<script>
export default {
  name: 'TruncatedText',
  props: {
    max: {
      type: Number,
      default: 30
    },
    text: {
      type: String,
      required: false
    },
    sectionTo: {
      type: String,
      default: null
    },
    customStyles: {
      type: Object,
      default: null
    },
    customClasses: {
      type: [String, Array, Object],
      default: null
    },
    onlyTruncate: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return ({
      open: false
    })
  },
  methods: {
    more () {
      if (this.sectionTo !== null) {
        window.location.href = `#${this.sectionTo}`
      } else {
        this.open = true
      }
    },
    less () { this.open = false },
    toggle () {
      if (this.sectionTo !== null) {
        window.location.href = `#${this.sectionTo}`
      } else {
        this.open = !this.open
      }
    }
  },
  render (createElement) {
    let content = this.text
    if (this.needTruncate) {
      if (!this.open) {
        content = this.text.slice(0, this.max)
      }
    }

    let contentOpen = ` ...${this.$t('app.text.show_more')}`
    if (this.onlyTruncate) {
      contentOpen = '...'
    }
    if (this.open) {
      contentOpen = ` ${this.$t('app.text.show_less')}`
    }

    const toggler = createElement('span', {
      class: {
        toggler: true,
        'font-weight-bold': true,
        'cursor-pointer': true,
        'text-decoration-underline': true
      },
      domProps: {
        innerHTML: contentOpen
      },
      on: {
        click: (event) => {
          if (!this.onlyTruncate) {
            event.stopPropagation()
            event.preventDefault()
            this.toggle()
          }
        }
      }
    })

    const element = createElement('span', {
      class: this.customClasses,
      domProps: {
        innerHTML: content
      },
      style: this.customStyles
    })

    const childrens = [element]
    if (this.needTruncate) { childrens.push(toggler) }

    return createElement('span', {}, childrens)
  },
  computed: {
    needTruncate () {
      if (this.text === null || this.text === undefined) {
        return false
      }
      return this.text.length > this.max
    }
  }
}
</script>
