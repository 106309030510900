<template>
  <div >
    <Banner/>
    <Shop/>
    <Pages/>
    <Categories/>
    <Pages onlyBrands/>
  </div>
</template>

<script>
import Banner from '@/views/components/MainComp/Banner.vue'
import Shop from '@/views/components/MainComp/Shop.vue'
import Categories from '@/views/components/MainComp/Categories.vue'
import Pages from '@/views/components/MainComp/Pages.vue'

export default {
  components: {
    Banner,
    Shop,
    Pages,
    Categories
  }
}
</script>
