export default {
  text: {
    checkout: 'Checkout',
    continue: 'Continuar',
    edit: 'Editar',
    not_data: 'Sin Datos',
    your_order: 'Tu pedido',
    subtotal: 'SUBTOTAL',
    discount: 'DESCUENTO',
    shipping: 'ENVÍO',
    shipping_free: 'ENVÍO GRATIS',
    total: 'TOTAL',
    payment_online: 'PAGOS EN LINEA',
    payment_delivery: 'PAGO CONTRA ENTREGA',
    read_terms_condition_part0: 'HE LEÍDO Y ESTOY DE ACUERDO CON LOS',
    read_terms_condition_part1: 'TÉRMINOS Y CONDICIONES',
    read_terms_condition_part2: 'DE LA WEB',
    make_order: 'REALIZAR PEDIDO'
  },
  step_input_persona: {
    title: 'Datos Personales',
    first_name: {
      label: 'Nombres',
      placeholder: 'Nombres'
    },
    last_name: {
      label: 'Apellidos',
      placeholder: 'Apellidos'
    },
    identification: {
      label: 'Identificación (C.C)',
      placeholder: 'Identificación'
    },
    cellphone: {
      label: 'Celular',
      placeholder: 'Celular'
    },
    email: {
      label: 'Correo Electrónico',
      placeholder: 'Correo'
    }
  },
  step_input_address: {
    title: 'Dirección',
    country: {
      label: 'País',
      placeholder: 'País'
    },
    department: {
      label: 'Departamento',
      placeholder: 'Departamento'
    },
    municipality: {
      label: 'Municipio',
      placeholder: 'Municipio'
    },
    reference_point: {
      label: 'Barrio',
      placeholder: 'Barrio'
    },
    direction: {
      label: 'Dirección',
      placeholder: 'Dirección'
    },
    apart_tower_house: {
      label: 'Apartamento-torre-casa (opcional)',
      placeholder: 'Apartamento-torre-casa (opcional)'
    },
    location_postal_code: {
      label: 'Código Postal',
      placeholder: 'Código Postal'
    }
  },
  step_input_coupon: {
    title: 'Bono',
    discount: 'Descuento:',
    apply_coupon: 'APLICAR CUPÓN',
    not_coupon: 'Sin cupón',
    coupon: {
      label: 'Cupón',
      placeholder: 'Código de Cupón'
    }
  },
  step_input_order_note: {
    title: 'Notas pedido',
    not_notes: 'Sin notas',
    order_note: {
      label: 'Notas del pedido (opcional)',
      placeholder: 'Notas del pedido'
    }
  },
  cart_empty: 'Vaya, parece que no has agregado ningún producto a tu carrito para pagar'
}
