<template>
  <div class="error-page">
    <span class="size-px-100 material-icons-outlined">mood_bad</span>
    <h1 class="error-heading">{{$t('error404.text.error_404')}}</h1>
    <p class="error-message">¡{{$t('error404.text.not_found')}}</p>
    <b-button class="bg-color-dark rounded-none" @click="redirectToHome">{{$t('error404.text.go_to_home')}}</b-button>
  </div>
</template>

<script>
export default {
  name: 'viewError',
  methods: {
    redirectToHome () {
      this.$router.push('/')
    }
  }
}
</script>

<style>
.error-page {
  text-align: center;
  margin-top: 100px;
  margin-bottom: 100px;
}
.error-heading {
  font-size: 3rem;
  margin-bottom: 20px;
}
.error-message {
  font-size: 1.2rem;
  margin-bottom: 20px;
}
</style>
