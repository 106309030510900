<template>
  <section id="section_banner_image">
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      fade
      indicators
      background="#ababab"
      img-width="1024"
      img-height="480"
      style="text-shadow: 1px 1px 2px #333;"
    >
      <b-carousel-slide v-for="(banner, idx) in banners" :key="idx" :style="`background-image: url(${$utilities.publicPath(banner.url)});`">
        <template #img>
          <a @click="redirectToBanner(banner)" :href="banner.link" target="_blank" class="cursor-pointer">
            <b-img-lazy
              class="d-block img-fluid object-fit-fill"
              width="1024"
              height="480"
              :src="$utilities.publicPath(banner.url)"
              :alt="`banner-${idx}`"
              blank-color="grey"
              blank
            />
          </a>
        </template>
      </b-carousel-slide>
    </b-carousel>
  </section>
</template>

<script>
export default {
  name: 'CompBannerImage',
  data () {
    return {
      slide: 0
    }
  },
  props: {
    banners: {
      type: Array,
      required: true
    }
  },
  methods: {
    redirectToBanner (banner) {
      if (banner.banner_link_source === 'INTERNAL') {
        const routeData = {}

        if (banner.banner_link_type === 'PRODUCT') {
          routeData.name = 'portal.products.detail'
          routeData.params = { productStoreSlug: banner.banner_link_related_slug }
        }
        if (banner.banner_link_type === 'CATEGORY') {
          routeData.name = 'portal.products.category'
          routeData.params = { categoryStoreSlug: banner.banner_link_related_slug }
        }
        if (banner.banner_link_type === 'BRAND') {
          routeData.name = 'portal.products.brand'
          routeData.params = { brandStoreSlug: banner.banner_link_related_slug }
        }
        if (banner.banner_link_type === 'PAGE') {
          routeData.name = 'portal.pages.detail'
          routeData.params = { pageStoreSlug: banner.banner_link_related_slug }
        }

        const url = this.$router.resolve(routeData).href
        window.location.assign(url)
      } else if (banner.banner_link_source === 'EXTERNAL') {
        window.open(banner.banner_link, '_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .carousel-item img {
      width: 100vw;
      height: 45vh;
      object-fit: cover;
      object-position: 50% 50%;
  }
  ::v-deep div .carousel-caption {
    position: absolute;
    top: 15%;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
  }
</style>
