// english
import app from '@/common/langs/es_CO/app'
import base from '@/common/langs/es_CO/base'
import categories from '@/common/langs/es_CO/categories'
import shop from '@/common/langs/es_CO/shop'
import cart from '@/common/langs/es_CO/cart'
import cartPay from '@/common/langs/es_CO/cartPay'
import contact from '@/common/langs/es_CO/contact'
import giftCards from '@/common/langs/es_CO/giftCards'
import productDetail from '@/common/langs/es_CO/productDetail'
import productCard from '@/common/langs/es_CO/productCard'
import products from '@/common/langs/es_CO/products'
import error404 from '@/common/langs/es_CO/error404'
import previewProduct from '@/common/langs/es_CO/previewProduct'
import selectMunicipality from '@/common/langs/es_CO/selectMunicipality'

export const en = {
  app,
  base,
  categories,
  shop,
  cart,
  cartPay,
  contact,
  giftCards,
  productDetail,
  productCard,
  products,
  error404,
  previewProduct,
  selectMunicipality
}
