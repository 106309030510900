<template>
  <div>
    <Legal/>
  </div>
</template>
<script>

import Legal from '@/views/components/Legal.vue'

export default {
  data () {
    return {
    }
  },
  methods: {
  },
  components: {
    Legal
  }
}
</script>
