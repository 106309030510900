export default {
  text: {
    categories: 'Categorías',
    brands: 'Marcas',
    not_result: 'No hay resultados'
  },
  form: {
    search: 'Buscar...'
  }
}
