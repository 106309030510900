<template>
  <div>
    <b-modal
      :visible="modalVisible"
      @hide="emitHideModalProduct"
      dialog-class="modal-width-custom"
      content-class="rounded-md border-0"
      hide-header
      hide-footer
      centered
    >
      <template>
        <a class="close cursor-pointer opacity-100" aria-label="Close" @click="emitHideModalProduct">
          <span aria-hidden="true">&times;</span>
        </a>
        <b-container fluid class="pt-4 px-0" v-if="productShowModal">
          <b-row>
            <b-col cols="12" lg="5">
              <ProductImages ref="compProductImages" :quantityShowProduct="5" :product="productShowModal"> </ProductImages>
            </b-col>
            <b-col cols="12" lg="7" class="scroll-detail-product-modal px-lg-5">
              <p class="size-px-16 size-px-lg-24 cursor-pointer mb-0" style="color: #393939;" @click="onClickRedirectDetailProduct(productShowModal)">{{productShowModal.name}}</p>

              <p class="d-flex align-items-center">
                <span
                  :class="productShowModal.price.special_price == 0 ?
                  'font-weight-bold size-px-24 size-px-lg-30' :
                  'text-decoration-line-through text-color-grey-light size-px-16 size-px-lg-20'"
                >
                  {{productShowModal.price.retail_price | currencyFormat}}
                </span>
                <span
                  v-if="productShowModal.price.special_price != 0"
                  class="font-weight-bold ml-2 size-px-24 size-px-lg-30"
                >
                  {{productShowModal.price.value_price | currencyFormat}}
                </span>
              </p>

              <p><TruncatedText :max="250" style="white-space: break-spaces; margin-top:3rem; margin-bottom:3rem;" class="my-5 size-px-14 size-px-lg-16" :text="productShowModal.online_store_description"></TruncatedText></p>

              <div v-if="productShowModal.variants.length > 0" class="pb-4 border-bottom border-color-white-grey mb-2">
                <p class="text-center font-weight-bold size-px-20">{{$t('previewProduct.text.variants')}}</p>
                  <p
                    v-for="(variant, idx) in orderVariant(productShowModal.variants)" :key="`variant_${idx}`"
                    class="variant-custom p-2 size-px-13 cursor-pointer rounded"
                    :class="{
                      'variant-custom-active': variant.id === productShowModal.id,
                      'bg-color-white': variant.id !== productShowModal.id,
                      'd-inline-block mr-2': variant.value_name.length < 18 && variant.value_name.length > 0
                    }"
                    @click="onChangeVariantSelected(variant, productShowModal.variants)"
                  >
                    {{variant.value_name}}
                  </p>
              </div>

              <p class="text-center mb-3">
                <span v-if="productShowModal.stock && !productShowModal.stock.out_stock">
                  <span v-if="info ? info.show_quantity_in_stock : false" class="size-px-18">
                    {{productShowModal.stock.quantity_available | quantityFormat}} {{$t('previewProduct.text.availables')}}
                  </span>
                  <span v-else class="size-px-18">
                    {{$t('previewProduct.text.available')}}
                  </span>
                </span>
                <span v-else class="size-px-18">
                  {{$t('previewProduct.text.not_available')}}
                </span>
              </p>

              <b-row class="justify-content-center mb-5">
                <b-col cols="12" lg="auto" class="d-flex align-items-center" v-if="canShowBuy">
                  <div class="quantity d-flex justify-content-center" style="display: inner-block;">
                    <span class="cursor-pointer decrease d-flex align-items-center" @click="emitDecrementQuantity">-</span>
                    <input
                      readonly
                      type="number"
                      id="quantity"
                      class="text-center text border-0"
                      name="quantity"
                      :value="quantity"
                      :aria-label="$t('PreviewProducts.text.quantity')"
                      size="4"
                      min="1"
                      :max="productShowModal.stock ? productShowModal.stock.quantity_available : 999"
                      step="1"
                      placeholder=""
                      inputmode="numeric"
                      autocomplete="off"
                    >
                    <span class="cursor-pointer increase d-flex align-items-center" @click="emitIncrementQuantity">+</span>
                  </div>
                </b-col>
                <b-col cols="12" lg="auto" class="text-center">
                  <b-button class="w-100 bg-color-red height-pct-100 border-0 rounded-full p-2 py-lg-2 px-lg-5 mt-3 mt-lg-0" v-if="canShowBuy" @click="emitAddToCart">
                    <span><font-awesome-icon class="mr-2 size-px-20" icon="fa-solid fa-bag-shopping" /> {{$t('previewProduct.text.add_to_cart')}}</span>
                  </b-button>
                  <b-button
                    v-else-if="productShowModal.stock && !productShowModal.stock.out_stock"
                    class="bg-color-red height-pct-100 rounded-full p-lg-3 border-0"
                    @click="emitShowModalCart()"
                  >
                    <span>{{$t('previewProduct.text.already_cart')}}</span>
                  </b-button>
                </b-col>
              </b-row>

              <div class="mt-3 border-top-1 border-color-white-grey py-4">
                <span class="size-px-10 font-weight-bold">{{$t('previewProduct.text.category')}}: </span>
                <span class="size-px-10 text-color-grey-light ml-3">{{categoryName}}</span>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </b-modal>

  </div>
</template>

<script>
import TruncatedText from '@/views/components/TruncatedText.vue'
import ProductImages from '@/components/products/ProductImages.vue'

import LoadingMixin from '@/plugins/Common/LoadingMixin.js'

export default {
  name: 'CompPreviewProduct',
  props: {
    modalVisible: {
      type: Boolean,
      default: false
    },
    productShowModal: {
      type: Object,
      required: true
    },
    info: {
      type: Object,
      required: true
    },
    categoryName: {
      type: String,
      required: true
    },
    canShowBuy: {
      type: Boolean,
      required: true
    },
    quantity: {
      type: Number,
      required: true
    }
  },
  methods: {
    emitHideModalProduct () {
      this.$emit('hideModalProduct')
    },
    emitShowModalCart () {
      this.$emit('showModalCart', false)
    },
    emitAddToCart () {
      this.$emit('addToCart', this.productShowModal)
    },
    emitIncrementQuantity () {
      this.$emit('incrementQuantity')
    },
    emitDecrementQuantity () {
      this.$emit('decrementQuantity')
    },
    onClickRedirectDetailProduct () {
      this.$emit('redirectDetailProduct', this.productShowModal)
    },
    orderVariant (variants) {
      const copyVariants = [...variants]
      copyVariants.sort((a, b) => a.value_name.length - b.value_name.length)
      return copyVariants
    },
    onChangeVariantSelected (variant, variants) {
      if (variant.id !== this.productShowModal.id) {
        this.$emit('changeProductShowModal', variant, variants)
      }
      this.$refs.compProductImages.resetSelectedImg()
    }

  },
  components: { TruncatedText, ProductImages },
  mixins: [LoadingMixin]
}
</script>

<style lang="scss" scoped>
  .quantity {
    transition: .5s;
    border: 1px solid #e4e6eb !important;
    align-content: center;
    float: none;
    width: 100%;
    height: 65px;
    margin-right: 10px;
  }
  .decrease, .quantity, .increase {
    cursor: pointer;
    padding: 7px 4px;
    display: inline-block;
    background: 0 0;
    border: none;
    user-select: none;
  }
  input#quantity:focus-visible {
      outline-color: transparent;
  }
  .new-badge {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000000;
    padding: 3px 10px 3px 10px;
  }
  .discount-badge {
    position: absolute;
    top: 30px;
    left: 0;
    background-color: #00d994;
    padding: 3px 10px 3px 10px;
  }
  .variant-custom {
    border: 1px solid #cdcdcd;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }
  .variant-custom-active {
    border: 1px solid #000000;
    background-color: #ffffff;
  }
  .variant-custom:not(.variant-custom-active):hover {
    background-color: rgb(255, 255, 255) !important;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-color: #000000 !important;
  }
  ::v-deep .modal-width-custom {
    max-width: 1180px !important
  }
  @media (min-width: 576px) {
      ::v-deep .modal-width-custom{
        max-width: 556px !important;
      }
    }
    @media (min-width: 768px) {
      ::v-deep .modal-width-custom {
        max-width: 748px !important;
      }
    }
    @media (min-width: 992px) {
      ::v-deep .modal-width-custom {
        max-width: 972px !important;
      }
    }
    @media (min-width: 1200px) {
      ::v-deep .modal-width-custom {
        max-width: 1180px !important;
      }
    }
</style>
