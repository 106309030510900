const Mixin = {
  data () {
    return {
      items: [],
      search: null,
      localValue: null
    }
  },
  props: {
    itemText: {
      type: String,
      default: 'text'
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    internalSearch: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    value: null
  },
  methods: {
    fnFilter (item, queryText, itemText) {
      return true
    },
    clearItems () {
      this.items = []
    },
    onSearchChange (q) {
      if (q) {
        if (this.internalSearch) {
          this.searchInternalItems(q)
        } else {
          this.searchItems(q)
        }
      }
    },
    setLocalValue (value) {
      this.localValue = value
    },
    getTransformItems (items) {
      return items
    },
    setItems (items) {
      const defaultOption = {
        text: this.$t('app.text.select_option'),
        value: null
      }

      const transformedItems = this.getTransformItems(items)

      this.items = [defaultOption, ...transformedItems]

      this.setLocalValue(this.value)
    },
    searchInternalItems (q) {
      this.items = this.source.filter(object => object[this.itemText].toLowerCase().includes(q.toLowerCase()))
    },
    searchItems (q) {
    }
  },
  watch: {
    search (val, oVal) {
      if (val) {
        if (val !== oVal) {
          this.onSearchChange(val)
        }
      }
    },
    localValue (v) {
      this.$emit('input', v)
    },
    value (v) {
      this.setLocalValue(v)
    }
  },
  created () {
    this.setLocalValue(this.value)

    this.loadSource()
  }
}

export default Mixin
