<template>
  <section id="section_shop_product_highlight" class="mt-4 mx-3">
    <b-container v-if="statusIsLoading() && currentPage === 1">
      <b-row>
        <b-col cols="12" class="my-5 text-center">
          <b-spinner style="width: 3rem; height: 3rem;" :label="$t('app.text.loading')"></b-spinner>
        </b-col>
      </b-row>
    </b-container>

    <b-container v-else-if="bannerProducts.length > 0" class="custom-container">
      <b-row class="justify-content-center my-5">
        <b-col cols="12" xl="12">
          <div class="mb-3">
            <p v-if="searchCategoryProduct" class="size-px-24 text-color-dark font-weight-bold">{{title}}</p>
            <a v-if="onlyNews" @click="redirectNewProducts" class="size-px-24 text-color-dark font-weight-bold cursor-pointer">{{title}}</a>
            <a v-if="onlyHighlight" @click="redirectHighlightProducts" class="size-px-24 text-color-dark font-weight-bold cursor-pointer">{{title}}</a>
            <a v-if="onlyOutlet" @click="redirectOutletProducts" class="size-px-24 text-color-dark font-weight-bold cursor-pointer">{{title}}</a>
          </div>

          <b-row v-if="isDesktop" class="position-relative">
            <span
              v-if="!isFirstPage"
              class="cursor-pointer position-absolute custom-position-preview material-icons-outlined"
              @click="previousPage()"
            >
              arrow_back_ios
            </span>

            <span
              v-if="canNavigateNext"
              class="cursor-pointer position-absolute custom-position-next material-icons-outlined"
              @click="nextPage()"
            >
              arrow_forward_ios
            </span>

            <!-- view desktop -->
            <!-- onlyThreeCols -->
            <template v-if="onlyThreeCols">
              <b-col v-for="(product, idx) in bannerProducts" cols="4" class="px-1" :key="`product_highlight_${idx}`">
                <ProductCard
                  :ref="`compProductCard_${product.id}`"
                  :product="product"
                  class="product-item h-100"
                  @redirectDetailProduct="redirectDetailProduct"
                  @seeModalProduct="showModalProduct"
                  @seeModalCart="showModalCart"
                  @addToCart="addToCart"
                >
                </ProductCard>
              </b-col>
            </template>

              <!-- differentCols -->
            <template v-else>
              <b-col v-for="(product, idx) in bannerProducts" lg="3" xl="2" class="px-1" :key="`product_highlight_${idx}`">
                <ProductCard
                  :ref="`compProductCard_${product.id}`"
                  :product="product"
                  class="product-item h-100"
                  @redirectDetailProduct="redirectDetailProduct"
                  @seeModalProduct="showModalProduct"
                  @seeModalCart="showModalCart"
                  @addToCart="addToCart"
                >
                </ProductCard>
              </b-col>
            </template>
          </b-row>

          <!-- view Mobile -->
          <b-row v-if="isMobile || isTablet" class="flex-nowrap" style="overflow-x:auto;" @scroll="handleScroll">
            <b-col v-for="(product, idx) in bannerProducts" cols="5" md="5" lg="3" class="px-1 my-2" style="min-width: 198px;" :key="idx">
              <ProductCard
                :ref="`compProductCard_${product.id}`"
                :product="product"
                class="product-item h-100"
                @redirectDetailProduct="redirectDetailProduct"
                @seeModalProduct="showModalProduct"
                @seeModalCart="showModalCart"
                @addToCart="addToCart"
              >
              </ProductCard>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- Modal SHOW PRODUCT -->
      <PreviewProduct
        v-if="productShowModal"
        :modalVisible="modalProduct"
        :productShowModal="productShowModal"
        :info="info"
        :categoryName="categoryName"
        :canShowBuy="canShowBuy"
        :quantity="quantity"
        @changeProductShowModal="changeProductShowModal"
        @redirectDetailProduct="redirectDetailProduct"
        @showModalCart="showModalCart"
        @hideModalProduct="hideModalProduct"
        @addToCart="addToCart"
        @incrementQuantity="incrementQuantity"
        @decrementQuantity="decrementQuantity"
      />
    </b-container>
  </section>
</template>

<script>
import ProductCard from '@/views/components/ProductCard.vue'
import PreviewProduct from '@/components/PreviewProduct.vue'

import LoadingMixin from '@/plugins/Common/LoadingMixin.js'
import AddCartMixin from '@/mixins/AddCartMixin.js'
import ResizeMixin from '@/mixins/ResizeMixin.js'
import RedirectMixin from '@/mixins/RedirectMixin.js'
import BasicPaginationMixin from '@/mixins/BasicPaginationMixin.js'

export default {
  name: 'CompBannerProductsHighlight',
  data () {
    return {
      bannerProducts: [],
      totalPages: 1
    }
  },
  props: {
    title: {
      type: String,
      default: null
    },
    onlyThreeCols: {
      type: Boolean,
      default: false
    },
    onlyNews: {
      type: Boolean,
      default: false
    },
    onlyHighlight: {
      type: Boolean,
      default: false
    },
    onlyOutlet: {
      type: Boolean,
      default: false
    },
    searchCategoryProduct: {
      type: Object,
      default: null
    }
  },
  methods: {
    nextPage () {
      if (this.canNavigateNext) {
        this.currentPage++
        this.load()
      }
    },
    previousPage () {
      if (!this.isFirstPage) {
        this.currentPage--
        this.load()
      }
    },
    load () {
    // if (this.statusIsLoading()) return
      this.statusLoading()

      const query = this.$Models.Product.query()
      if (this.onlyNews) {
        query.where('online_store_is_new', true)
      } else if (this.onlyHighlight) {
        query.where('online_store_is_highlight', true)
      } else if (this.onlyOutlet) {
        query.where('online_store_is_outlet', true)
      } else if (this.searchCategoryProduct) {
        query.where('category_id', this.searchCategoryProduct.category_id)
        query.where('id', '!=', this.searchCategoryProduct.id)
      }

      query.forPage(this.currentPage, this.productsPerPage)

      query.get()
        .then(res => {
          if (this.onlyNews) {
            res.items.forEach((item) => {
              if (item.variants.length) {
                item.variants = item.variants.sort((a, b) => {
                  if (a.online_store_is_new === b.online_store_is_new) {
                    return 0
                  } else if (a.online_store_is_new) {
                    return -1
                  } else {
                    return 1
                  }
                })
              }
            })
          }
          if (this.onlyHighlight) {
            res.items.forEach((item) => {
              if (item.variants.length) {
                item.variants = item.variants.sort((a, b) => {
                  if (a.online_store_is_highlight === b.online_store_is_highlight) {
                    return 0
                  } else if (a.online_store_is_highlight) {
                    return -1
                  } else {
                    return 1
                  }
                })
              }
            })
          }
          if (this.onlyOutlet) {
            res.items.forEach((item) => {
              if (item.variants.length) {
                item.variants = item.variants.sort((a, b) => {
                  if (a.online_store_special_price < b.online_store_special_price) {
                    return 1
                  } else if (a.online_store_special_price > b.online_store_special_price) {
                    return -1
                  } else {
                    return 0
                  }
                })
              }
            })
          }

          if (this.isDesktop) {
            this.bannerProducts = res.items
            if (this.currentPage === 1) {
              const perPage = this.productsPerPage
              this.totalPages = Math.ceil(res.count / perPage)
            }
          } else {
            if (this.currentPage === 1) {
              this.bannerProducts = res.items
              this.totalPages = Math.ceil(res.count / this.productsPerPage)
            } else {
              this.bannerProducts = [...this.bannerProducts, ...res.items]
            }
          }
          this.statusLoadingComplete()
        })
        .catch((err) => {
          this.statusLoadingFail()
          this.showError(err)
        })
    },
    handleScroll (event) {
      const el = event.target
      if (el.scrollLeft + el.clientWidth >= el.scrollWidth) {
        this.nextPage()
      }
    }

  },
  mounted () {
    this.load()
  },
  computed: {
    productsPerPage () {
      let perPage
      if (this.onlyThreeCols) {
        perPage = 3
      } else if (this.deviceIs === 'xl') { // xl
        perPage = 6
      } else if (this.deviceIs === 'lg') { // lg
        perPage = 4
      } else if (this.deviceIs === 'md') { // md
        perPage = 5
      } else if (this.deviceIs === 'sm') { // sm
        perPage = 5
      } else {
        perPage = 5
      }

      return perPage
    }
  },
  components: {
    ProductCard,
    PreviewProduct
  },
  mixins: [
    LoadingMixin,
    AddCartMixin,
    ResizeMixin,
    RedirectMixin,
    BasicPaginationMixin
  ]
}
</script>

<style lang="scss" scoped>
  .custom-container {
    max-width: 1280px;
  }
  a {
    text-decoration: underline;
  }
  a:hover {
    color: black;
  }
  .custom-position-preview,
  .custom-position-next {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 10px;
    border-radius: 50%;
    color:white;
  }
  .custom-position-preview {
    top: 50%;
    left: 0px;
    z-index: 60;
  }
  .custom-position-next {
    top: 50%;
    right: 0px;
    z-index: 60;
  }
</style>
