<template>
  <div>
    <CartPay/>
  </div>
</template>

<script>
import CartPay from '@/views/components/CartPay.vue'

export default {
  components: {
    CartPay
  }
}
</script>
