<template>
  <div class="d-flex flex-column" style="min-height: 100vh; scrollbar-gutter: stable;">
    <div v-if="info.show_whatsapp_button">
      <!-- Boton Flotante Whatsapp -->
      <transition name="flip">
        <font-awesome-icon
          v-if="modalWhatsapp"
          @click="showModalWhatsapp"
          class="cursor-pointer fixed-wp fixed-wp-sm position-fixed bg-color-wp-dark text-light border-radius-lg"
          icon="fa-solid fa-xmark"
          key="close-icon"
        />

        <font-awesome-icon
          v-else
          @click="showModalWhatsapp"
          class="cursor-pointer fixed-wp fixed-wp-sm position-fixed bg-color-wp-dark text-light border-radius-lg"
          icon="fa-brands fa-whatsapp"
          key="whatsapp-icon"
        />
      </transition>

      <!-- Poptip Flotante Whatsapp -->
      <transition v-if="isDesktop" name="slide-fade">
        <b-container fluid
          v-if="!modalWhatsapp"
          @click="showModalWhatsapp"
          body-class="p-0"
          class="container-wp-custom position-fixed cursor-pointer"
          style="bottom: 30px;right: 100px; z-index:1055; max-width: 170px;">
          <b-row class="bg-color-wp-white p-2" style="border-top-left-radius: 0.2rem; border-top-right-radius: 0.2rem; border-bottom-left-radius: 0.2rem; border-bottom-right-radius: 0.2rem;">
            <b-col cols="12" class="text-color-dark-blue">
              <p class="size-px-12 mb-0 font-weight-bold">{{$t('base.modal_whatsapp.text.chat_with_us')}}</p>
              <p class="size-px-12 mb-0 font-weight-bold">{{$t('base.modal_whatsapp.text.sales_line')}}</p>
            </b-col>
          </b-row>
        </b-container>
      </transition>

      <!-- Modal Flotante Whatsapp -->
      <transition name="slide-fade">
        <b-container fluid
          v-if="modalWhatsapp"
          body-class="p-0"
          class="container-wp-custom position-fixed"
        >
          <b-row class="bg-color-wp-dark p-3" style="border-top-left-radius: 0.5rem; border-top-right-radius: 0.5rem;">
            <b-col cols="2" class="rounded-xl">
              <font-awesome-icon class="size-px-45 text-light" icon="fa-brands fa-whatsapp"/>
            </b-col>
            <b-col cols="10" class="text-light">
              <p class="size-px-18 mb-1">{{$t('base.modal_whatsapp.text.chat_with_us_sell')}}</p>
              <p class="size-px-12">{{$t('base.modal_whatsapp.text.hello_click_options')}}</p>
            </b-col>
          </b-row>
          <b-row class="bg-color-white p-3" style="border-bottom-left-radius: 0.5rem; border-bottom-right-radius: 0.5rem;">
            <b-col cols="12">
              <p class="size-px-11 text-color-grey-light">{{$t('base.modal_whatsapp.text.team_usually_responds')}}</p>
              <div @click="redirectWhatsapp(info.contact_cellphone_indicator, info.contact_cellphone)">
                <b-row class="py-3 px-2 wp-custom justify-content-between">
                  <b-col cols="8" class="d-flex">
                    <div class="d-flex align-items-center mr-2">
                      <span class="size-px-35 text-color-wp-dark material-icons">face</span>
                    </div>
                    <div>
                      <p class="mb-0 size-px-14 font-weight-bold text-color-dark">{{$t('base.modal_whatsapp.text.sales')}}</p>
                      <p class="mb-0 size-px-11 text-color-grey-light">{{$t('base.modal_whatsapp.text.sales')}}</p>
                    </div>
                  </b-col>
                  <b-col cols="auto" class="d-flex align-items-center">
                    <font-awesome-icon class="size-px-27 text-color-wp" icon="fa-brands fa-whatsapp"/>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </transition>
    </div>

    <!-- Promotional Banner Text -->
    <b-container v-if="promotionalBannerText" fluid class="py-2 bg-color-dark">
      <b-row>
        <b-col
          cols="12"
          class="d-flex align-items-center text-light justify-content-center cursor-pointer"
          @click="showModalBanner"
        >
          <p class="mb-0 pt-px-13 pb-px-13">{{promotionalBannerText}}</p>
        </b-col>
      </b-row>
    </b-container>

    <!-- Main Menu -->
    <b-container class="custom-container">
      <b-row class="align-items-center justify-content-between" style="height: 90px;">
        <b-col cols="10" sm="11" lg="9">
          <b-row class="align-items-center">
            <b-col v-if="info.store_logo" cols="5" md="auto" @click="redirectHome()">
              <b-img-lazy
                class="img-logo w-100 cursor-pointer"
                :src="$utilities.publicPath(info.store_logo)"
                blank-color="grey"
                blank
              />
            </b-col>
            <b-col cols="7" lg="8" class="d-flex justify-content-center justify-content-md-start">
              <b-input-group  v-if="isTablet || isDesktop" size="md" class="d-flex justify-content-center">
                <b-input-group-prepend is-text class="border-right-0 bg-color-withe custom-prepend" style="border-top-left-radius: 50px; border-bottom-left-radius:50px;">
                  <span @click="onTextSearch" style="border-top-left-radius: 50px; border-bottom-left-radius:50px;" class="size-px-18 cursor-pointer material-icons-outlined">search</span>
                </b-input-group-prepend>
                <b-form-input
                  @keydown.enter="onTextSearch"
                  v-model="q"
                  class="border-x-0 border-y-1 border-color-dark"
                  size="md"
                  :placeholder="$t('base.main_menu.search.placeholder')"
                >
                </b-form-input>
                <b-input-group-append class="border-y-1" style="border-top-right-radius: 50px; border-bottom-right-radius:50px;">
                  <b-button @click="onTextSearch" class="bg-color-dark d-flex align-items-center border-0" style="border-top-right-radius: 50px; border-bottom-right-radius:50px;">
                    <span class="size-px-20 cursor-pointer material-icons-outlined">search</span>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <span v-if="isMobile" @click="showModalSearch"  class="d-flex material-icons-outlined size-px-30">search</span>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="2" sm="1" lg="auto" class="justify-content-end">
          <div class="d-flex justify-content-end" @click="showModalCart(false)">
            <div class="position-relative">
              <span class="material-icons-outlined cursor-pointer size-px-30">shopping_cart</span>
              <span class="badge-number bg-color-red text-light size-px-9 font-weight-bold">{{cart.items.length}}</span>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <!-- Secundary Menu Submenu -->
    <section class="bg-color-dark position-relative mb-1">
      <div style="overflow-x:auto; overflow-y: hidden;">
        <b-container class="custom-container">
          <!-- Visual Desktop hasta lg -->
          <b-row v-if="isDesktop" class="flex-nowrap py-2 position-relative justify-content-center">
            <div
              @mouseover="showDropdownNews = true"
              @mouseleave="showDropdownNews = false"
              class="cursor-pointer position-static text-light d-flex align-items-center justify-content-center mx-2 my-3"
            >
              <p class="size-px-12 mb-0 font-weight-bold text-uppercase">{{$t('base.secondary_menu.news')}}</p>

              <span v-show="showDropdownNews" class="position-absolute material-icons-outlined size-px-50" style="bottom: -21px;">arrow_drop_up</span>
            </div>

            <div
              @mouseover="showDropdownBrands = true"
              @mouseleave="showDropdownBrands = false"
              class="cursor-pointer d-flex position-static align-items-center text-light justify-content-center mx-2 my-3"
            >
              <p class="size-px-12 mb-0 font-weight-bold text-uppercase">{{$t('base.secondary_menu.brands')}}</p>

              <span v-show="showDropdownBrands" class="position-absolute material-icons-outlined size-px-50" style="bottom: -21px;">arrow_drop_up</span>
            </div>

            <!-- Mas de 10 categorias -->
            <template v-if="isCategoryMoreTen">
              <div
                v-for="(category, idx) in getCategoriesByRange(0, 7)"
                class="cursor-pointer d-flex align-items-center text-light justify-content-center font-weight-bold mx-2 my-3"
                :key="`categories_${idx}`"
              >
                <p @click="redirectCategory(category.online_store_slug)" class="size-px-12 mb-0 text-uppercase">{{category.name}}</p>
              </div>

              <div
                @mouseover="showDropdownCategories = true"
                @mouseleave="showDropdownCategories = false"
                class="cursor-pointer position-static text-light d-flex align-items-center justify-content-center mx-2 my-3"
              >
                <p class="size-px-12 mb-0 font-weight-bold text-uppercase">{{$t('base.secondary_menu.more_categories')}}</p>

                <span v-show="showDropdownCategories" class="position-absolute material-icons-outlined size-px-50" style="bottom: -21px;">arrow_drop_up</span>
              </div>
            </template>

            <!-- Menos de 10 categorías -->
            <template v-else>
              <div
                v-for="(category, idx) in categories"
                :key="`categories_${idx}`"
                class="cursor-pointer d-flex align-items-center text-light justify-content-center font-weight-bold mx-2 my-3"
              >
                <p @click="redirectCategory(category.online_store_slug)" class="mb-0 text-break text-uppercase">{{category.name}}</p>
              </div>
            </template>

            <div v-if="info.can_sell_gift_cards" class="cursor-pointer d-flex align-items-center text-light justify-content-center font-weight-bold mx-2 my-3">
              <p @click="redirectGiftCard()" class="size-px-12 mb-0 text-uppercase">{{$t('base.secondary_menu.gift_card')}}</p>
            </div>

            <div v-if="info.show_online_booking_link" class="cursor-pointer d-flex align-items-center text-light justify-content-center font-weight-bold mx-2 my-3">
              <p @click="redirectBookingLink(info.online_booking_link)" class="size-px-12 mb-0 text-uppercase">{{$t('base.secondary_menu.schedule_appointment')}}</p>
            </div>
          </b-row>

          <!-- Visual Mobile sm md -->
          <b-row v-if="isMobile || isTablet" class="flex-nowrap py-2 position-relative">
            <b-col
              cols="auto"
              @click="toggleDropdown('news')"
              class="cursor-pointer position-static text-light d-flex align-items-center justify-content-center mx-auto"
            >
              <p class="mb-0 pt-px-13 pb-px-13 font-weight-bold size-px-12 text-uppercase">{{$t('base.secondary_menu.news')}}</p>

              <span v-show="showDropdownNews" class="position-absolute material-icons-outlined size-px-50" style="bottom: -21px;">arrow_drop_up</span>
            </b-col>
            <b-col
              cols="auto"
              @click="toggleDropdown('brands')"
              class="cursor-pointer d-flex position-static align-items-center text-light justify-content-center mx-auto"
            >
              <p class="mb-0 pt-px-13 pb-px-13 font-weight-bold size-px-12 text-uppercase">{{$t('base.secondary_menu.brands')}}</p>

              <span v-show="showDropdownBrands" class="position-absolute material-icons-outlined size-px-50" style="bottom: -21px;">arrow_drop_up</span>
            </b-col>

            <!-- Mas de 10 categorías -->
            <template v-if="isCategoryMoreTen">
              <b-col
                cols="auto"
                @click="toggleDropdown('categories')"
                class="cursor-pointer position-static text-light d-flex align-items-center justify-content-center mx-auto"
              >
                <p class="mb-0 pt-px-13 pb-px-13 font-weight-bold size-px-12 text-uppercase">{{$t('base.secondary_menu.categories')}}</p>

                <span v-show="showDropdownCategories" class="position-absolute material-icons-outlined size-px-50" style="bottom: -21px;">arrow_drop_up</span>
              </b-col>
            </template>

            <!-- Menos de 10 categorías -->
            <template v-else>
              <b-col v-for="(category, idx) in categories"
                cols="auto"
                class="cursor-pointer d-flex align-items-center text-light justify-content-center font-weight-bold mx-auto"
                :key="`categories_${idx}`"
              >
                <p @click="redirectCategory(category.online_store_slug)" class="mb-0 pt-px-13 pb-px-13 size-px-14 text-uppercase">{{category.name}}</p>
              </b-col>
            </template>

            <b-col v-if="info.can_sell_gift_cards" cols="auto" class="cursor-pointer d-flex align-items-center text-light justify-content-center font-weight-bold mx-auto">
              <p @click="redirectGiftCard()" class="mb-0 pt-px-13 pb-px-13 size-px-12 text-uppercase">{{$t('base.secondary_menu.gift_card')}}</p>
            </b-col>

            <b-col v-if="info.show_online_booking_link" cols="auto" class="cursor-pointer d-flex align-items-center text-light justify-content-center font-weight-bold mx-auto">
              <p @click="redirectBookingLink(info.online_booking_link)" class="mb-0 pt-px-13 pb-px-13 size-px-12 text-uppercase">{{$t('base.secondary_menu.schedule_appointment')}}</p>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <!-- Dropdown News -->
      <div
        v-show="showDropdownNews"
        @mouseover="showDropdownNews = true"
        @mouseleave="showDropdownNews = false"
        style="right: 0px; top: 100%; z-index: 50; cursor: default;"
        class="w-100 bg-white position-absolute"
      >
        <b-container class="h-custom" style="overflow-y: auto">
          <b-row class="text-color-dark py-3 py-lg-5">
            <b-col v-if="isMobile || isTablet" cols="12" class="d-flex justify-content-end"><span @click="toggleDropdown('news')" class="material-icons-outlined cursor-pointer">close</span></b-col>
              <b-col cols="6" sm="6" md="4" lg="3">
              <p @click="redirectNewProducts" class="size-px-12 size-px-md-14 size-px-lg-16 mb-0 pt-px-13 cursor-pointer text-hover text-uppercase">{{$t('base.secondary_menu.dropdown_news.all_news')}}</p>
            </b-col>
            <b-col cols="6" sm="6" md="4" lg="3">
              <p @click="redirectHighlightProducts" class="size-px-12 size-px-md-14 size-px-lg-16 mb-0 pt-px-13 cursor-pointer text-hover text-uppercase">{{$t('base.secondary_menu.dropdown_news.all_highlights')}}</p>
            </b-col>
            <b-col v-for="(category, idx) in categories" cols="6" sm="6" md="4" lg="3" :key="`category_news_${idx}`">
              <p @click="redirectNewCategories(category.online_store_slug)" class="size-px-12 size-px-md-14 size-px-lg-16 mb-0 pt-px-13 cursor-pointer text-hover text-break text-uppercase">{{category.name}}</p>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <!-- Dropdown Brands -->
      <div
        v-show="showDropdownBrands"
        @mouseover="showDropdownBrands = true"
        @mouseleave="showDropdownBrands = false"
        style="right: 0px; top: 100%; z-index: 10;"
        class="w-100 bg-white position-absolute"
      >
        <b-container class="h-custom" style="overflow-y: auto">
          <b-row class="text-color-dark py-3 py-lg-5">
            <b-col v-if="isMobile || isTablet" cols="12" class="d-flex justify-content-end"><span @click="toggleDropdown('brands')" class="material-icons-outlined cursor-pointer">close</span></b-col>
            <b-col v-for="(brand, idx) in brands" cols="6" sm="6" md="4" lg="3" :key="`brands_${idx}`">
            <p @click="redirectBrand(brand.online_store_slug)" class="size-px-12 size-px-md-14 size-px-lg-16 mb-0 pt-px-13 cursor-pointer text-hover text-break text-uppercase">{{brand.name}}</p>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <!-- Dropdown Categories -->
      <div
        v-show="showDropdownCategories"
        @mouseover="showDropdownCategories = true"
        @mouseleave="showDropdownCategories = false"
        style="right: 0px; top: 100%; z-index: 50; cursor: default;"
        class="w-100 bg-white position-absolute"
      >
        <b-container class="h-custom" style="overflow-y: auto">
          <b-row v-if="isDesktop" class="text-color-dark py-3 py-lg-5">
            <b-col v-for="(category, idx) in getCategoriesByRange(7)" cols="6" sm="6" md="4" lg="3" :key="`categories_${idx}`">
              <p @click="redirectCategory(category.online_store_slug)" class="size-px-12 size-px-md-14 size-px-lg-16 mb-0 pt-px-13 cursor-pointer text-hover text-break text-uppercase">{{category.name}}</p>
            </b-col>
          </b-row>
          <b-row v-if="isMobile || isTablet" class="text-color-dark py-3 py-lg-5">
            <b-col cols="12" class="d-flex justify-content-end"><span @click="toggleDropdown('categories')" class="material-icons-outlined cursor-pointer">close</span></b-col>
            <b-col v-for="(category, idx) in getCategoriesByRange(0)" cols="6" sm="6" md="4" lg="3" :key="`categories_${idx}`">
              <p @click="redirectCategory(category.online_store_slug)" class="size-px-12 size-px-md-14 size-px-lg-16 mb-0 pt-px-13 cursor-pointer text-hover text-break text-uppercase">{{category.name}}</p>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </section>

    <!-- Container -->
    <router-view></router-view>

    <!-- Footer -->
    <section class="bg-color-dark">
      <b-container class="custom-container pt-px-44 pb-px-44">
        <b-row>
          <b-col v-if="info.legal" cols="12" md="6" class="d-flex align-items-center">
            <b-row class="mb-5 mb-lg-0">
              <b-col v-for="([key, legal], idx) in Object.entries(info.legal)" cols="12" lg="6" :key="`legal_${idx}`">
                <p v-if="legal.text" class="text-white text-uppercase size-px-14 size-px-md-12 cursor-pointer text-underline-hover mb-1" @click="redirectLegal(key)">
                  {{ legal.name }}
                </p>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12" md="6" class="d-flex justify-content-center justify-content-lg-end">
            <a class="mb-1 d-flex flex-column align-items-center text-underline-hover" :href="$router.resolve({ name: 'portal.index' }).href">
              <b-img-lazy
                v-if="info.store_logo"
                class="img-footer"
                :src="$utilities.publicPath(info.store_logo)"
                blank-color="grey"
                blank
              />
              <p class="size-px-12 text-center text-white font-weight-thin" style="width:200px;">Copyright 2024 {{info.name}}</p>
            </a>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="auto">
            <a class="text-light size-px-24 font-weight-bold" :href="$router.resolve({ name: 'portal.contact' }).href">
              <span>{{$t('base.footer.contact')}}</span>
            </a>
          </b-col>
        </b-row>

        <b-row class="border-top-1 border-color-grey-light justify-content-end mb-4">
          <b-col cols="12" md="6" class="my-5 order-2 order-lg-1">
            <a class="text-color-grey">Powered By: Lizto Software</a>
          </b-col>
          <b-col cols="12" md="6" class="d-flex justify-content-between align-items-center my-5 order-1 order-lg-2">
            <a v-if="info.url_website" :href="info.url_website" target="_blank"><font-awesome-icon class="size-px-25 text-white cursor-pointer" icon="fa-solid fa-globe" /></a>
            <a v-if="info.url_instagram" :href="info.url_instagram" target="_blank"><font-awesome-icon class="size-px-25 text-white cursor-pointer" icon="fa-brands fa-instagram" /></a>
            <a v-if="info.url_facebook" :href="info.url_facebook" target="_blank"><font-awesome-icon class="size-px-25 text-white cursor-pointer" icon="fa-brands fa-facebook" /></a>
            <a v-if="info.url_youtube" :href="info.url_youtube" target="_blank"><font-awesome-icon class="size-px-25 text-white cursor-pointer" icon="fa-brands fa-youtube" /></a>
            <a v-if="info.url_twitter" :href="info.url_twitter" target="_blank"><font-awesome-icon class="size-px-25 text-white cursor-pointer" icon="fa-brands fa-x-twitter" /></a>
            <a v-if="info.url_tiktok" :href="info.url_tiktok" target="_blank"><font-awesome-icon class="size-px-25 text-white cursor-pointer" icon="fa-brands fa-tiktok" /></a>
            <a v-if="info.url_snapchat" :href="info.url_snapchat" target="_blank"><font-awesome-icon class="size-px-25 text-white cursor-pointer" icon="fa-brands fa-snapchat" /></a>
            <a v-if="info.url_pinterest" :href="info.url_pinterest" target="_blank"><font-awesome-icon class="size-px-25 text-white cursor-pointer" icon="fa-brands fa-pinterest" /></a>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <!-- Notification Toast -->
    <b-toast v-model="notificationToast.state" toaster="b-toaster-top-center" header-class="border-bottom-0 bg-color-transparent d-none" body-class="bg-color-dark" auto-hide-delay="3000">
      <div class="d-flex align-items-center">
        <span v-if="notificationToast.color === 'danger'" class="text-color-red material-icons">highlight_off</span>
        <span v-if="notificationToast.color === 'success'" class="text-color-wp material-icons">check</span>
        <span class="ml-2 text-light">{{notificationToast.message}}</span>
      </div>
    </b-toast>

    <!-- Modal search -->
    <b-modal v-model="modalSearch" hide-header hide-footer dialog-class="m-0 p-0" modal-class="p-0" content-class="w-vw-100" body-class="h-vh-100">
      <div class="d-flex justify-content-end mb-2">
        <a class="close cursor-pointer opacity-100" aria-label="Close" @click="showModalSearch">
          <span aria-hidden="true">&times;</span>
        </a>
      </div>

      <div class="search d-flex flex-column align-items-center" style="height: 100%;">
        <b-form-input autofocus @keydown.enter="onTextSearch" type="text" v-model="q" autocomplete="off" id="coupon" class="search-placeholder mb-3" :placeholder="$t('base.main_menu.search.placeholder')"></b-form-input>
        <b-button class="bg-color-dark" @click="onTextSearch"><span class="size-px-18 mt-3 cursor-pointer text-light">{{$t('base.main_menu.search.label')}}</span></b-button>
      </div>
    </b-modal>

    <!-- Modal Promotional Banner Content -->
    <b-modal v-model="modalBanner" size="md" content-class="rounded-none" hide-header hide-footer centered>
      <template #default="{ hide }">
        <a class="close cursor-pointer opacity-100" aria-label="Close" @click="hide('forget')">
          <span aria-hidden="true">&times;</span>
        </a>

        <b-container class="py-5">
          <b-row>
            <b-col cols="12" class="d-flex flex-column justify-content-center">
              <p style="white-space: break-spaces;" class="text-color-dark text-uppercase size-px-12 cursor-pointer">
                {{promotionalBannerContent}}
              </p>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </b-modal>

    <!-- Modal Cart -->
    <b-modal v-model="modalCart.state" dialog-class="modal-width-custom-750" content-class="rounded-md" hide-header hide-footer centered>
      <template>
        <!-- Contenedor carrito vacio -->
        <b-container v-if="cart.items.length === 0" class="scroll-detail-product-modal py-5" style="max-height:700px; overflow-y:auto;">
          <b-row class="height-vh-25">
            <b-col cols="12" class="d-flex flex-column justify-content-center align-items-center">
              <b-img-lazy
                v-if="info.store_logo"
                class="img-logo"
                :src="$utilities.publicPath(info.store_logo)"
                blank-color="grey"
                blank
              />

              <p class="text-color-dark size-px-16 mb-0 font-weight-bold">{{$t('base.modal_cart.cart_empty.oh')}}</p>

              <p class="text-color-dark size-px-16 text">{{$t('base.modal_cart.cart_empty.your_cart_empty')}}</p>
            </b-col>
          </b-row>
        </b-container>

        <!-- contenedor carrito con productos -->
        <b-container v-else class="scroll-detail-product-modal my-4" style="max-height:700px; overflow-y:auto;">
          <b-row>
            <b-col cols="12" class="d-flex flex-column justify-content-center">
              <p v-if="modalCart.message" class="bg-color-dark text-light d-flex align-items-center font-weight-bold py-3">
                <span class="font-weight-bold mx-3 size-px-30 text-success mr-2 material-icons">check</span>
                <span>{{modalCart.message}}</span>
              </p>

              <p class="text-center size-px-25 font-weight-bold mb-3">{{$t('base.modal_cart.cart_with_product.your_cart')}}</p>

              <b-row v-for="(product, idx) in cart.items" :key="`product_cart_${idx}`" class="mt-3 justify-content-lg-around">
                <b-col cols="12" class="d-flex align-items-center">
                  <b-img-lazy
                    v-if="product.product.photo"
                    class="aspect-ratio-1-1"
                    width="60px"
                    :src="$utilities.publicPath(product.product.photo)"
                    blank-color="grey"
                    blank
                  />

                  <div v-else style="width:60px;" class="d-flex justify-content-center align-items-center aspect-ratio-1-1">
                    <span class="material-icons">inventory</span>
                  </div>

                  <div class="w-100">
                    <div class="d-flex flex-column flex-md-row justify-content-md-around align-items-md-center">
                      <span class="ml-3 ml-md-0 size-px-14 size-px-md-16 text-color-grey w-cart-product-custom">{{product.product.name}}</span>
                      <span class="ml-3 ml-md-0 size-px-14">{{product.price | currencyFormat}}</span>

                      <div>
                        <div class="ml-3 ml-md-0 size-px-14 text-center quantity d-inline p-1 p-lg-2" style="display: inner-block; height: 44px;">
                          <span class="decrease" @click="decrementQuantity(product)">-</span>

                          <input
                            readonly
                            id="quantity"
                            type="number"
                            class="text-center text border-0"
                            name="quantity"
                            :value="product.quantity"
                            :aria-label="$t('app.text.quantity')"
                            size="4"
                            min="1"
                            max="999"
                            step="1"
                            placeholder=""
                            inputmode="numeric"
                            autocomplete="off"
                          >

                          <span class="increase" @click="incrementQuantity(product)">+</span>
                        </div>
                      </div>
                      <span class="ml-3 ml-md-0 size-px-14 font-weight-bold">{{product.total | currencyFormat}}</span>
                      <span v-if="isDesktop || isTablet" @click="removeToCart(product)" class="ml-3 ml-md-0 size-px-26 cursor-pointer text-color-white-grey material-icons-outlined">highlight_off</span>
                      <span  v-if="isMobile" @click="removeToCart(product)" class="ml-3 ml-md-0 size-px-16 cursor-pointer text-color-red">{{$t('cart.text.remove')}}</span>
                    </div>
                  </div>
                </b-col>
              </b-row>

              <b-row class="border-top-1 border-color-white-grey pt-2 my-4">
                <b-col cols="12" md="6"  class="size-px-17 size-px-md-25 font-weight-bold d-flex justify-content-start">
                  <span>{{$t('base.modal_cart.cart_with_product.total_cart')}}</span>
                </b-col>
                <b-col cols="12" md="6" class="size-px-25 font-weight-bold d-flex justify-content-md-end">
                  <span >{{cart.totals.total | currencyFormat}}</span>
                </b-col>
              </b-row>

              <div>
                <b-button class="bg-color-dark width-pct-100 mb-2 py-3 rounded-xl" @click="redirectCart">
                  <span class="size-px-14">{{$t('base.modal_cart.cart_with_product.see_cart')}}</span>
                </b-button>
                <b-button class="bg-color-dark width-pct-100 mb-2 py-3 rounded-xl" :href="$router.resolve({ name: 'cart.checkout' }).href">
                  <span class="size-px-14">{{$t('base.modal_cart.cart_with_product.pay')}}</span>
                </b-button>
                <b-button class="bg-color-dark width-pct-100 mb-2 py-3 rounded-xl" @click="hideModalCart">
                  <span class="size-px-14">{{$t('base.modal_cart.cart_with_product.keep_buying')}}</span>
                </b-button>
              </div>

              <div>
                <BannerProducts :title="$t('base.modal_cart.cart_with_product.highlight_month')" onlyThreeCols onlyHighlight/>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </b-modal>

    <!-- Modal SHOW PRODUCT -->
    <PreviewProduct
      v-if="productShowModal"
      :modalVisible="modalProduct"
      :productShowModal="productShowModal"
      :info="info"
      :categoryName="categoryName"
      :canShowBuy="canShowBuy"
      :quantity="quantity"
      @changeProductShowModal="changeProductShowModal"
      @redirectDetailProduct="redirectDetailProduct"
      @showModalCart="showModalCart"
      @hideModalProduct="hideModalProduct"
      @addToCart="addToCart"
      @incrementQuantity="incrementQuantity"
      @decrementQuantity="decrementQuantity"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BannerProducts from '@/components/bannersProducts/BannerProducts.vue'
import PreviewProduct from '@/components/PreviewProduct.vue'

import LoadingMixin from '@/plugins/Common/LoadingMixin.js'
import AddCartMixin from '@/mixins/AddCartMixin.js'
import ResizeMixin from '@/mixins/ResizeMixin.js'
import RedirectMixin from '@/mixins/RedirectMixin.js'

export default {
  name: 'ViewBase',
  data () {
    return {
      q: '',

      modalWhatsapp: false,
      modalBanner: false,
      modalProduct: false,
      modalSearch: false,

      showDropdownNews: false,
      showDropdownCategories: false,
      showDropdownBrands: false
    }
  },
  methods: {
    toggleDropdown (type) {
      if (type === 'news') {
        this.showDropdownNews = !this.showDropdownNews
        this.showDropdownBrands = false
        this.showDropdownCategories = false
      } else if (type === 'brands') {
        this.showDropdownBrands = !this.showDropdownBrands
        this.showDropdownNews = false
        this.showDropdownCategories = false
      } else if (type === 'categories') {
        this.showDropdownCategories = !this.showDropdownCategories
        this.showDropdownNews = false
        this.showDropdownBrands = false
      }
    },
    onTextSearch () {
      if (this.q) {
        const url = this.$router.resolve({ name: 'portal.products.search', query: { q: this.q } }).href
        window.location.assign(url)
      }
    },
    removeToCart (item) {
      if (this.statusIsLoading()) return

      this.statusLoading()

      this.$Models.Cart
        .remove(item.id)
        .then(res => {
          this.statusLoadingComplete()

          if (res.ok) {
            this.showSuccessMessage(res.message)
            this.$store.commit('cart/setCart', res.cart)
          } else {
            this.$store.commit('cart/setCart', res.cart)
            this.showError(res.message)
          }
        })
        .catch(err => {
          this.statusLoadingFail()
          this.showError(err)
        })
    },
    redirectHome () {
      const url = this.$router.resolve({
        name: 'portal.index'
      }).href

      window.location.assign(url)
    },
    redirectWhatsapp (indicator, phone) {
      const whatsappNumber = indicator + phone
      window.open(`https://wa.me/${whatsappNumber}`, '_blank')
    },
    redirectNewCategories (categoryStoreSlug) {
      const url = this.$router.resolve({
        name: 'portal.products.category',
        params: { categoryStoreSlug },
        query: { is_new: true }
      }).href

      window.location.assign(url)
    },
    redirectLegal (legalSlug) {
      const url = this.$router.resolve({
        name: 'portal.legal',
        params: { pageLegalSlug: legalSlug }
      }).href

      window.location.assign(url)
    },
    redirectGiftCard () {
      const url = this.$router.resolve({
        name: 'portal.giftCards'
      }).href

      window.location.assign(url)
    },
    redirectBookingLink (link) {
      window.open(link, '_blank')
    },
    redirectCart () {
      const url = this.$router.resolve({
        name: 'cart'
      }).href

      window.location.assign(url)
    },
    showModalCart (showMessage = true) {
      if (this.modalProduct) {
        this.hideModalProduct()
      }
      this.modalCart.state = true
      if (showMessage) {
        this.modalCart.message = this.$t('base.modal_cart.cart_with_product.add_product')
      } else {
        this.modalCart.message = null
      }
      this.$store.commit('general/setModalCart', this.modalCart)
    },
    hideModalCart () {
      this.modalCart.state = false
      this.modalCart.message = null
      this.$store.commit('general/setModalCart', this.modalCart)
    },
    getCategoriesByRange (init, end = this.categories.length - 1) {
      if (init > end) {
        return this.categories
      } else {
        return this.categories.slice(init, end)
      }
    },
    showModalBanner () {
      if (this.promotionalBannerContent) {
        this.modalBanner = !this.modalBanner
      }
    },
    showModalSearch () {
      this.modalSearch = !this.modalSearch
    },
    showModalWhatsapp () {
      this.modalWhatsapp = !this.modalWhatsapp
    }
  },
  computed: {
    ...mapState('general', {
      promotionalBannerText: state => state.info.promotional_banner_text,
      promotionalBannerContent: state => state.info.promotional_banner_content,
      notificationToast: state => state.notificationToast,
      brands: state => state.filters.brands
    }),
    isCategoryMoreTen () {
      if (this.categories.length > 0 && this.categories.length > 10) {
        return true
      } else if (this.categories.length > 0 && this.categories.length <= 10) {
        return false
      } else {
        return false
      }
    }
  },

  created () {
    // setStoreSlug(this.$route.params.portalId) // standalone store change

    this.$store.dispatch('cart/loadCart')
    this.$store.dispatch('general/loadInfo', this.$route.params.portalId)
      .then(res => {
        // this.changeTitle(res.name)
        // this.changeFavicon(this.$utilities.publicPath(res.store_logo))
      })

    this.$store.dispatch('general/loadFilters')
    // .catch(res => {})
  },
  components: {
    BannerProducts,
    PreviewProduct
  },
  mixins: [
    LoadingMixin,
    AddCartMixin,
    ResizeMixin,
    RedirectMixin
  ]

}
</script>

<style lang="scss" scoped>
  .custom-container {
    max-width: 1280px;
  }
  .toast-center {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1050;
  }
  .quantity {
      transition: .5s;
      padding: 4px;
      border: 1px solid #e4e6eb !important;
      align-content: center;
      float: none;
      width: 100%;
      height: 30px;
  }
  .decrease, .quantity, .increase {
      cursor: pointer;
      display: inline-block;
      background: 0 0;
      border: none;
      user-select: none;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
      appearance: none;
      margin: 0;
  }
  input[type=number]:focus {
  outline: none;
  }

  .img-logo {
    height: 75px;
    width: 180px;
    object-fit: contain;
    aspect-ratio: 16 / 9;
  }
  .img-footer {
    width: 260px;
    object-fit: contain;
    aspect-ratio: 16 / 9;
  }
  .text-underline-hover:hover {
    text-decoration: underline !important;
    color: inherit;
  }
  .badge-number {
    position: absolute;
    top: -3px;
    right: -7px;
    border-radius: 50%;
    padding: 2px 6px;
  }
  .wp-custom {
    border-left: 2px solid #25d366;
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    background-color: rgba(0, 0, 0, 0.05);
  }
  .wp-custom:hover {
    background-color: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }
  .container-wp-custom {
    bottom:100px;
    right:30px;
    z-index:1055;
    max-width: 380px;
  }
  .container-wp-custom:hover {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 410px) {
    .container-wp-custom {
      bottom:100px;
      right:30px;
      z-index:1055;
      max-width: 310px;
    }
  }
  @media (max-width: 320px) {
    .container-wp-custom {
      bottom:100px;
      right:25px;
      z-index:1055;
      max-width: 280px;
    }
  }

  /** animations */
  .flip-enter-active, .flip-leave-active {
    transition: transform 0.5s ease;
  }
  .flip-enter, .flip-leave-to {
    transform: rotate(180deg) scale(0);
  }
  .flip-leave-active, .flip-enter-active {
    transition: transform 0.5s ease;
  }
  .flip-leave, .flip-enter-to {
    transform: rotate(360deg) scale(1);
  }
  .slide-fade-enter-active, .slide-fade-leave-active {
    transition: all 0.5s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateY(20px);
    opacity: 0;
  }
  /** animations */

  .scroll-detail-product-modal {
    overflow-y: auto;
    max-height: 672px;
  }
  .scroll-detail-product-modal::-webkit-scrollbar {
    width: 10px;
  }
  .scroll-detail-product-modal::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 5px;
  }
  .h-custom {
    height: 400px;
  }
  .w-cart-product-custom {
    width: auto;
  }
  @media (min-width: 576px) {
    ::v-deep .modal-width-custom-750 {
      max-width: 550px !important;
    }
  }
  @media (min-width: 768px) {
    .w-cart-product-custom {
      width: 270px;
    }
    ::v-deep .modal-width-custom-750 {
      max-width: 750px !important;
    }
  }
  @media (min-width: 992px) {
    .h-custom {
      height: 100%;
    }
    .w-cart-product-custom {
      width: 270px;
    }
  }

  //@override class boostrap - estilos para la lupa integrada en input search se sobre escribe
  .input-group-text {
    background-color: #fff !important;
    border: 1px solid #000000 !important;
    border-top-left-radius: 50px !important;
    border-bottom-left-radius: 50px !important;
  }
  .text-hover:hover {
    color: black !important;
    text-decoration: underline !important;
  }
  ::v-deep .h-vh-100 {
    height: 100vh;
  }
  ::v-deep .w-vw-100 {
    width: 100vw;
  }
</style>
