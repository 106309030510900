export default {
  text: {
    title: 'Tarjeta de regalo',
    give_it_to: 'A quien lo vas a regalar',
    your_data: 'Tus Datos',
    make_payment: 'REALIZAR PAGO'
  },
  form: {
    selected_price: {
      label: 'Selecciona un valor'
    },
    first_name: {
      label: 'Nombres',
      placeholder: 'Nombres'
    },
    last_name: {
      label: 'Apellidos',
      placeholder: 'Apellidos'
    },
    email: {
      label: 'Correo Electrónico',
      placeholder: 'Correo Electrónico'
    },
    message: {
      label: 'Mensaje (opcional)',
      placeholder: 'Ingresa aquí tu mensaje'
    },
    cellphone: {
      label: 'Celular',
      placeholder: 'Celular'
    },
    identification: {
      label: 'Identificación(C.C)',
      placeholder: 'Identificación'
    },
    custom_price: {
      label: 'Personalizado'
    },
    terms_and_condition: 'Acepta Términos y Condiciones?'
  },
  errors: {
    price_error: 'El precio debe esta entre {min} y {max}'
  }
}
