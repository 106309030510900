<template>
  <div>
    <Cart/>
  </div>
</template>

<script>
import Cart from '@/views/components/Cart.vue'

export default {
  components: {
    Cart
  }
}
</script>
