<template>
  <div>
    <Contact/>
  </div>
</template>

<script>
import Contact from '@/views/components/Contact.vue'

export default {
  components: {
    Contact
  }
}
</script>
