<template>
  <section id="section_page">
    <template v-if="currentPage">
      <b-container fluid class="p-0" v-if="currentPage.page_video_url">
        <b-embed
          type="iframe"
          aspect="16by9"
          :src="`https://www.youtube.com/embed/${extractVideoId(currentPage.page_video_url)}?rel=0&autoplay=1&mute=1&loop=1&playlist=${extractVideoId(currentPage.page_video_url)}`"
        ></b-embed>
      </b-container>
      <b-container fluid class="mt-3">
        <b-row class="pt-px-80 pb-px-80 bg-color-dark justify-content-center">
          <b-col cols="auto">
            <span class="size-px-18 size-px-md-26 size-px-lg-40 line-bottom text-light text-uppercase">{{currentPage.page_name}}</span>
          </b-col>
        </b-row>
      </b-container>

      <b-container class="custom-container">
        <div v-if="currentPage.page_content_design === 'IMG_INNER'">
          <b-row class="justify-md-content-end my-px-175 bg-custom-img py-px-100" :style="`background-image: url('${$utilities.publicPath(currentPage.page_content_img)}');`">
            <b-col cols="12" md="6" class="text-light ml-5">
              <span class="size-px-20" style="white-space: break-spaces;">{{currentPage.page_content_text}}</span>
            </b-col>
          </b-row>
        </div>

        <div v-else-if="currentPage.page_content_design === 'IMG_RIGHT'" class="my-px-100">
          <b-row class="justify-content-center">
            <b-col cols="12" lg="6" class="mb-5 mb-md-0 d-flex align-items-center">
              <span class="size-px-20" style="white-space: break-spaces;">{{currentPage.page_content_text}}</span>
            </b-col>
            <b-col cols="12" lg="6" class="text-center">
              <b-img-lazy
                class="aspect-ratio-1-1 object-fit-cover w-100"
                :src="$utilities.publicPath(currentPage.page_content_img)"
                :alt="currentPage.page_name"
                blank-color="grey"
                blank
              />
            </b-col>
          </b-row>
        </div>

        <div v-else-if="currentPage.page_content_design === 'IMG_LEFT'" class="my-px-100">
          <b-row class="justify-content-center">
            <b-col cols="12" lg="6" class="mb-5 mb-md-0 text-center">
              <b-img-lazy
                class="aspect-ratio-1-1 object-fit-cover w-100"
                :src="$utilities.publicPath(currentPage.page_content_img)"
                :alt="currentPage.page_name"
                blank-color="grey"
                blank
              />
            </b-col>
            <b-col cols="12" lg="6" class="d-flex align-items-center">
              <span class="size-px-20" style="white-space: break-spaces;">{{currentPage.page_content_text}}</span>
            </b-col>
          </b-row>
        </div>

        <div v-else-if="currentPage.page_content_design === 'IMG_TOP'" class="my-px-100">
          <b-row class="justify-content-center mb-5">
            <b-col cols="12" md="6" class="text-center">
              <b-img-lazy
                class="aspect-ratio-1-1 object-fit-cover w-100"
                :src="$utilities.publicPath(currentPage.page_content_img)"
                :alt="currentPage.page_name"
                blank-color="grey"
                blank
              />
            </b-col>
          </b-row>

          <b-row class="justify-content-center">
            <b-col cols="12">
              <span class="size-px-20" style="white-space: break-spaces;">{{currentPage.page_content_text}}</span>
            </b-col>
          </b-row>
        </div>

        <div v-else-if="currentPage.page_content_design === 'IMG_BOTTOM'" class="my-px-100">
          <b-row class="justify-content-center">
            <b-col cols="12" lg="8">
              <span class="size-px-20" style="white-space: break-spaces;">{{currentPage.page_content_text}}</span>
            </b-col>
          </b-row>

          <b-row class="justify-content-center mt-5">
            <b-col cols="12" md="6" class="text-center">
              <b-img-lazy
                class="aspect-ratio-1-1 object-fit-cover w-100"
                :src="$utilities.publicPath(currentPage.page_content_img)"
                :alt="currentPage.page_name"
                blank-color="grey"
                blank
              />
            </b-col>
          </b-row>
        </div>

        <div v-if="!currentPage.page_content_design" class="my-px-175">
          <b-row class="justify-content-center">
            <b-col cols="12" lg="8" class="text-left">
              <span class="size-px-20" style="white-space: break-spaces;">{{currentPage.page_content_text}}</span>
            </b-col>
          </b-row>

          <b-row class="justify-content-center mt-5">
            <b-col cols="12" md="6" class="text-center">
              <b-img-lazy
                class="aspect-ratio-1-1 object-fit-cover w-100"
                :src="$utilities.publicPath(currentPage.page_content_img)"
                :alt="currentPage.page_name"
                blank-color="grey"
                blank
              />
            </b-col>
          </b-row>
        </div>
      </b-container>
    </template>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      currentPage: null
    }
  },
  methods: {
    extractVideoId (url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
      const match = url.match(regExp)

      return (match && match[2].length === 11) ? match[2] : null
    },
    load () {
      const page = this.info.store_pages.find(page => page.page_unique_slug === this.$route.params.pageStoreSlug)
      if (page) {
        this.currentPage = page
      } else {
        this.$router.push('/error404')
      }
    }
  },
  computed: {
    ...mapState('general', {
      info: state => state.info
    })
  },
  watch: {
    info (oVal, nVal) {
      if (nVal) {
        this.load()
      }
    }
  },

  mixins: []
}
</script>

<style lang="scss" scoped>
  .custom-container {
    max-width: 1280px;
  }

  .bg-custom-img {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .bg-custom-img::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0;
  }

  .line-bottom {
    position: relative;
    display: inline-block;
  }

  .line-bottom::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -5px;
    transform: translateX(-50%);
    width: 117%;
    height: 3px;
    background-color: #FFFFFF;
  }
</style>
