const Mixin = {
  methods: {
    redirectToPage (page) {
      if (page.page_link_source === 'INTERNAL') {
        const routeData = {}

        if (page.page_link_type === 'PRODUCT') {
          routeData.name = 'portal.products.detail'
          routeData.params = { productStoreSlug: page.page_link_related_slug }
        }
        if (page.page_link_type === 'CATEGORY') {
          routeData.name = 'portal.products.category'
          routeData.params = { categoryStoreSlug: page.page_link_related_slug }
        }
        if (page.page_link_type === 'PAGE') {
          routeData.name = 'portal.pages.detail'
          routeData.params = { pageStoreSlug: page.page_unique_slug }
        }
        if (page.page_link_type === 'BRAND') {
          routeData.name = 'portal.products.brand'
          routeData.params = { brandStoreSlug: page.page_link_related_slug }
        }

        const url = this.$router.resolve(routeData).href
        window.location.assign(url)
      } else if (page.page_link_source === 'EXTERNAL') {
        window.open(page.page_link_url, '_blank')
      }
    },
    redirectBrand (brandStoreSlug) {
      const url = this.$router.resolve({
        name: 'portal.products.brand',
        params: { brandStoreSlug }
      }).href

      window.location.assign(url)
    },
    redirectCategory (categoryStoreSlug) {
      const url = this.$router.resolve({
        name: 'portal.products.category',
        params: { categoryStoreSlug }
      }).href

      window.location.assign(url)
    },
    redirectNewProducts () {
      const url = this.$router.resolve({
        name: 'portal.products.search',
        query: { is_new: true }
      }).href

      window.location.assign(url)
    },
    redirectHighlightProducts () {
      const url = this.$router.resolve({
        name: 'portal.products.search',
        query: { is_highlight: true }
      }).href

      window.location.assign(url)
    },
    redirectOutletProducts () {
      const url = this.$router.resolve({
        name: 'portal.products.search',
        query: { is_outlet: true }
      }).href

      window.location.assign(url)
    }
  }
}

export default Mixin
