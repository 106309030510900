<template>
  <section>
    <b-container class="custom-container my-4">
      <b-row class="my-3">
        <b-col class="text-center">
          <b-card class="gift-card rounded-lg stars-bg">
            <div class="card-body">
              <h2 class="text-light">{{$t('giftCards.text.title')}}</h2>
              <div v-if="isCustomPriceInputVisible && selectedPrice === 'custom'">
                <b-form-input
                  v-model="customPrice"
                  @blur="updateGiftCardPrice"
                  ref="customPriceInput"
                  type="number"
                  :min="info.min_price_gift_cards"
                  :max="info.max_price_gift_cards"
                  class="custom-price-input text-light size-px-38 mt-px-30"
                ></b-form-input>
              </div>
              <h3 v-else class="text-light size-px-38 mt-px-30 cursor-pointer" @click="enableCustomPrice">
                {{ giftCard.price | currencyFormat }}
                <span class="material-icons-outlined">edit</span>
              </h3>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col class="text-center">
          <b-form-group :label="$t('giftCards.form.selected_price.label')" label-for="price-select">
            <b-form-select
              id="price-select"
              v-model="selectedPrice"
              :options="priceOptions"
              @change="setGiftPrice"
              class="mb-3"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col cols="12" lg="6">
          <b-row>
            <b-col class="text-center">
              <h1 class="font-weight-bold my-3">
                {{$t('giftCards.text.give_it_to')}}
              </h1>
                <b-row>
                  <b-col cols="12" sm="6" md="6">
                    <b-form-group
                      :label="$t('giftCards.form.first_name.label')"
                      label-for="input-first-name"
                    >
                      <b-form-input
                        id="input-first-name"
                        v-model="recipient.first_name"
                        @blur="handleBlur('recipient.first_name')"
                        :placeholder="$t('giftCards.form.first_name.placeholder')"
                        :state="getFieldState('recipient.first_name', 'text')"
                        :invalid-feedback="getFieldFeedback('recipient.first_name', 'text')"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="6" md="6">
                    <b-form-group
                      :label="$t('giftCards.form.last_name.label')"
                      label-for="input-last-name"
                    >
                      <b-form-input
                        id="input-last-name"
                        v-model="recipient.last_name"
                        @blur="handleBlur('recipient.last_name')"
                        :placeholder="$t('giftCards.form.last_name.placeholder')"
                        :state="getFieldState('recipient.last_name', 'text')"
                        :invalid-feedback="getFieldFeedback('recipient.last_name', 'text')"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-form-group
                  :label="$t('giftCards.form.email.label')"
                  label-for="input-email"
                >
                  <b-form-input
                    id="input-email"
                    v-model="recipient.email"
                    @blur="handleBlur('recipient.email')"
                    :placeholder="$t('giftCards.form.email.placeholder')"
                    :state="getFieldState('recipient.email', 'email')"
                    :invalid-feedback="getFieldFeedback('recipient.email', 'email')"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  :label="$t('giftCards.form.message.label')"
                  label-for="input-message"
                >
                  <b-form-textarea
                    id="input-message"
                    v-model="recipient.message"
                    :placeholder="$t('giftCards.form.message.placeholder')"
                    :rows="3"
                    :max-rows="6"
                    :counter="190"
                  ></b-form-textarea>
                </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" lg="6">
          <b-row>
            <b-col class="text-center">
              <h1 class="font-weight-bold my-3">
                {{$t('giftCards.text.your_data')}}
              </h1>

                <b-row>
                  <b-col cols="12" sm="6" md="6">
                    <b-form-group :label="$t('giftCards.form.first_name.label')" label-for="first-name">
                      <b-form-input
                        id="first-name"
                        v-model="customer.first_name"
                        @blur="handleBlur('customer.first_name')"
                        :placeholder="$t('giftCards.form.first_name.placeholder')"
                        :state="getFieldState('customer.first_name', 'text')"
                        :invalid-feedback="getFieldFeedback('customer.first_name', 'text')"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="6" md="6">
                    <b-form-group :label="$t('giftCards.form.last_name.label')" label-for="last-name">
                      <b-form-input
                        id="last-name"
                        v-model="customer.last_name"
                        @blur="handleBlur('customer.last_name')"
                        :placeholder="$t('giftCards.form.last_name.placeholder')"
                        :state="getFieldState('customer.last_name', 'text')"
                        :invalid-feedback="getFieldFeedback('customer.last_name', 'text')"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-form-group :label="$t('giftCards.form.identification.label')" label-for="identification">
                  <b-form-input
                    id="identification"
                    v-model="customer.identification"
                    @change="onChangeCustomerData"
                    @blur="handleBlur('customer.identification')"
                    :placeholder="$t('giftCards.form.identification.placeholder')"
                    :state="getFieldState('customer.identification', 'identification' )"
                    :invalid-feedback="getFieldFeedback('customer.identification', 'identification')"
                  ></b-form-input>
                </b-form-group>

                <b-row>
                  <b-col cols="12" sm="6" md="6">
                    <b-form-group :label="$t('giftCards.form.email.label')" label-for="email">
                      <b-form-input
                        id="email"
                        type="email"
                        v-model="customer.email"
                        @change="onChangeCustomerData"
                        @blur="handleBlur('customer.email')"
                        :placeholder="$t('giftCards.form.email.placeholder')"
                        :state="getFieldState('customer.email', 'email')"
                        :invalid-feedback="getFieldFeedback('customer.email', 'email')"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="6" md="6">
                    <b-form-group :label="$t('giftCards.form.cellphone.label')" label-for="cellphone">
                      <b-form-input
                        id="cellphone"
                        v-model="customer.cellphone"
                        @change="onChangeCustomerData"
                        @blur="handleBlur('customer.cellphone')"
                        :placeholder="$t('giftCards.form.cellphone.placeholder')"
                        :state="getFieldState('customer.cellphone', 'cellphone')"
                        :invalid-feedback="getFieldFeedback('customer.cellphone', 'cellphone')"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-form-checkbox
                  v-model="customer.accept_terms_and_conditions"
                  @change="handleBlur('customer.accept_terms_and_conditions')"
                  :state="getFieldState('customer.accept_terms_and_conditions', 'checkbox')"
                  :invalid-feedback="getFieldFeedback('customer.accept_terms_and_conditions', 'checkbox')"
                >
                  {{$t('giftCards.form.terms_and_condition')}}
                </b-form-checkbox>

                <a
                  target="_blank"
                  class="my-4 d-block text-left text-dark font-weight-bold"
                  :href="$router.resolve({ name: 'portal.legal', params: { pageLegalSlug: 'gift_card_policies' }}).href"
                >
                  Políticas de tarjetas regalos*
                </a>

            </b-col>
          </b-row>
        </b-col>
          <b-col cols="12">
            <div class="text-center">
              <b-button @click="preparePay" class="bg-color-dark" block size="lg">
                {{$t('giftCards.text.make_payment')}}
              </b-button>
            </div>
          </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import { mapState } from 'vuex'

import MixinPay from '@/mixins/pay'
import LoadingMixin from '@/plugins/Common/LoadingMixin.js'
import RecaptchaMixin from '@/plugins/Common/RecaptchaMixin.js'
import FormValidationMixin from '@/mixins/FormValidationMixin.js'
import currencyFormat from '@/filters/currency'

export default {
  name: 'GiftCards',
  data () {
    return {
      recaptchaToken: null,
      customPrice: 0,
      selectedPrice: 25000,
      isCustomPriceInputVisible: false,
      customer: {
        first_name: null,
        last_name: null,
        identification: null,
        email: null,
        cellphone: null,
        accept_terms_and_conditions: false
      },
      recipient: {
        first_name: null,
        last_name: null,
        email: null,
        message: null
      },
      giftCard: { price: 0 },
      voucherGifted: null,
      priceOptions: [
        {
          value: 25000,
          text: currencyFormat(25000)
        },
        {
          value: 50000,
          text: currencyFormat(50000)
        },
        {
          value: 75000,
          text: currencyFormat(75000)
        },
        {
          value: 100000,
          text: currencyFormat(100000)
        },
        {
          value: 'custom',
          text: this.$t('giftCards.form.custom_price.label')
        }
      ]
    }
  },
  methods: {
    onGetRecaptchaToken (token) {
      this.recaptchaToken = token
    },
    onChangeCustomerData () {
      // dev1: Se retira por que es una validacion que incomoda el uso de la tienda a los usuarios finales
    },
    allFormFieldsValid () {
      const recipientRelevantFields = Object.keys(this.recipient)
      const customerRelevantFields = Object.keys(this.customer)

      let validationRecipient = false
      let validationCustomer = false

      validationRecipient = recipientRelevantFields.every(fieldName => {
        if (this.isFieldNotRequired(fieldName)) {
          return true
        }

        const validationType = this.getValidationType(this.recipient.fieldName)
        return this.touchedFields[`recipient.${fieldName}`] && this.getFieldState(`recipient.${fieldName}`, validationType)
      })

      validationCustomer = customerRelevantFields.every(fieldName => {
        if (this.isFieldNotRequired(fieldName)) {
          return true
        }

        const validationType = this.getValidationType(fieldName)
        return this.touchedFields[`customer.${fieldName}`] && this.getFieldState(`customer.${fieldName}`, validationType)
      })

      return validationRecipient && validationCustomer
    },
    isFieldNotRequired (fieldName) {
      const notRequiredFields = [
        'message'
      ]

      return notRequiredFields.includes(fieldName)
    },
    getValidationType (fieldName) {
      if (fieldName === 'first_name') return 'text'
      if (fieldName === 'last_name') return 'text'
      if (fieldName === 'identification') return 'identification'
      if (fieldName === 'email') return 'email'
      if (fieldName === 'cellphone') return 'cellphone'
      if (fieldName === 'accept_terms_and_conditions') return 'checkbox'

      return true
    },
    preparePay () {
      if (this.statusIsLoading()) return

      if (this.allFormFieldsValid()) {
        this.statusLoading()

        this.getRecaptchaToken('giftCardPay')
          .then(token => { this.pay() })
      } else {
        this.showError(this.$t('app.text.error.filled_out_correctly_and_validation'))
      }
    },
    pay () {
      const body = {
        giftCard: this.giftCard,
        customer: this.customer,
        recipient: this.recipient,
        recaptcha_token: this.recaptchaToken
      }

      // this.statusLoading()
      this.$Models.GiftCard
        .pay(body)
        .then(res => {
          this.statusLoadingComplete()
          this.showSuccessMessage()

          const data = res.data.result

          this.checkout = data.checkout
          this.voucherGifted = data.voucherGifted

          this.initPaymentCheckout()
          this.clearData()
          this.clearTouchField()
        })
        .catch((err) => {
          this.showError(err)
          this.statusLoadingFail()
        })
    },
    clearData () {
      this.customPrice = 0

      this.selectedPrice = 25000

      this.isCustomPriceInputVisible = false

      this.customer = {
        first_name: null,
        last_name: null,
        identification: null,
        email: null,
        cellphone: null,
        accept_terms_and_conditions: false
      }

      this.recipient = {
        first_name: null,
        last_name: null,
        email: null,
        message: null
      }

      this.giftCard = { price: 0 }
    },
    setGiftPrice (value) {
      if (value === 'custom') {
        this.giftCard.price = this.customPrice
        this.isCustomPriceInputVisible = true
        this.$nextTick(() => {
          this.$refs.customPriceInput.focus()
        })
      } else {
        this.giftCard.price = value
      }
    },
    enableCustomPrice () {
      this.isCustomPriceInputVisible = true
      this.selectedPrice = 'custom'
      this.customPrice = this.giftCard.price
      this.$nextTick(() => {
        this.$refs.customPriceInput.focus()
      })
    },
    updateGiftCardPrice () {
      if (this.customPrice >= this.info.min_price_gift_cards && this.customPrice <= this.info.max_price_gift_cards) {
        this.giftCard.price = this.customPrice
      } else {
        this.showError(this.$t('giftCards.errors.price_error', { min: this.info.min_price_gift_cards, max: this.info.max_price_gift_cards }))
        this.giftCard.price = this.info.min_price_gift_cards
      }
      this.isCustomPriceInputVisible = false
    },
    totals () {
      return {
        amount: this.voucherGifted.price_gift,
        tax: 0,
        taxReturnBase: 0
      }
    }
  },
  computed: {
    ...mapState('general', {
      info: state => state.info
    })
  },
  watch: {
    info (nVal, oVal) {
      this.wathInfoSiteKey(nVal, oVal)
      if (nVal && nVal.min_price_gift_cards) {
        this.selectedPrice = nVal.min_price_gift_cards <= 25000 ? 25000 : nVal.min_price_gift_cards
        this.giftCard.price = nVal.min_price_gift_cards <= 25000 ? 25000 : nVal.min_price_gift_cards
      }
    }
  },
  components: {
    // PayuPaymentForm
  },
  mixins: [MixinPay, LoadingMixin, RecaptchaMixin, FormValidationMixin]
}
</script>

<style scoped>
  .custom-container {
    max-width: 1280px;
  }
  .gift-card {
    max-width: 400px;
    margin: 0 auto;
    background: #000000;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .gift-card::before {
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  border: 1px solid white;
  border-radius: 6px; /* Ajusta según tus necesidades */
  pointer-events: none;
}
  /* Diseño de card con rayas */
  .striped-bg {
    background: repeating-linear-gradient(
      #000,
      #000 37px,
      #fff 10px,
      #fff 74px
    )
  }
  /* Diseño de card con puntos */
  .stars-bg {
    background-color: black;
    background-image: radial-gradient(#ffffff 1px, transparent 1px);
    background-size: 32px 32px;
    color: white;
  }
  .custom-price-input {
    color: #fff;
    font-size: 38px;
    margin-top: 30px;
    background: transparent !important;
    border: none;
    border-bottom: 1px solid #fff;
    text-align: center;
    width: 100%;
  }
  .custom-price-input:focus {
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #fff;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
      appearance: none;
      margin: 0;
  }
</style>
