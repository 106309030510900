import { render, staticRenderFns } from "./ProductCard.vue?vue&type=template&id=72125640&scoped=true"
import script from "./ProductCard.vue?vue&type=script&lang=js"
export * from "./ProductCard.vue?vue&type=script&lang=js"
import style0 from "./ProductCard.vue?vue&type=style&index=0&id=72125640&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72125640",
  null
  
)

export default component.exports