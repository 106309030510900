<template>
  <section id="page">
    <b-container v-if="currentLegal" fluid >
      <b-row class="pt-px-60 pb-px-60 bg-color-dark">
        <b-col cols="12" class="d-flex justify-content-center">
          <span  class="size-px-30 size-px-md-40 line-bottom text-light">{{currentLegal.name}}</span>
        </b-col>
      </b-row>

      <b-row class="my-px-130 justify-content-center">
        <b-col cols="12" lg="10" class="d-flex align-items-center">
          <span class="size-px-24" style="white-space: break-spaces;">{{currentLegal.text}}</span>
        </b-col>
      </b-row>
    </b-container>

    <b-container v-else>
      <Error404 />
    </b-container>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Error404 from '../pages/Error404.vue'

export default {
  components: { Error404 },
  name: 'compLegal',
  data () {
    return {
      currentLegal: null
    }
  },
  methods: {
    load () {
      const pageLegalSlug = this.$route.params.pageLegalSlug
      const legal = this.info.legal[pageLegalSlug]
      if (legal) {
        if (legal.text) {
          this.currentLegal = legal
        } else {
          this.currentLegal = null
        }
      } else {
        this.currentLegal = null
      }
    }
  },
  computed: {
    ...mapState('general', {
      info: state => state.info
    })
  },
  watch: {
    info (oVal, nVal) {
      if (nVal) {
        this.load()
      }
    }
  },
  created () {
    this.legalName = this.$route.query.q
  }
}
</script>

<style lang="scss" scoped>
  .line-bottom {
    position: relative;
    display: inline-block;
  }

  .line-bottom::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -5px;
    transform: translateX(-50%);
    width: 101%;
    height: 3px;
    background-color: #FFFFFF;
  }
</style>
