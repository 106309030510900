export default {
  data () {
    return {
      touchedFields: {}
    }
  },
  methods: {
    handleBlur (fieldName) {
      this.$set(this.touchedFields, fieldName, true)
    },
    validateEmail (email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return re.test(email)
    },
    validateNumbers (input) {
      if (input) {
        return input.trim() !== '' && /^\d+$/.test(input)
      } else {
        return false
      }
    },
    validateText (input) {
      if (input) {
        const re = /^[a-zA-Z\s]+$/
        return re.test(input)
      } else {
        return false
      }
    },
    validateRequired (input) {
      if (input) {
        return input.length > 0
      } else {
        return false
      }
    },
    validateCheckbox (checked) {
      return checked
    },
    validateSelect (select) {
      if (select !== null) {
        return true
      } else {
        return false
      }
    },
    validateRadio (radio) {
      if (radio !== null) {
        return true
      } else {
        return false
      }
    },
    validateIdentification (identification) {
      const re = /[0-9]/
      return re.test(identification)
    },
    validateCellphone (cellphone) {
      const re = /^[0-9]{10}$/
      return re.test(cellphone)
    },
    getFieldState (fieldName, validationType) {
      if (!this.touchedFields[fieldName]) return null

      const data = this.getFieldValue(fieldName)

      if (validationType === 'email') {
        return this.validateEmail(data)
      }
      if (validationType === 'identification') {
        return this.validateIdentification(data)
      }
      if (validationType === 'cellphone') {
        return this.validateCellphone(data)
      }
      if (validationType === 'numbers') {
        return this.validateNumbers(data)
      }
      if (validationType === 'text') {
        return this.validateText(data)
      }
      if (validationType === 'required') {
        return this.validateRequired(data)
      }
      if (validationType === 'checkbox') {
        return this.validateCheckbox(data)
      }
      if (validationType === 'select') {
        return this.validateSelect(data)
      }
      if (validationType === 'radio') {
        return this.validateRadio(data)
      }

      return true
    },

    getFieldFeedback (fieldName, validationType) {
      if (!this.touchedFields[fieldName]) return ''

      const data = this.getFieldValue(fieldName)

      if (validationType === 'email' && !this.validateEmail(data)) {
        return this.$t('app.text.validation_forms.fiel_required_email')
      } else if (validationType === 'cellphone' && !this.validateNumbers(data)) {
        return this.$t('app.text.validation_forms.fiel_required_phone')
      } else if (validationType === 'numbers' && !this.validateNumbers(data)) {
        return this.$t('app.text.validation_forms.fiel_required_number')
      } else if (validationType === 'identification' && !this.validateIdentification(data)) {
        return this.$t('app.text.validation_forms.fiel_required_identification')
      } else if (validationType === 'text' && !this.validateText(data)) {
        return this.$t('app.text.validation_forms.fiel_required_text')
      } else if (validationType === 'required' && !this.validateRequired(data)) {
        return this.$t('app.text.validation_forms.fiel_required')
      } else if (validationType === 'checkbox' && !this.validateCheckbox(data)) {
        return this.$t('app.text.validation_forms.fiel_required')
      } else if (validationType === 'select' && !this.validateSelect(data)) {
        return this.$t('app.text.validation_forms.fiel_required_option')
      } else if (validationType === 'radio' && !this.validateSelect(data)) {
        return this.$t('app.text.validation_forms.fiel_required_option')
      }
      return ''
    },

    getFieldValue (fieldName) {
      const parts = fieldName.split('.')
      let value = this
      parts.forEach(part => {
        value = value[part]
      })
      return value
    },

    clearTouchField () {
      this.touchedFields = {}
    }
  }
}
