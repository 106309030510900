<template>
  <div>
    <Products/>
  </div>
</template>

<script>
import Products from '@/views/components/Products.vue'

export default {
  components: {
    Products
  }
}
</script>
