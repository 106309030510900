<template>
  <div>
    <BannerProducts :title="$t('shop.text.news')" onlyNews/>
    <BannerProducts :title="$t('shop.text.highlight')" onlyHighlight/>
    <BannerProducts :title="$t('shop.text.outlet')" onlyOutlet/>
  </div>
</template>

<script>
import BannerProducts from '@/components/bannersProducts/BannerProducts.vue'

export default {
  name: 'CompShop',
  components: {
    BannerProducts
  }
}
</script>
