export default {
  text: {
    error: {
      filled_out_correctly: 'Por favor, asegúrate de que todos los campos están correctamente llenos.',
      filled_out_correctly_and_validation: 'Por favor, asegúrate de que todos los campos están correctamente llenos y validados.',
      error_ocurred: 'Ha ocurrido un error'
    },
    show_more: 'Ver más',
    show_less: 'Ver menos',
    select_option: 'Selecciona una opción',
    product_add_to_cart: '¡Producto añadido al carrito!',
    empty_category: 'Sin categoría',
    loading: 'Cargando...',
    quantity: 'Cantidad',
    show_all: 'Ver todo',
    validation_forms: {
      fiel_required: 'Este campo es obligatorio',
      fiel_required_email: 'Por favor, ingrese un correo electrónico válido.',
      fiel_required_phone: 'Por favor, ingrese un número de celular válido. Debe contener exactamente 10 dígitos.',
      fiel_required_number: 'Solo se permiten números en este campo.',
      fiel_required_identification: 'Por favor, ingrese una identificación válida. Debe contener entre 8 y 10 dígitos.',
      fiel_required_text: 'Solo se permiten letras y espacios en este campo.',
      fiel_required_option: 'Por favor, seleccione una opción.'
    }
  }
}
