<template>
  <section id="section_banner">
    <BannerImg
      v-if="info.type_banner_store === 'BANNER_NORMAL' && banners && banners.length > 0"
      :banners="banners"
    ></BannerImg>
    <BannerCard
      v-else-if="info.type_banner_store === 'BANNER_CARDS' && banners && banners.length > 0"
      :banners="banners"
    ></BannerCard>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import BannerImg from '@/components/Banners/BannerImg.vue'
import BannerCard from '@/components/Banners/BannerCard.vue'

export default {
  name: 'CompBanner',
  computed: {
    ...mapState('general', {
      info: state => state.info,
      banners: state => state.info.banners
    })
  },
  components: {
    BannerImg,
    BannerCard
  }
}
</script>
