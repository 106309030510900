import { format as formatCurrency } from '@/utils/currency'

function format (object, valueField = 'value', typeField = 'type') {
  let formated = ''
  if (object) {
    if (object[typeField] === 'VALUE') {
      formated = formatCurrency(object[valueField])
    } else if (object[typeField] === 'PERCENTAGE') {
      formated = `${object[valueField]} %`
    }
  }
  return formated
}

export {
  format
}
