<template>
  <section id="cart_pay">
    <b-container fluid>
      <b-row v-if="cart.items.length > 0" class="justify-content-center mb-5">
        <b-col cols="12" lg="6" xl="5" class="px-5">
          <b-row class="border-bottom-3">
            <b-col cols="12">
              <p class="mt-5 size-px-30 font-weight-medium">{{$t('cartPay.text.checkout')}}</p>
            </b-col>
          </b-row>

          <b-row :class="step >= stepsCheckOutIndex.PERSON_DATA.index ? 'border-bottom-0' : 'pb-4 border-bottom-3 border-color-white-grey'">
            <p class="mt-5 size-px-24 font-weight-medium">{{$t('cartPay.step_input_persona.title')}}</p>

            <b-col v-show="step === stepsCheckOutIndex.PERSON_DATA.index" cols="12">
              <div class="text-dark">
                <b-row class="my-3 justify-content-center">
                  <b-col cols="12" md="6">
                    <b-form-group>
                    <label for="form_first_name" class="font-weight-bold">{{ $t('cartPay.step_input_persona.first_name.label') }}<span style="color: red;">*</span></label>
                      <b-form-input
                        id="form_first_name"
                        v-model="form.first_name"
                        :placeholder="$t('cartPay.step_input_persona.first_name.placeholder')"
                        @blur="handleBlur('form.first_name')"
                        :state="getFieldState('form.first_name', 'text')"
                        :invalid-feedback="getFieldFeedback('form.first_name', 'text')"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6">
                    <b-form-group>
                      <label for="form_last_name" class="font-weight-bold">{{ $t('cartPay.step_input_persona.last_name.label') }}<span style="color: red;">*</span></label>
                      <b-form-input
                        id="form_last_name"
                        v-model="form.last_name"
                        :placeholder="$t('cartPay.step_input_persona.last_name.placeholder')"
                        @blur="handleBlur('form.last_name')"
                        :state="getFieldState('form.last_name', 'text')"
                        :invalid-feedback="getFieldFeedback('form.last_name', 'text')"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="my-3 justify-content-center">
                  <b-col cols="12" md="6">
                    <b-form-group>
                      <label for="form_identification" class="font-weight-bold">{{ $t('cartPay.step_input_persona.identification.label') }}<span style="color: red;">*</span></label>
                      <b-form-input
                        id="form_identification"
                        v-model="form.identification"
                        type="number"
                        :placeholder="$t('cartPay.step_input_persona.identification.placeholder')"
                        @blur="handleBlur('form.identification')"
                        :state="getFieldState('form.identification', 'identification')"
                        :invalid-feedback="getFieldFeedback('form.identification', 'identification')"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6">
                    <b-form-group>
                      <label for="form_cellphone" class="font-weight-bold">{{ $t('cartPay.step_input_persona.cellphone.label') }}<span style="color: red;">*</span></label>
                      <b-form-input
                        id="form_cellphone"
                        v-model="form.cellphone"
                        type="number"
                        :placeholder="$t('cartPay.step_input_persona.cellphone.placeholder')"
                        @blur="handleBlur('form.cellphone')"
                        :state="getFieldState('form.cellphone', 'cellphone')"
                        :invalid-feedback="getFieldFeedback('form.cellphone', 'cellphone')"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="my-3">
                  <b-col cols="12" md="6">
                    <b-form-group>
                      <label for="form_email" class="font-weight-bold">{{ $t('cartPay.step_input_persona.email.label') }}<span style="color: red;">*</span></label>
                      <b-form-input
                        id="form_email"
                        v-model="form.email"
                        :placeholder="$t('cartPay.step_input_persona.email.placeholder')"
                        @blur="handleBlur('form.email')"
                        :state="getFieldState('form.email', 'email')"
                        :invalid-feedback="getFieldFeedback('form.email', 'email')"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="auto">
                    <b-button class="bg-color-dark rounded-full" @click="validateStep">
                      <span class="size-px-18">{{$t('cartPay.text.continue')}}</span>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>

          <b-row v-show="step > stepsCheckOutIndex.PERSON_DATA.index" class="mb-4 border-bottom-2 border-color-dark">
            <b-col cols="12">
              <div class="text-dark py-3">
                  <b-row class="mb-3 justify-content-end">
                    <b-col cols="auto">
                      <b-button class="bg-color-dark rounded-full"  @click="editStep(stepsCheckOutIndex.PERSON_DATA.index)">
                        <span class="size-px-18">{{$t('cartPay.text.edit')}}</span>
                      </b-button>
                    </b-col>
                  </b-row>

                  <b-row class="my-3 justify-content-center">
                    <b-col cols="12" md="3">
                      <label for="form_first_name" class="size-px-16 font-weight-bold">{{$t('cartPay.step_input_persona.first_name.label')}}</label>
                    </b-col>
                    <b-col cols="12" md="8">
                      <span>{{form.first_name}} {{form.last_name}}</span>
                    </b-col>
                  </b-row>

                  <b-row class="my-3 justify-content-center">
                    <b-col cols="12" md="3">
                      <label for="form_identification" class="size-px-16 font-weight-bold">{{$t('cartPay.step_input_persona.identification.label')}}</label>
                    </b-col>
                    <b-col cols="12" md="8">
                      <span>{{form.identification}}</span>
                    </b-col>
                  </b-row>

                  <b-row class="my-3 justify-content-center">
                    <b-col cols="12" md="3">
                      <label for="form_cellphone" class="size-px-16 font-weight-bold">{{$t('cartPay.step_input_persona.cellphone.label')}}</label>
                    </b-col>
                    <b-col cols="12" md="8">
                      <span>{{form.cellphone}}</span>
                    </b-col>
                  </b-row>

                  <b-row class="my-3 justify-content-center">
                    <b-col cols="12" md="3">
                      <label for="form_email" class="size-px-16 font-weight-bold">{{$t('cartPay.step_input_persona.email.label')}}</label>
                    </b-col>
                    <b-col cols="12" md="8">
                      <span>{{form.email}}</span>
                    </b-col>
                  </b-row>
              </div>
            </b-col>
          </b-row>

          <b-row :class="step >= stepsCheckOutIndex.ADDRESS.index ? 'border-bottom-0' : 'border-bottom-3 border-color-white-grey'">
            <p class="size-px-24 font-weight-medium my-4" :class="step >= stepsCheckOutIndex.ADDRESS.index ? 'text-color-dark': 'text-color-white-grey'">{{$t('cartPay.step_input_address.title')}}</p>

            <b-col v-show="step === stepsCheckOutIndex.ADDRESS.index" cols="12">
              <div class="text-dark pb-4">
                <b-row class="my-3 mb-4">
                  <b-col cols="12" md="3">
                    <span class="size-px-16 font-weight-bold">{{$t('cartPay.step_input_address.country.label')}}<span style="color: red;">*</span></span>
                  </b-col>
                  <b-col cols="12" md="8">
                    <span class="font-weight-bold">Colombia</span>
                  </b-col>
                </b-row>

                <b-row class="my-3 justify-content-center">
                  <b-col cols="12" md="6">
                    <b-form-group>
                      <label for="form_departament_code" class="font-weight-bold">{{ $t('cartPay.step_input_address.department.label') }}<span style="color: red;">*</span></label>
                      <SelectDepartment
                        id="form_departament_code"
                        v-model="form.departament_code"
                        @input="onChangeDepartmentCode($event)"
                        @blur="handleBlur('form.departament_code')"
                        :state="getFieldState('form.departament_code', 'select')"
                        :invalid-feedback="getFieldFeedback('form.departament_code', 'select')"
                      ></SelectDepartment>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6">
                    <!-- // check later dev3 error, al memomento editar no carga el municipal ya seleccionado -->
                    <b-form-group>
                      <label for="form_municipality_code" class="font-weight-bold">{{ $t('cartPay.step_input_address.municipality.label') }}<span style="color: red;">*</span></label>
                      <SelectMunicipality
                        id="form_municipality_code"
                        ref="selectMunicipality"
                        v-model="form.municipality_code"
                        :departmentCode="form.departament_code"
                        @blur="handleBlur('form.municipality_code')"
                        :state="getFieldState('form.municipality_code', 'select')"
                        :invalid-feedback="getFieldFeedback('form.municipality_code', 'select')"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="my-3 justify-content-center">
                  <b-col cols="12" md="6">
                    <b-form-group>
                      <label for="form_reference_point" class="font-weight-bold">{{ $t('cartPay.step_input_address.reference_point.label') }}<span style="color: red;">*</span></label>
                      <b-form-input
                        id="form_reference_point"
                        v-model="form.reference_point"
                        class="mb-3"
                        :placeholder="$t('cartPay.step_input_address.reference_point.placeholder')"
                        @blur="handleBlur('form.reference_point')"
                        :state="getFieldState('form.reference_point', 'required')"
                        :invalid-feedback="getFieldFeedback('form.reference_point', 'required')"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6">
                    <b-form-group>
                      <label for="form_direction" class="font-weight-bold">{{ $t('cartPay.step_input_address.direction.label') }}<span style="color: red;">*</span></label>
                      <b-form-input
                        id="form_direction"
                        v-model="form.direction"
                        class="mb-3"
                        :placeholder="$t('cartPay.step_input_address.direction.placeholder')"
                        @blur="handleBlur('form.direction')"
                        :state="getFieldState('form.direction', 'required')"
                        :invalid-feedback="getFieldFeedback('form.direction', 'required')"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="my-3 justify-content-center">
                  <b-col cols="12" md="6">
                    <b-form-group
                      :label="$t('cartPay.step_input_address.apart_tower_house.label')"
                      label-for="form_apart_tower_house"
                      label-class="font-weight-bold"
                    >
                      <b-form-input
                        id="form_apart_tower_house"
                        v-model="form.apart_tower_house"
                        class="mb-3"
                        :placeholder="$t('cartPay.step_input_address.apart_tower_house.placeholder')"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6">
                    <b-form-group>
                      <label for="form_location_postal_code" class="font-weight-bold">{{ $t('cartPay.step_input_address.location_postal_code.label') }}<span style="color: red;">*</span></label>
                      <b-form-input
                        id="form_location_postal_code"
                        v-model="form.location_postal_code"
                        class="mb-3"
                        type="number"
                        :placeholder="$t('cartPay.step_input_address.location_postal_code.placeholder')"
                        @blur="handleBlur('form.location_postal_code')"
                        :state="getFieldState('form.location_postal_code', 'numbers')"
                        :invalid-feedback="getFieldFeedback('form.location_postal_code', 'numbers')"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="auto">
                    <b-button class="bg-color-dark rounded-full" @click="validateStep">
                      <span class="size-px-18">{{$t('cartPay.text.continue')}}</span>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>

          <b-row v-show="step > stepsCheckOutIndex.ADDRESS.index" class="mb-4 border-bottom-2 border-color-dark">
            <b-col cols="12">
              <div class="text-dark py-3">
                <b-row class="mb-3 justify-content-end">
                  <b-col cols="auto">
                    <b-button class="bg-color-dark rounded-full"  @click="editStep(stepsCheckOutIndex.ADDRESS.index)">
                      <span class="size-px-18">{{$t('cartPay.text.edit')}}</span>
                    </b-button>
                  </b-col>
                </b-row>

                <b-row class="my-3 justify-content-center mb-4">
                  <b-col cols="12" md="3">
                    <span class="size-px-16 font-weight-bold">{{$t('cartPay.step_input_address.country.label')}}</span>
                  </b-col>
                  <b-col cols="12" md="8">
                    <span class="font-weight-bold">Colombia</span>
                  </b-col>
                </b-row>

                <b-row class="my-3 justify-content-center">
                  <b-col cols="12" md="3">
                    <label for="form_departament_code" class="size-px-16 font-weight-bold">{{$t('cartPay.step_input_address.department.label')}}</label>
                  </b-col>
                  <b-col cols="12" md="8">
                    <span>{{form.departament_code}}</span>
                  </b-col>
                </b-row>

                <b-row class="my-3 justify-content-center">
                  <b-col cols="12" md="3">
                    <label for="form_municipality_code" class="size-px-16 font-weight-bold">{{$t('cartPay.step_input_address.municipality.label')}}</label>
                  </b-col>
                  <b-col cols="12" md="8">
                    <span>{{form.municipality_code}}</span>
                  </b-col>
                </b-row>

                <b-row class="my-3 justify-content-center">
                  <b-col cols="12" md="3">
                    <label for="form_reference_point" class="size-px-16 font-weight-bold">{{$t('cartPay.step_input_address.reference_point.label')}}</label>
                  </b-col>
                  <b-col cols="12" md="8">
                    <span>{{form.reference_point}}</span>
                  </b-col>
                </b-row>

                <b-row class="my-3 justify-content-center">
                  <b-col cols="12" md="3">
                    <label for="form_direction" class="size-px-16 font-weight-bold">{{$t('cartPay.step_input_address.direction.label')}}</label>
                  </b-col>
                  <b-col cols="12" md="8">
                    <span>{{form.direction}}</span>
                  </b-col>
                </b-row>

                <b-row class="my-3 justify-content-center">
                  <b-col cols="12" md="3">
                    <label for="form_apart_tower_house" class="size-px-16 font-weight-bold">{{$t('cartPay.step_input_address.apart_tower_house.label')}}</label>
                  </b-col>
                  <b-col cols="12" md="8">
                    <span v-if="form.apart_tower_house">{{form.apart_tower_house}}</span>
                    <span v-else>{{$t('cartPay.text.not_data')}}</span>
                  </b-col>
                </b-row>

                <b-row class="my-3 justify-content-center">
                  <b-col cols="12" md="3">
                    <label for="form_location_postal_code" class="size-px-16 font-weight-bold">{{$t('cartPay.step_input_address.location_postal_code.label')}}</label>
                  </b-col>
                  <b-col cols="12" md="8">
                    <span v-if="form.location_postal_code">{{form.location_postal_code}}</span>
                    <span v-else>{{$t('cartPay.text.not_data')}}</span>
                  </b-col>
                </b-row>

              </div>
            </b-col>
          </b-row>

          <b-row :class="step >= stepsCheckOutIndex.COUPON.index ? 'border-bottom-0' : 'border-bottom-3 border-color-white-grey'">
            <p class="size-px-24 font-weight-medium my-4" :class="step >= stepsCheckOutIndex.COUPON.index ? 'text-color-dark' : 'text-color-white-grey'">{{$t('cartPay.step_input_coupon.title')}}</p>

            <b-col v-show="step === stepsCheckOutIndex.COUPON.index" cols="12">
              <div class="text-dark pb-4">
                <b-row v-if="cart.coupon" class="mt-5">
                  <b-col cols="auto" md="4"><span class="size-px-24 font-weight-medium">{{$t('cartPay.step_input_coupon.discount')}} {{cart.coupon.code}} <strong>({{formatValueType(cart.coupon)}}):</strong></span></b-col>
                  <b-col cols="auto" md="8" class="d-flex align-items-center">
                    <span class="font-weight-bold">{{cart.totals.discount | currencyFormat}}</span>
                    <span class="ml-2 ml-md-4 size-px-22 cursor-pointer material-icons-outlined" @click="removeCoupon(cart.coupon)">close</span>
                  </b-col>
                </b-row>
                <b-row v-else class="justify-content-center">
                  <b-col cols="12" md="8">
                    <b-form-input v-model="couponCode" id="coupon" class="mb-3" :placeholder="$t('cartPay.step_input_coupon.coupon.placeholder')"></b-form-input>
                  </b-col>
                  <b-col cols="auto" md="4">
                    <div class="mt-3">
                      <p class="size-px-14 cursor-pointer border-bottom-2" @click="addCoupon">{{$t('cartPay.step_input_coupon.apply_coupon')}}</p>
                    </div>
                  </b-col>
                </b-row>

                <b-row class="mt-3">
                  <b-col cols="auto">
                    <b-button class="bg-color-dark rounded-full" @click="validateStep">
                      <span class="size-px-18">{{$t('cartPay.text.continue')}}</span>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>

          <b-row v-show="step > stepsCheckOutIndex.COUPON.index" class="border-bottom-2 border-color-dark">
            <b-col cols="12">
              <div class="text-dark py-3">
                <b-row class="mb-3 justify-content-end">
                  <b-col cols="auto">
                    <b-button class="bg-color-dark rounded-full"  @click="editStep(stepsCheckOutIndex.COUPON.index)">
                      <span class="size-px-18">{{$t('cartPay.text.edit')}}</span>
                    </b-button>
                  </b-col>
                </b-row>

                <b-row class="justify-content-center">
                  <b-col cols="12" md="12">
                    <span v-if="cart.coupon">{{cart.coupon.code}}</span>
                    <span v-else>{{$t('cartPay.step_input_coupon.not_coupon')}}</span>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>

          <b-row :class="step >= stepsCheckOutIndex.ORDER_NOTE.index ? 'border-bottom-0' : 'border-bottom-3 border-color-white-grey'">
            <p class="size-px-24 font-weight-medium my-4" :class="step >= stepsCheckOutIndex.ORDER_NOTE.index ? 'text-color-dark' : 'text-color-white-grey'">{{$t('cartPay.step_input_order_note.title')}}</p>

            <b-col v-show="step === stepsCheckOutIndex.ORDER_NOTE.index" cols="12">
              <div class="text-dark pb-4">
                  <b-row class="my-3 justify-content-center">
                    <b-col cols="12" md="3">
                      <label for="form_order_note" class="size-px-16 font-weight-bold">{{$t('cartPay.step_input_order_note.order_note.label')}}</label>
                    </b-col>
                    <b-col cols="12" md="8">
                      <b-form-input
                        id="form_order_note"
                        v-model="form.order_note"
                        class="mb-3"
                        :placeholder="$t('cartPay.step_input_order_note.order_note.placeholder')"
                      ></b-form-input>
                    </b-col>
                  </b-row>

                  <b-row class="justify-content-end">
                    <b-col cols="auto">
                      <b-button class="bg-color-dark rounded-full" @click="validateStep">
                        <span class="size-px-18">{{$t('cartPay.text.continue')}}</span>
                      </b-button>
                    </b-col>
                  </b-row>
              </div>
            </b-col>
          </b-row>

          <b-row v-show="step > stepsCheckOutIndex.ORDER_NOTE.index" class="border-bottom-2 border-color-dark">
            <b-col cols="12">
              <div class="text-dark py-3">
                  <b-row class="mb-3 justify-content-end">
                    <b-col cols="auto">
                      <b-button class="bg-color-dark rounded-full"  @click="editStep(stepsCheckOutIndex.ORDER_NOTE.index)">
                        <span class="size-px-18">{{$t('cartPay.text.edit')}}</span>
                      </b-button>
                    </b-col>
                  </b-row>

                  <b-row class="my-3 justify-content-center">
                    <b-col cols="12" md="3">
                      <span v-if="form.order_note">{{form.order_note}}</span>
                      <span v-else>{{$t('cartPay.step_input_order_note.not_notes')}}</span>
                    </b-col>
                  </b-row>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" lg="5" xl="4">
          <b-row>
            <b-col cols="12" class="px-lg-0">
              <p class="mt-5 size-px-24 font-weight-medium">{{$t('cartPay.text.your_order')}}</p>
            </b-col>
          </b-row>
          <b-row class="mb-5">
            <b-col cols="12" class="border-1 border-color-white-grey">
              <div class="p-3 d-flex flex-column">
                <b-row class="w-100 py-3 justify-content-between border-bottom-1 border-color-white-grey" v-for="(item, idx) in cart.items" :key="`items_cart_${idx}`">
                  <b-col cols="12" md="6" class="d-flex align-items-center">
                    <b-img-lazy
                      v-if="item.product.photo"
                      class="aspect-ratio-1-1"
                      width="60px"
                      :src="$utilities.publicPath(item.product.photo)"
                      blank-color="grey"
                      blank
                    />
                    <div v-else style="width:60px; height:60px" class="d-flex justify-content-center align-items-center aspect-ratio-1-1 cursor-pointer">
                      <span class="material-icons">inventory</span>
                    </div>

                    <span class="ml-2 size-px-12 font-weight-bold">{{item.product.name}}</span>
                  </b-col>
                  <b-col cols="12" md="6" class="d-flex justify-content-end d-md-block mt-2 mt-md-0">
                    <b-row>
                      <b-col cols="auto" md="6" class="d-flex align-items-center justify-content-end">
                        <span class="text-color-grey">x{{item.quantity}}</span>
                      </b-col>
                      <b-col cols="auto" md="6" class="text-right d-flex align-items-center justify-content-end">
                        <span class="font-weight-bold">{{item.total | currencyFormat}}</span>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="w-100 py-3 justify-content-center border-bottom-1 border-color-white-grey">
                  <b-col cols="6">
                    <span>{{$t('cartPay.text.subtotal')}}</span>
                  </b-col>
                  <b-col cols="6" class="text-right">
                    <span class="size-px-14 font-weight-bold">{{cart.totals.total - cart.totals.shipping + cart.totals.discount | currencyFormat}}</span>
                  </b-col>
                </b-row>
                <b-row v-if="cart.totals.discount > 0" class="w-100 py-3 justify-content-center border-bottom-1 border-color-white-grey">
                  <b-col cols="6">
                    <span>{{$t('cartPay.text.discount')}}</span>
                  </b-col>
                  <b-col cols="6" class="text-right">
                    <span class="size-px-14 font-weight-bold">{{cart.totals.discount | currencyFormat}}</span>
                  </b-col>
                </b-row>
                <b-row class="w-100 py-3 justify-content-center border-bottom-1 border-color-white-grey">
                  <b-col cols="6">
                    <span>{{$t('cartPay.text.shipping')}}</span>
                  </b-col>
                  <b-col cols="6" class="text-right">
                    <span v-if="cart.totals.shipping > 0" class="size-px-12 font-weight-bold">{{cart.totals.shipping | currencyFormat}}</span>
                    <span v-else class="text-color-grey size-px-14 font-weight-bold">{{$t('cartPay.text.shipping_free')}}</span>
                  </b-col>
                </b-row>
                <b-row class="w-100 py-3 justify-content-center border-bottom-1 border-color-white-grey">
                  <b-col cols="6">
                    <span>{{$t('cartPay.text.total')}}</span>
                  </b-col>
                  <b-col cols="6" class="text-right">
                    <span class="size-px-14 font-weight-bold">{{cart.totals.total | currencyFormat}}</span>
                  </b-col>
                </b-row>

                <b-row class="w-100 py-3 justify-content-center border-bottom-1 border-color-white-grey">
                  <b-col cols="12">
                    <label class="custom-checkbox-container d-flex align-items-center" :class="{ 'is-invalid': !getFieldState('form.paymentMethod', 'radio') }">
                      <span class="size-px-14">{{$t('cartPay.text.payment_online')}}</span>
                      <input type="radio" v-model="form.paymentMethod" value="PAYMENT_GATEWAY" @change="handleBlur('form.paymentMethod')">
                      <span class="checkmark"></span>
                    </label>
                    <span v-if="!getFieldState('form.paymentMethod', 'radio')" class="invalid-feedback">{{ getFieldFeedback('form.paymentMethod', 'radio') }}</span>

                    <!--
                    <label class="custom-checkbox-container d-flex align-items-center" :class="{ 'is-invalid': !getFieldState('form.paymentMethod', 'radio') }">
                      <span class="size-px-14">{{$t('cartPay.text.payment_delivery')}}</span>
                      <input type="radio" v-model="form.paymentMethod" value="EXTERNAL_TRANSFER" @change="handleBlur('form.paymentMethod')">
                      <span class="checkmark"></span>
                    </label>
                    -->
                    <span v-if="!getFieldState('form.paymentMethod', 'radio')" class="invalid-feedback">{{ getFieldFeedback('form.paymentMethod', 'radio') }}</span>
                  </b-col>
                </b-row>

              <b-collapse
                v-if="info.legal"
                id="collapse-terms-conditions"
                class="my-5 p-3"
                style="max-height: 200px; overflow:auto; background:rgba(0, 0, 0, .05); box-shadow: inset 0 1px 2px rgba(0,0,0,0.1); border: 1px solid rgba(0, 0, 0, .2);"
              >
                <span>{{info.legal.terms_and_conditions.name}} </span>
                <span>{{info.legal.terms_and_conditions.text}}</span>
              </b-collapse>

              <b-row class="w-100 mt-5 b-3">
                <b-col cols="12">
                  <label class="custom-checkbox-container d-flex align-items-center" :class="{ 'is-invalid': !getFieldState('accept_terms_and_conditions', 'checkbox') }">
                    <span class="size-px-14">
                      <span>{{$t('cartPay.text.read_terms_condition_part0')}}</span>
                      <span
                        @click.stop.prevent
                        v-b-toggle.collapse-terms-conditions
                        class="font-weight-bold cursor-pointer"
                        style="text-decoration:underline;"
                      >
                      {{$t('cartPay.text.read_terms_condition_part1')}}
                      </span>
                      <span>{{$t('cartPay.text.read_terms_condition_part2')}}</span> <span style="color: red;">*</span>
                    </span>

                    <input type="checkbox" v-model="form.accept_terms_and_conditions" @change="handleBlur('form.accept_terms_and_conditions')">
                    <span class="checkmark"></span>
                  </label>

                  <span v-if="!getFieldState('form.accept_terms_and_conditions', 'checkbox')" class="invalid-feedback">{{ getFieldFeedback('form.accept_terms_and_conditions', 'checkbox') }}</span>
                </b-col>
              </b-row>

              <b-button class="bg-color-dark width-pct-100 mt-3 py-4 rounded-0" :disabled="step < stepsCheckOutIndex.PAY.index" @click="preparePay">{{$t('cartPay.text.make_order')}}</b-button>
            </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-else class="my-5">
        <b-col cols="12" class="text-center">
          <p class="font-weight-bold">{{$t('cartPay.cart_empty')}}</p>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import { mapState } from 'vuex'

import SelectDepartment from '@/components/common/SelectDepartment.vue'
import SelectMunicipality from '@/components/common/SelectMunicipality.vue'

import MixinPay from '@/mixins/pay'
import FormValidationMixin from '@/mixins/FormValidationMixin.js'
import LoadingMixin from '@/plugins/Common/LoadingMixin.js'
import RecaptchaMixin from '@/plugins/Common/RecaptchaMixin.js'

import { format as formatValueType } from '@/utils/valueType'

export default {
  data () {
    return {
      step: 0,
      stepsCheckOutIndex: {
        PERSON_DATA: { index: 0 },
        ADDRESS: { index: 1 },
        COUPON: { index: 2 },
        ORDER_NOTE: { index: 3 },
        PAY: { index: 4 }
      },
      form: {
        first_name: null,
        last_name: null,
        identification: null,
        country: null,
        departament_code: null,
        municipality_code: null,
        direction: null,
        location_postal_code: null,
        reference_point: null,
        apart_tower_house: null,
        cellphone: null,
        email: null,
        paymentMethod: 'PAYMENT_GATEWAY',
        accept_terms_and_conditions: false,
        order_note: null
      },

      couponCode: null,
      recaptchaToken: null,
      order: null,
      shipping: {},
      billing: {},
      defaultShipping: {
        notes: null,
        departament_code: null,
        municipality_code: null,
        location_address: null,
        location_city: null
      },
      defaultBilling: {
        location_address: null,
        location_neighborhood: null,
        location_postal_code: null,
        departament_code: null,
        municipality_code: null
      },
      customer: {},
      defaultCustomer: {
        first_name: null,
        last_name: null,
        identification: null,
        // identification_type: null, // Desarrollo2 Revisar Bombón
        email: null,
        cellphone: null,
        accept_terms_and_conditions: false
      },
      paymentMethod: 'PAYMENT_GATEWAY'
    }
  },
  methods: {
    formatValueType,
    addCoupon () {
      if (this.statusIsLoading()) return

      if (this.couponCode) {
        this.statusLoading()

        this.$Models.Cart.addCoupon(this.couponCode)
          .then(res => {
            this.statusLoadingComplete()

            if (res.ok) {
              this.$store.commit('cart/setCart', res.cart)
              this.couponCode = null
              this.showSuccessMessage(res.message)
            } else {
              this.$store.commit('cart/setCart', res.cart)
              this.showError(res.message)
            }
          })
          .catch((err) => {
            this.statusLoadingFail()
            this.showError(err)
          })
      }
    },
    removeCoupon (coupon) {
      if (this.statusIsLoading()) return

      if (coupon) {
        this.statusLoading()

        this.$Models.Cart.removeCoupon(coupon.code)
          .then(res => {
            this.statusLoadingComplete()

            if (res.ok) {
              this.showSuccessMessage(res.message)
              this.$store.commit('cart/setCart', res.cart)
            } else {
              this.$store.commit('cart/setCart', res.cart)
              this.showError(res.message)
            }
          })
          .catch(err => {
            this.statusLoadingFail()
            this.showError(err)
          })
      }
    },
    onGetRecaptchaToken (token) {
      this.recaptchaToken = token
    },
    setDefaultValues () {
      this.shipping = Object.assign({}, this.defaultShipping)
      this.billing = Object.assign({}, this.defaultBilling)
      this.customer = Object.assign({}, this.defaultCustomer)
    },
    onChangeDepartmentCode (departamentCode) {
      this.form.municipality_code = null
      this.$refs.selectMunicipality.setItemsByDepartment(departamentCode)
    },
    onChangeBillingDepartamentCode (departamentCode) {
      this.form.municipality_code = null
      this.$refs.selectBillingMunicipality.setItemsByDepartament(departamentCode)
    },
    getRelevantFieldsForStep () {
      const stepFields = {
        [this.stepsCheckOutIndex.PERSON_DATA.index]: [
          'first_name',
          'last_name',
          'identification',
          'cellphone',
          'email'
        ],
        [this.stepsCheckOutIndex.ADDRESS.index]: [
          'departament_code',
          'municipality_code',
          'direction',
          'reference_point',
          'location_postal_code'
        ]
      }

      return stepFields[this.step] || []
    },
    allFormFieldsValid () {
      let relevantFields = Object.keys(this.form)

      if (this.getRelevantFieldsForStep().length > 0) {
        relevantFields = this.getRelevantFieldsForStep()
      }

      return relevantFields.every(fieldName => {
        if (this.isFieldNotRequired(fieldName)) {
          return true
        }

        const validationType = this.getValidationType(fieldName)
        return this.touchedFields[`form.${fieldName}`] && this.getFieldState(`form.${fieldName}`, validationType)
      })
    },
    isFieldNotRequired (fieldName) {
      const notRequiredFields = [
        'apart_tower_house',
        'order_note',
        'country'
        // 'location_postal_code'
      ]

      return notRequiredFields.includes(fieldName)
    },
    getValidationType (fieldName) {
      if (fieldName === 'first_name') return 'text'
      if (fieldName === 'last_name') return 'text'
      // if (fieldName === 'country') return 'text'
      if (fieldName === 'departament_code') return 'select'
      if (fieldName === 'municipality_code') return 'select'
      if (fieldName === 'direction') return 'required'
      // if (fieldName === 'apart_tower_house') return 'text'
      if (fieldName === 'reference_point') return 'required'
      if (fieldName === 'cellphone') return 'cellphone'
      if (fieldName === 'email') return 'email'
      if (fieldName === 'accept_terms_and_conditions') return 'checkbox'
      if (fieldName === 'location_postal_code') return 'numbers'

      return true
    },
    validateStep () {
      if (this.step === this.stepsCheckOutIndex.PERSON_DATA.index) {
        const isValid = this.allFormFieldsValid()
        if (isValid) {
          this.step += 1
        } else {
          this.$t('app.text.error.filled_out_correctly_and_validation')
          this.showError(this.$t('app.text.error.filled_out_correctly_and_validation'))
        }
      } else if (this.step === this.stepsCheckOutIndex.ADDRESS.index) {
        const isValid = this.allFormFieldsValid()
        if (isValid) {
          this.step += 1
        } else {
          this.showError(this.$t('app.text.error.filled_out_correctly_and_validation'))
        }
      } else if (this.step === this.stepsCheckOutIndex.COUPON.index) {
        this.step += 1
      } else if (this.step === this.stepsCheckOutIndex.ORDER_NOTE.index) {
        this.step += 1
      }
    },
    editStep (step) {
      this.step = step
    },
    preparePay () {
      if (this.statusIsLoading()) return

      if (this.allFormFieldsValid()) {
        this.statusLoading()

        this.getRecaptchaToken('cartPay')
          .then(token => { this.pay() })
      } else {
        this.showError(this.$t('app.text.error.filled_out_correctly_and_validation'))
      }
    },
    getCartNotes () {
      if (this.form.order_note && this.form.apart_tower_house) {
        return `
          - Nota: ${this.form.order_note}
          - Apartamento/Torre/Casa: ${this.form.apart_tower_house}
        `
      } else if (this.form.order_note) {
        return `- Nota: ${this.form.order_note}`
      } else if (this.form.apart_tower_house) {
        return `- Apartamento/Torre/Casa: ${this.form.apart_tower_house}`
      } else {
        return null
      }
    },
    pay () {
      const body = {
        // cart: this.cart,
        payment_method: this.form.paymentMethod,
        customer: {
          identification: this.form.identification,
          first_name: this.form.first_name,
          last_name: this.form.last_name,
          email: this.form.email,
          cellphone: this.form.cellphone,
          accept_terms_and_conditions: this.form.accept_terms_and_conditions
        },
        shipping: {
          location_address: this.form.direction,
          departament_code: this.form.departament_code,
          municipality_code: this.form.municipality_code,
          location_city: this.form.reference_point,
          notes: this.getCartNotes()
        },
        billing: {
          location_postal_code: this.form.location_postal_code
        },
        recaptcha_token: this.recaptchaToken
      }

      this.statusLoading()

      this.$Models.Cart
        .pay(body)
        .then(res => {
          this.statusLoadingComplete()
          this.showSuccessMessage()

          const data = res.data.result
          this.checkout = data.checkout
          this.order = data.order

          this.initPaymentCheckout()

          this.setDefaultValues()
        })
        .catch((err) => {
          this.showError(err)
          this.statusLoadingFail()
        })
    },
    totals () {
      return {
        amount: this.order.total,
        tax: this.order.total_tax,
        taxReturnBase: this.order.total - this.order.total_tax
      }
    }
  },
  computed: {
    departamentCodeSelected () {
      return this.shipping.departament_code !== null && this.shipping.departament_code !== undefined
    },
    billingDepartamentCodeSelected () {
      return this.billing.departament_code !== null && this.billing.departament_code !== undefined
    },
    ...mapState('general', {
      info: state => state.info
    }),
    ...mapState('cart', {
      cart: state => state.cart
    }),
    state () {
      return this.form.first_name.length > 0
    }
  },
  watch: {
    info (nVal, oVal) {
      this.wathInfoSiteKey(nVal, oVal)
    }
  },
  created () {
    this.setDefaultValues()
  },
  components: {
    // PayuPaymentForm,
    SelectDepartment,
    SelectMunicipality
  },
  mixins: [MixinPay, LoadingMixin, RecaptchaMixin, FormValidationMixin]
}
</script>

<style lang="scss" scoped>
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
  }
  input[type=number]:focus {
  outline: none;
  }
  .custom-checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
  }
  .custom-checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .checkmark {
    position: absolute;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #e4e6eb;
    border: 1px solid #e4e6eb;
  }
  .custom-checkbox-container input:checked ~ .checkmark {
    background-color: white;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .custom-checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }
  .custom-checkbox-container .checkmark:after {
    left: 9px;
    top: 3px;
    width: 6px;
    height: 13px;
    border: solid black;
    border-width: 0 2px 3px 0;
    transform: rotate(45deg);
  }
</style>
