import Vue from 'vue'
import Router from 'vue-router'

import Base from '@/views/templates/Base.vue'

// import PortalNotFoundPage from '@/views/pages/PortalNotFoundPage.vue'
// import PortalInactivePage from '@/views/pages/PortalInactivePage.vue'
import MainPage from '@/views/pages/MainPage.vue'
import ProductDetailPage from '@/views/pages/Product/ProductDetailPage.vue'
import ProductsPage from '@/views/pages/Product/ProductsPage.vue'
import ContactPage from '@/views/pages/Product/ContactPage.vue'
import LegalPage from '@/views/pages/LegalPage.vue'
import GiftCards from '@/views/components/GiftCards.vue'
import CartPage from '@/views/pages/Cart/CartPage.vue'
import CartPayPage from '@/views/pages/Cart/CartPayPage.vue'
import PageDetailPage from '@/views/pages/Pages/PageDetailPage.vue'
import Error404 from '@/views/pages/Error404.vue'

Vue.use(Router)

// standalone store change

export default new Router({
  mode: 'history',
  routes: [
    /*
    {
      path: '/not-found',
      component: PortalNotFoundPage,
      name: 'portal-not-found',
      meta: { label: '' }
    },
    {
      path: '/inactive',
      component: PortalInactivePage,
      name: 'portal-inactive',
      meta: { label: '' }
    },
    */
    {
      path: '/',
      component: Base,
      meta: { label: '' },
      children: [
        {
          path: '',
          name: 'portal.index',
          component: MainPage,
          meta: { label: '' }
        },
        {
          path: 'pages/:pageStoreSlug',
          name: 'portal.pages.detail',
          component: PageDetailPage,
          meta: { label: '' }
        },
        {
          path: 'products/search',
          name: 'portal.products.search',
          component: ProductsPage,
          meta: { label: '' }
        },
        {
          path: 'products/category/:categoryStoreSlug',
          name: 'portal.products.category',
          component: ProductsPage,
          meta: { label: '' }
        },
        {
          path: 'products/brand/:brandStoreSlug',
          name: 'portal.products.brand',
          component: ProductsPage,
          meta: { label: '' }
        },
        {
          path: 'products/:productStoreSlug',
          name: 'portal.products.detail',
          component: ProductDetailPage,
          meta: { label: '' }
        },
        {
          path: 'gift-cards',
          component: GiftCards,
          name: 'portal.giftCards',
          meta: { label: '' }
        },
        {
          path: 'carrito-de-compra',
          name: 'cart',
          component: CartPage,
          meta: { label: '' }
        },
        {
          path: 'checkout',
          component: CartPayPage,
          name: 'cart.checkout',
          meta: { label: '' }
        },
        {
          path: 'contact',
          name: 'portal.contact',
          component: ContactPage,
          meta: { label: '' }
        },
        {
          path: 'legal/:pageLegalSlug',
          component: LegalPage,
          name: 'portal.legal',
          meta: { label: '' }
        },
        {
          path: 'error404',
          component: Error404,
          name: 'portal.error404',
          meta: { label: '' }
        }
      ]
    }
  ]
})
