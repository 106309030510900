import { mapState } from 'vuex'

const Mixin = {
  data () {
    return {
      modalProduct: false,
      productShowModal: null,
      quantity: 1
    }
  },
  methods: {
    showModalCart (showMessage = true) {
      if (this.modalProduct) {
        this.hideModalProduct()
      }
      this.modalCart.state = true
      if (showMessage) {
        this.modalCart.message = this.$t('app.text.product_add_to_cart')
      } else {
        this.modalCart.message = null
      }
      this.$store.commit('general/setModalCart', this.modalCart)
    },
    showModalProduct (product, productMaster = []) {
      this.productShowModal = { ...product, variants: productMaster.variants, online_store_slug: productMaster.online_store_slug }
      this.modalProduct = true
    },
    hideModalProduct () {
      this.productShowModal = null
      this.modalProduct = false
    },
    addToCart (product, quantity = 1) {
      if (this.statusIsLoading()) return
      this.statusLoading()
      if (quantity > 1) {
        this.quantity = quantity
      }
      this.$Models.Cart.add(product, this.quantity)
        .then(res => {
          this.statusLoadingComplete()
          if (res.ok) {
            this.$store.commit('cart/setCart', res.cart)
            this.showSuccessMessage(res.message)
            this.quantity = 1
            this.showModalCart()
          } else {
            this.$store.commit('cart/setCart', res.cart)
            this.quantity = 1
            this.showError(res.message)
          }
        })
        .catch((err) => {
          this.statusLoadingFail()
          this.showError(err)
        })
    },
    inCart () {
      let object
      if (this.productShowModal) {
        object = this.cart.items.find(item => item.product.id === this.productShowModal.id)
      }
      if (object) return true
      return false
    },
    checkShowBuy () {
      if (this.productShowModal) {
        if (this.productShowModal.stock.out_stock) return false
        if (this.inCart()) return false
        return true
      }
    },
    findCategoryProduct (idCategoryProduct) {
      const category = this.categories.find(item => item.id === idCategoryProduct)
      if (category) {
        return category.name
      } else {
        return this.$t('app.text.empty_category')
      }
    },
    redirectDetailProduct (product) {
      const url = this.$router.resolve({
        name: 'portal.products.detail',
        params: { portalId: this.$route.params.portalId, productStoreSlug: product.online_store_slug }
      }).href

      window.location.assign(url)
    },
    incrementQuantity (product = null) {
      if (product) {
        product.quantity = parseInt(product.quantity) + 1
        this.onChangeQuantity(product)
      } else {
        if (!this.checkShowBuy()) { return null }
        this.quantity = parseInt(this.quantity) + 1
      }
    },
    decrementQuantity (product = null) {
      if (product) {
        if (product.quantity > 1) {
          product.quantity = parseInt(product.quantity) - 1
          this.onChangeQuantity(product)
        }
      } else {
        if (!this.checkShowBuy()) { return null }
        if (this.quantity > 1) {
          this.quantity = parseInt(this.quantity) - 1
        }
      }
    },
    onChangeQuantity (product) {
      this.updateItemCart(product, { quantity: product.quantity })
    },
    updateItemCart (item, data) {
      if (this.statusIsLoading()) return

      this.statusLoading()

      this.$Models.Cart
        .updateItem({
          id: item.id,
          ...data
        })
        .then(res => {
          this.statusLoadingComplete()

          if (res.ok) {
            this.showSuccessMessage(res.message)
            this.$store.commit('cart/setCart', res.cart)
          } else {
            this.$store.commit('cart/setCart', res.cart)
            this.showError(res.message)
          }
        })
        .catch(err => {
          this.statusLoadingFail()
          this.showError(err)
        })
    },
    changeProductShowModal (variant, variants) {
      this.productShowModal = { ...variant, variants }
      this.calculateDiscount()
    },

    calculateDiscount () {
      let discountPercentage = 0
      if (this.productShowModal.online_store_special_price === 0) {
        // nothing
      } else {
        const diffPrice = this.productShowModal.online_store_retail_price - this.productShowModal.online_store_special_price

        discountPercentage = Math.round(diffPrice / this.productShowModal.online_store_retail_price * 100)
      }

      this.productShowModal.discount_percentage = discountPercentage
    }
  },
  computed: {
    categoryName () {
      return this.findCategoryProduct(this.productShowModal.category_id)
    },
    canShowBuy () {
      return this.checkShowBuy()
    },
    ...mapState('cart', {
      cart: state => state.cart
    }),
    ...mapState('general', {
      info: state => state.info,
      categories: state => state.filters.categories,
      modalCart: state => state.modalCart
    })
  }
}

export default Mixin
