const Mixin = {
  data () {
    return {
      currentPage: 1
    }
  },
  methods: {
    nextPage () {
      if (this.canNavigateNext) {
        this.currentPage++
      }
    },
    previousPage () {
      if (!this.isFirstPage) {
        this.currentPage--
      }
    }
  },
  computed: {
    isFirstPage () {
      return this.currentPage === 1
    },
    canNavigateNext () {
      return this.currentPage < this.totalPages
    }
  }
}

export default Mixin
