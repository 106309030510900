import { RawConnection } from '@/api/ORM/connection/index'

export default {
  namespaced: true,
  state: {
    pageIsLoading: false,
    info: {},
    filters: {
      brands: [],
      categories: [],
      sliders: []
    },
    modalCart: {
      state: false,
      message: ''
    },
    notificationToast: {
      state: false,
      color: '',
      message: ''
    }
  },
  mutations: {
    setDepartaments (state, departaments) {
      state.departaments = departaments
    },
    setMunicipalities (state, municipalities) {
      state.municipalities = municipalities
    },
    setLoading (state, value) {
      state.pageIsLoading = value
    },
    setInfo (state, info) {
      state.info = info
    },
    setFilters (state, res) {
      state.filters.categories = res.categories.items
      state.filters.brands = res.brands.items
    },
    setModalCart (state, config) {
      state.modalCart = {
        message: config.message,
        state: config.state
      }
    },
    setNotificationToast (state, config) {
      state.notificationToast = {
        color: config.color,
        message: config.message,
        state: config.state
      }
    }
  },
  actions: {
    // standalone store change
    loadInfo (context) {
      const connection = new RawConnection()

      const url = '/api/v1/company/info'

      return connection.post(url)
        .then(result => result.data.data.result)
        .then(res => {
          context.commit('setInfo', res)
          return res
        })
    },
    loadDepartaments (context) {
      // standalone store change
      const connection = new RawConnection()

      const url = '/api/v1/company/departaments'

      return connection.post(url)
        .then(result => result.data.data.result)
        .then(res => {
          context.commit('setDepartaments', res)
          return res
        })
    },
    loadMunicipalities (context) {
      // standalone store change
      const connection = new RawConnection()

      const url = '/api/v1/company/municipalities'

      return connection.post(url)
        .then(result => result.data.data.result)
        .then(res => {
          context.commit('setMunicipalities', res)
          return res
        })
    },
    setLoading (context, value) {
      context.commit('setLoading', value)
    },
    loadFilters (context) {
      const connection = new RawConnection()

      const url = '/api/v1/products/filters'

      return connection.get(url)
        .then(result => result.data.data.result)
        .then(res => {
          context.commit('setFilters', res)
          return res
        })
    },
    setModalCart (context, config) {
      context.commit('setModalCart', config)
    },
    setNotificationToast (context, config) {
      context.commit('setNotificationToast', config)
    }
  },
  getters: {
    car (state) {
      return state.cart
    }
    /*
    allInfo: (state) => {
      return state.info
    },
    categories: (state) => {
      return state.filters.categories
    },
    brands: (state) => {
      return state.filters.brands
    }
    */
  }
}
