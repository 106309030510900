export default {
  text: {
    add_to_cart: 'AGREGAR AL CARRITO',
    not_available: 'NO DISPONIBLE',
    in_the_cart: 'EN EL CARRITO',
    variants: 'Variantes',
    quantity_availables: 'Disponible',
    quantity_available: 'Disponible',
    not_quantity_available: 'No hay disponible',
    add_cart: 'Añadir al carrito',
    already_cart: 'Ya esta en el carrito',
    category: 'CATEGORÍA:',
    shared_product: 'Compartir Producto',
    about_product: 'Acerca del producto',
    sku: 'SKU:',
    about_brand: 'Acerca de la marca',
    buy_more: 'Comprar más'
  }
}
