export default {
  text: {
    title_page: 'CONTACTO',
    your_question_comments: 'Déjanos tus preguntas o comentarios aquí',
    enter_your_contact: 'Ingresa tus datos de contacto para poder comunicarnos contigo y ayudarte.',
    send: 'ENVIAR'
  },
  form: {
    name: {
      label: 'Nombres y Apellidos',
      placeholder: 'Nombres y Apellidos'
    },
    email: {
      label: 'Correo Electrónico',
      placeholder: 'Correo Electrónico'
    },
    cellphone: {
      label: 'Celular',
      placeholder: 'Celular'
    },
    message: {
      label: 'Mensaje',
      placeholder: 'Mensaje'
    },
    accept_terms_and_conditions: 'Al enviar estos datos acepto la Política de privacidad y los términos y condiciones del uso de esta plataforma'
  }
}
