<template>
  <div>
    <ProductDetail/>
  </div>
</template>

<script>
import ProductDetail from '@/views/components/ProductDetail.vue'

export default {
  components: {
    ProductDetail
  }
}
</script>
