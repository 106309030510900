<template>
  <section id="comp_cart">
    <b-container fluid class="my-5">
      <b-row class="py-px-70 bg-color-dark">
        <b-col cols="12" class="text-center py-4">
          <h1 class="line-bottom size-px-30 text-light">{{$t('cart.text.buy_cart')}}</h1>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="my-5">
      <div v-if="cart.items.length > 0" cols="12" lg="10" >
        <b-row v-if="isTablet || isDesktop" class="border-bottom-1 border-color-white-grey pb-2 mb-3 justify-content-between">
          <b-col cols="12">
            <div class="d-flex flex-column flex-md-row justify-content-md-around align-items-md-center">
              <span class="ml-3 ml-md-0 size-px-14 size-px-md-16 text-color-grey" style="width: 325px;">{{$t('cart.text.product')}}</span>
              <span class="ml-3 ml-md-0 size-px-14">{{$t('cart.text.price')}}</span>
              <span class="ml-3 ml-md-0 size-px-14">{{$t('cart.text.quantity')}}</span>
              <span class="ml-3 ml-md-0 size-px-14 font-weight-bold">{{$t('cart.text.subtotal')}}</span>
              <span></span>
            </div>
          </b-col>
        </b-row>

        <b-row v-for="(product, idx) in cart.items" :key="`product_cart_${idx}`" class="mt-3 justify-content-lg-around">
          <b-col cols="12" class="d-flex align-items-center">
            <b-img-lazy
              v-if="product.product.photo"
              class="aspect-ratio-1-1"
              width="60px"
              :src="$utilities.publicPath(product.product.photo)"
              :alt="`${product.product.name}_${idx}`"
              blank-color="grey"
              blank
            />

            <div v-else style="width:60px;" class="d-flex justify-content-center align-items-center aspect-ratio-1-1">
              <span class="material-icons">inventory</span>
            </div>

            <div class="w-100">
              <div class="d-flex flex-column flex-md-row justify-content-md-around align-items-md-center">
                <span class="ml-3 ml-md-0 size-px-14 size-px-md-16 text-color-grey w-cart-product-custom">{{product.product.name}}</span>
                <span class="ml-3 ml-md-0 size-px-14">{{product.price | currencyFormat}}</span>
                <div>
                  <div class="ml-3 ml-md-0 size-px-14 text-center quantity d-inline p-1 p-lg-2" style="display: inner-block; height: 44px;">
                    <span class="decrease" @click="decrementQuantity(product)" style="width: 8px;">-</span>

                    <input
                      readonly
                      id="quantity"
                      type="number"
                      class="text-center text border-0"
                      name="quantity"
                      :value="product.quantity"
                      :aria-label="$t('app.text.quantity')"
                      size="4"
                      min="1"
                      max="999"
                      step="1"
                      placeholder=""
                      inputmode="numeric"
                      autocomplete="off"
                    >

                    <span class="increase" @click="incrementQuantity(product)">+</span>
                  </div>
                </div>

                <span class="ml-3 ml-md-0 size-px-14 font-weight-bold">{{product.total | currencyFormat}}</span>

                <span v-if="isDesktop || isTablet" @click="removeToCart(product)" class="ml-3 ml-md-0 size-px-26 cursor-pointer text-color-white-grey material-icons-outlined">highlight_off</span>
                <span  v-if="isMobile" @click="removeToCart(product)" class="ml-3 ml-md-0 size-px-16 cursor-pointer text-color-red">{{$t('cart.text.remove')}}</span>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row class="border-1 border-color-white-grey px-md-1 py-md-5 my-5 justify-content-center">
          <b-col cols="12" lg="3" class="my-4 my-md-0">
            <label class="size-px-20" for="coupon">{{$t('cart.text.coupon')}}</label>
          </b-col>
          <b-col cols="12" lg="8" class="mb-3">
            <b-row v-if="cart.coupon">
              <b-col cols="auto" md="4"><span>{{cart.coupon.code}} <strong>({{formatValueType(cart.coupon)}}):</strong></span></b-col>
              <b-col cols="auto" md="8" class="d-flex align-items-center">
                <span class="font-weight-bold">{{cart.totals.discount | currencyFormat}}</span>
                <span class="ml-2 ml-md-4 size-px-22 cursor-pointer material-icons-outlined" @click="removeCoupon(cart.coupon)">close</span>
              </b-col>
            </b-row>
            <div v-else>
              <b-form-input v-model="couponCode" id="coupon" class="mb-3" :placeholder="$t('cart.text.code_coupon')"></b-form-input>
              <div class="mt-2 d-flex justify-content-end">
                <p class="size-px-14 cursor-pointer border-bottom-2" @click="addCoupon">{{$t('cart.text.apply_coupon')}}</p>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row class="border-1 border-color-white-grey px-md-1 py-md-5 my-5 justify-content-center">
          <b-col cols="12" lg="3" class="my-4 my-md-0">
            <span class="size-px-20">{{$t('cart.text.total_cart')}}</span>
          </b-col>
          <b-col cols="12" lg="8">
            <b-row class="py-1">
              <b-col cols="12" sm="5"><span class="size-px-14">{{$t('cart.text.subtotal')}}</span></b-col>
              <b-col cols="12" sm="7"><span class="size-px-24 font-weight-bold">{{calculateSubtotal | currencyFormat}}</span></b-col>
            </b-row>
            <b-row v-if="cart.totals.discount > 0" class="py-1">
              <b-col cols="12" sm="5"><span class="size-px-14">{{$t('cart.text.discount')}}</span></b-col>
              <b-col cols="12" sm="7"><span class="size-px-24 font-weight-bold">{{cart.totals.discount | currencyFormat}}</span></b-col>
            </b-row>
            <b-row class="pt-1 pb-3">
              <b-col cols="12" sm="5"><span class="size-px-14">{{$t('cart.text.shipping')}}</span></b-col>
              <b-col v-if="cart.totals.shipping > 0" cols="12" sm="7" ><span class="size-px-24 font-weight-bold">{{cart.totals.shipping | currencyFormat}}</span></b-col>
              <b-col v-else cols="12" sm="7"  ><span class="size-px-14 font-weight-bold">{{$t('cart.text.shipping_free')}}</span></b-col>
            </b-row>
            <b-row class="border-top-1 border-color-white-grey py-3">
              <b-col cols="12" sm="5"><span class="size-px-14">{{$t('cart.text.total')}}</span></b-col>
              <b-col cols="12" sm="7"><span class="size-px-24 font-weight-bold">{{cart.totals.total | currencyFormat}}</span></b-col>
              <b-button class="bg-color-dark width-pct-100 mt-5 py-4 rounded-0" :href="$router.resolve({ name: 'cart.checkout' }).href">{{$t('cart.text.finish_purchase')}}</b-button>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div v-else class="my-5">
        <b-row>
          <b-col cols="12">
            <p class="text-center font-weight-bold size-px-26 mb-5">{{$t('cart.cart_empty')}}</p>
            <b-button class="bg-color-dark width-pct-100 mt-5 py-4 rounded-0" :href="$router.resolve({ name: 'portal.index' }).href">{{$t('cart.return_store')}}</b-button>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { format as formatValueType } from '@/utils/valueType'

import LoadingMixin from '@/plugins/Common/LoadingMixin.js'
import ResizeMixin from '@/mixins/ResizeMixin.js'

export default {
  name: 'compCart',
  data () {
    return {
      couponCode: null
    }
  },
  methods: {
    formatValueType,
    addCoupon () {
      if (this.statusIsLoading()) return

      if (this.couponCode) {
        this.statusLoading()

        this.$Models.Cart.addCoupon(this.couponCode)
          .then(res => {
            this.statusLoadingComplete()

            if (res.ok) {
              this.$store.commit('cart/setCart', res.cart)
              this.couponCode = null
              this.showSuccessMessage(res.message)
            } else {
              this.$store.commit('cart/setCart', res.cart)
              this.showError(res.message)
            }
          })
          .catch((err) => {
            this.statusLoadingFail()
            this.showError(err)
          })
      }
    },
    removeCoupon (coupon) {
      if (this.statusIsLoading()) return

      if (coupon) {
        this.statusLoading()

        this.$Models.Cart.removeCoupon(coupon.code)
          .then(res => {
            this.statusLoadingComplete()

            if (res.ok) {
              this.showSuccessMessage(res.message)
              this.$store.commit('cart/setCart', res.cart)
            } else {
              this.$store.commit('cart/setCart', res.cart)
              this.showError(res.message)
            }
          })
          .catch(err => {
            this.statusLoadingFail()
            this.showError(err)
          })
      }
    },
    incrementQuantity (product) {
      product.quantity = parseInt(product.quantity) + 1
      this.onChangeQuantity(product)
    },
    decrementQuantity (product) {
      if (product.quantity > 1) {
        product.quantity = parseInt(product.quantity) - 1
        this.onChangeQuantity(product)
      }
    },
    onChangeQuantity (product) {
      this.updateItemCart(product, { quantity: product.quantity })
    },
    updateItemCart (item, data) {
      if (this.statusIsLoading()) return

      this.statusLoading()

      this.$Models.Cart
        .updateItem({
          id: item.id,
          ...data
        })
        .then(res => {
          this.statusLoadingComplete()

          if (res.ok) {
            this.showSuccessMessage(res.message)
            this.$store.commit('cart/setCart', res.cart)
          } else {
            this.$store.commit('cart/setCart', res.cart)
            this.showError(res.message)
          }
        })
        .catch(err => {
          this.statusLoadingFail()
          this.showError(err)
        })
    },
    removeToCart (item) {
      if (this.statusIsLoading()) return

      this.statusLoading()

      this.$Models.Cart
        .remove(item.id)
        .then(res => {
          this.statusLoadingComplete()

          if (res.ok) {
            this.showSuccessMessage(res.message)
            this.$store.commit('cart/setCart', res.cart)
          } else {
            this.$store.commit('cart/setCart', res.cart)
            this.showError(res.message)
          }
        })
        .catch(err => {
          this.statusLoadingFail()
          this.showError(err)
        })
    }
  },
  computed: {
    ...mapState('cart', {
      cart: state => state.cart
    }),
    ...mapState('general', {
      info: state => state.info
    }),
    calculateSubtotal () {
      return this.cart.totals.total - this.cart.totals.shipping + this.cart.totals.discount
    }

  },
  mixins: [ResizeMixin, LoadingMixin]
}
</script>

<style lang="scss" scoped>
  .custom-container {
    max-width: 1280px;
  }
  // @override class boostrap
  ::v-deep .form-control {
      display: block;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: none;
      border-bottom: 1px solid #d1caca;
      border-radius: 0px;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  ::v-deep .form-control:focus {
      border-bottom: 2px solid black;
      color: #495057;
      background-color: #fff;
      border-color: none;
      outline: 0;
      box-shadow: none;
  }
  // @override class boostrap
  .quantity {
      transition: .5s;
      padding: 4px;
      border: 1px solid #e4e6eb !important;
      align-content: center;
      float: none;
      width: 100%;
      height: 30px;
  }
  .decrease, .quantity, .increase {
      cursor: pointer;
      display: inline-block;
      background: 0 0;
      border: none;
      user-select: none;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
      appearance: none;
      margin: 0;
  }
  input[type=number]:focus {
  outline: none;
  }
  .line-bottom {
    position: relative;
    display: inline-block;
  }
  .line-bottom::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -5px;
    transform: translateX(-50%);
    width: 109%;
    height: 2px;
    background-color: #FFFFFF;
  }
  .w-cart-product-custom {
    width: auto;
  }
  @media (min-width: 768px) {
    .w-cart-product-custom {
      width: 270px;
    }
  }
  @media (min-width: 992px) {
    .w-cart-product-custom {
      width: 270px;
    }
  }
</style>
