export default {
  text: {
    buy_cart: 'CARRITO DE COMPRA',
    product: 'PRODUCTO',
    price: 'PRECIO',
    quantity: 'CANTIDAD',
    subtotal: 'SUBTOTAL',
    remove: 'Eliminar',
    coupon: 'Cupón',
    code_coupon: 'Código de Cupón',
    apply_coupon: 'APLICAR CUPÓN',
    total_cart: 'Totales del carrito',
    discount: 'DESCUENTO',
    shipping: 'ENVÍO',
    shipping_free: 'ENVÍO GRATIS',
    total: 'TOTAL',
    finish_purchase: 'FINALIZAR COMPRA'
  },
  cart_empty: 'El carrito esta vacío',
  return_store: 'Volver a la tienda'
}
