<template>
  <section id="product_detail">
    <b-container v-if="statusIsLoading()" fluid class="mb-5">
      <b-row class="py-4 justify-content-center">
        <b-col cols="12" class="text-center">
          <b-spinner :label="$t('app.text.loading')"></b-spinner>
        </b-col>
      </b-row>
    </b-container>

    <b-container v-else-if="!product" fluid class="mb-5">
      <b-row class="py-4 justify-content-center">
        <b-col cols="12" class="text-center">
          <span class="size-px-100 material-icons-outlined">mood_bad</span>
          <p class="font-weight-bold size-px-24">{{$t('productDetail.product_empty.product_not_found')}}</p>
          <b-button class="bg-color-dark rounded-none" @click="redirectProduct">{{$t('productDetail.product_empty.see_all_product')}}</b-button>
        </b-col>
      </b-row>
    </b-container>

    <ProductCard
      v-else
      :product="product"
      @addToCart="addToCart"
      @seeModalCart="showModalCart"
      layoutType="list"
    ></ProductCard>

    <!-- list de product recommend for category -->
    <BannerProducts v-if="product && product.category_id" :title="$t('productDetail.text.related_products')" :searchCategoryProduct="product"/>
    <!-- list de product recommend for category -->

    <PreviewProduct
      v-if="productShowModal"
      :modalVisible="modalProduct"
      :productShowModal="productShowModal"
      :info="info"
      :categoryName="categoryName"
      :canShowBuy="canShowBuy"
      :quantity="quantity"
      @changeProductShowModal="changeProductShowModal"
      @redirectDetailProduct="redirectDetailProduct"
      @showModalCart="showModalCart"
      @hideModalProduct="hideModalProduct"
      @addToCart="addToCart"
      @incrementQuantity="incrementQuantity"
      @decrementQuantity="decrementQuantity"
    />

  </section>
</template>

<script>
import ProductCard from '@/views/components/ProductCard.vue'
import BannerProducts from '@/components/bannersProducts/BannerProducts.vue'
import PreviewProduct from '@/components/PreviewProduct.vue'

import LoadingMixin from '@/plugins/Common/LoadingMixin.js'
import AddCartMixin from '@/mixins/AddCartMixin.js'

export default {
  data () {
    return {
      product: null
    }
  },
  methods: {
    redirectProduct () {
      const url = this.$router.resolve({
        name: 'portal.products.search'
      }).href

      window.location.assign(url)
    },
    load () {
      if (this.statusIsLoading()) return
      this.statusLoading()

      const query = this.$Models.Product.query()

      if (this.$route.params.productStoreSlug) {
        query.where('online_store_slug', this.$route.params.productStoreSlug)
      }

      query.first()
        .then(product => {
          this.statusLoadingComplete()
          this.product = product
        })
        .catch(err => {
          this.statusLoadingFail()
          this.showError(err)
        })
    }
  },
  created () {
    this.load()
  },
  components: {
    ProductCard,
    BannerProducts,
    PreviewProduct
  },
  mixins: [LoadingMixin, AddCartMixin]
}
</script>

<style lang="scss" scoped>
  // start Card Product Class
  .product-container {
    position: relative;
    overflow: hidden;
  }
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f0f0f0;
  }
  .product-container {
    position: relative;
    overflow: hidden;
  }
  .product-image {
    transition: transform 0.3s;
  }
  .discount-badge {
    position: absolute;
  }
  .add-to-cart, .icons-top-right-heart, .icons-top-right-eye {
    position: absolute;
    display: none;
  }
  .product-container:hover .add-to-cart,
  .product-container:hover .icons-top-right-heart,
  .product-container:hover .icons-top-right-eye {
    display: block;
  }
  .add-to-cart {
    bottom: 0;
    width: 100%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px 0;
    transform: translateY(100%);
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear, transform 0.5s;
    visibility: hidden;
  }
  .product-container:hover .add-to-cart {
    animation: slideUp 0.5s forwards;
    visibility: visible;
  }
  .discount-badge {
    top: 0;
    left: 0;
    background-color: #00d994;
    padding: 3px 10px 3px 10px;
  }
  .icons-top-right-heart {
    top: 0;
    right: 0;
    padding: 5px;
  }
  .icons-top-right-eye {
    top: 0px;
    right: 0;
    padding: 5px;
  }
  @keyframes slideUp {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  /* Siempre visible en dispositivos móviles */
  @media (max-width: 768px) {
    .add-to-cart, .icons-top-right-heart, .icons-top-right-eye {
      display: block;
    }
  }
  // end card product class
</style>
