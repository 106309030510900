import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import i18n from './common/plugins/vue-i18n'

import Models from './api/main.js'
import Utilities from './plugins/Utilities.js'

import currencyFormat from './filters/currency'
import { quantityFormat, numberFormat } from './filters/number'

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fas, fab)

Vue.filter('currencyFormat', currencyFormat)
Vue.filter('quantityFormat', quantityFormat)
Vue.filter('numberFormat', numberFormat)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
Vue.prototype.$Models = Models

Vue.use(Utilities)
Vue.use(BootstrapVue)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
