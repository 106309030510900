<template>
  <section id="product" class="mt-px-37">
    <b-container fluid>
      <b-row class="mt-px-37 my-5">
        <b-col v-if="isDesktop" md="4" lg="4" class="order-2 order-md-1 pl-5">
          <b-row class="mb-4">
            <b-col cols="10">
              <b-input-group class="mb-2">
                <b-input-group-prepend is-text class="border-right-0 bg-color-withe">
                    <span @click="onTextSearch" class="cursor-pointer material-icons-outlined">search</span>
                </b-input-group-prepend>
                <b-form-input @keydown.enter="onTextSearch" v-model="q" size="md" class="mr-sm-2 border-left-0 px-0" :placeholder="$t('products.form.search')"></b-form-input>
              </b-input-group>
            </b-col>
          </b-row>

          <div style="height: 400px; overflow-y: auto;">
            <p class="font-weight-medium size-px-22">{{$t('products.text.categories')}}</p>

            <p
              v-for="(category, idxCategory) in productCategories" :key="`${idxCategory}_category`"
              :class="{'active-link': isActivePage(category.online_store_slug, 'category')}"
              class="px-3 size-px-13 text-dark text-uppercase hover-move-down cursor-pointer"
              @click="redirectCategory(category.online_store_slug)"
            >
              {{category.name}}
            </p>
          </div>

          <div style="height: 400px; overflow-y: auto;">
            <p class="font-weight-medium size-px-22">{{$t('products.text.brands')}}</p>

            <p
              v-for="(brand, idxBrand) in productBrands" :key="`${idxBrand}_brand`"
              :class="{'active-link': isActivePage(brand.online_store_slug, 'brand')}"
              class="px-3 size-px-13 text-dark text-uppercase hover-move-down cursor-pointer"
              @click="redirectBrand(brand.online_store_slug)"
            >
              {{brand.name}}
            </p>
          </div>
        </b-col>

        <b-col v-if="statusIsLoading()" md="8" lg="8" class="order-1 order-md-2 text-center">
          <b-spinner :label="$t('app.text.loading')"></b-spinner>
        </b-col>

        <b-col v-else-if="products.length > 0" md="12" lg="8" class="order-1 order-md-2">
          <b-row >
            <b-col
              v-for="(product, idx) in products" :key="`product_${idx}_page_${pagination.currentPage}`"
              class="mb-3 px-2" cols="6" md="3" lg="4" xl="3"
          >
              <ProductCard
                :ref="`compProductCard_${product.id}`"
                :product="product"
                @redirectDetailProduct="redirectDetailProduct"
                @seeModalProduct="showModalProduct"
                @seeModalCart="showModalCart"
                @addToCart="addToCart"
                class="h-100"
              >
              </ProductCard>
            </b-col>
          </b-row>

          <b-row class="justify-content-center">
            <b-col cols="auto">
              <ul class="pagination">
                <li v-for="page in pagination.totalPages" :key="page" class="page-item">
                  <a
                    @click="toNextPage(page)"
                    class="mx-3"
                    :class="{'active': page === pagination.currentPage, 'inactive': page !== pagination.currentPage}"
                    type="button"
                  >
                    {{ page }}
                  </a>
                </li>
              </ul>
            </b-col>
          </b-row>
        </b-col>

        <b-col v-else md="8" lg="8" class="order-1 order-md-2">
          <h1>{{$t('products.text.not_result')}}</h1>
        </b-col>
      </b-row>
    </b-container>

    <!-- Modal SHOW PRODUCT -->
    <PreviewProduct
      v-if="productShowModal"
      :modalVisible="modalProduct"
      :productShowModal="productShowModal"
      :info="info"
      :categoryName="categoryName"
      :canShowBuy="canShowBuy"
      :quantity="quantity"
      @changeProductShowModal="changeProductShowModal"
      @redirectDetailProduct="redirectDetailProduct"
      @showModalCart="showModalCart"
      @hideModalProduct="hideModalProduct"
      @addToCart="addToCart"
      @incrementQuantity="incrementQuantity"
      @decrementQuantity="decrementQuantity"
    />
  </section>
</template>

<script>
import ProductCard from '@/views/components/ProductCard.vue'
import PreviewProduct from '@/components/PreviewProduct.vue'

import LoadingMixin from '@/plugins/Common/LoadingMixin.js'
import AddCartMixin from '@/mixins/AddCartMixin.js'
import RedirectMixin from '@/mixins/RedirectMixin.js'
import ResizeMixin from '@/mixins/ResizeMixin.js'

export default {
  name: 'CompProducts',
  data () {
    return {
      products: [],

      productCategories: [],
      productBrands: [],

      q: '',

      filters: {
        q: null,
        online_store_is_new: null,
        online_store_is_highlight: null,
        category_store_slug: null,
        brand_store_slug: null
      },

      pagination: {
        currentPage: 1,
        totalRows: 1,
        totalPages: 1,
        perPage: 20
      }
    }
  },
  methods: {
    onTextSearch () {
      if (this.q) {
        const url = this.$router.resolve({ name: 'portal.products.search', query: { q: this.q } }).href
        window.location.assign(url)
      }
    },
    toNextPage (page) {
      if (page !== this.pagination.currentPage) {
        this.pagination.currentPage = page
        this.load()
      }
    },
    resetLoad () {
      this.products = []
      this.pagination.currentPage = 1
    },
    load () {
      if (this.statusIsLoading()) return

      const query = this.$Models.Product.query()

      const qValue = this.filters.q
      if (qValue !== null && qValue !== undefined) {
        query.where('q', qValue)
      }

      if (this.filters.category_store_slug) {
        query.where('category_online_store_slug', this.filters.category_store_slug)
      }
      if (this.filters.brand_store_slug) {
        query.where('brand_online_store_slug', this.filters.brand_store_slug)
      }

      if (this.filters.is_new) { query.where('online_store_is_new', true) }

      if (this.filters.is_highlight) { query.where('online_store_is_highlight', true) }

      if (this.filters.is_outlet) { query.where('online_store_is_outlet', true) }

      query.forPage(this.pagination.currentPage, this.pagination.perPage)

      this.statusLoading()
      query.get()
        .then(res => {
          if (this.pagination.currentPage === 1) {
            this.pagination.totalRows = res.count
            this.pagination.totalPages = Math.ceil(this.pagination.totalRows / this.pagination.perPage)
          }

          return res
        })
        .then(res => {
          this.products = res.items
          this.statusLoadingComplete()
        })
        .catch((err) => {
          this.statusLoadingFail()
          this.showError(err)
        })
    },
    isActivePage (slugIdentification, type) {
      if (type === 'category') {
        return this.filters.category_store_slug === slugIdentification
      } else if (type === 'brand') {
        return this.filters.brand_store_slug === slugIdentification
      } else {
        return false
      }
    }
  },
  created () {
    const qValue = this.$route.query.q
    if (qValue !== null && qValue !== undefined) {
      this.filters.q = qValue
    }

    const isNew = this.$route.query.is_new
    if (isNew === true || isNew === 'true') {
      this.filters.is_new = true
    } else {
      this.filters.is_new = false
    }

    const isHighlight = this.$route.query.is_highlight
    if (isHighlight === true || isHighlight === 'true') {
      this.filters.is_highlight = true
    } else {
      this.filters.is_highlight = false
    }

    const isOutlet = this.$route.query.is_outlet
    if (isOutlet === true || isOutlet === 'true') {
      this.filters.is_outlet = true
    } else {
      this.filters.is_outlet = false
    }

    if (this.$route.params.categoryStoreSlug) {
      this.filters.category_store_slug = this.$route.params.categoryStoreSlug
    }
    if (this.$route.params.brandStoreSlug) {
      this.filters.brand_store_slug = this.$route.params.brandStoreSlug
    }

    this.load()

    this.$Models.Product.getFilters()
      .then(res => {
        this.productCategories = res.categories.items
        this.productBrands = res.brands.items
      })
      .catch(err => {
        this.showError(err)
      })
  },
  components: {
    ProductCard,
    PreviewProduct
  },
  mixins: [
    LoadingMixin,
    AddCartMixin,
    RedirectMixin,
    ResizeMixin
  ]
}
</script>

<style lang="scss" scoped>
  .hover-move-down {
    transition: transform 0.3s ease-in-out;
  }
  .hover-move-down:hover {
    transform: translateY(5px);
  }
  .active-link {
    color: #000;
    font-weight: bold;
  }
  .pagination .page-item .active {
    font-weight: bold;
    color: black;
    border-bottom: 2px solid black;
  }
  .pagination .page-item .inactive {
    color: grey;
  }
  .input-group-text {
  background-color: #fff;
  border: 1px solid #ced4da;
  }
</style>
