<template>
  <section id="contact">
    <b-container fluid>
      <b-row class="py-5 bg-color-dark mt-2">
        <b-col cols="12" class="d-flex justify-content-center">
          <span class="size-px-30 size-px-md-35 line-bottom text-light">{{$t('contact.text.title_page')}}</span>
        </b-col>
      </b-row>
      <b-row class="mt-px-100 justify-content-center">
        <b-col v-if="info.store_logo" cols="12" md="6" lg="6" xl="5" class="d-flex justify-content-center align-items-center py-5 my-5">
          <a class="d-flex justify-content-center" :href="$router.resolve({ name: 'portal.index' }).href">
            <b-img-lazy
              style="width:90%;"
              class="aspect-ratio-16-9 object-fit-contain cursor-pointer zoom-effect"
              :src="info ? $utilities.publicPath(info.store_logo): ''"
              alt="Logo"
              blank-color="grey"
              blank
            />
          </a>
        </b-col>
        <b-col
          cols="12"
          :md="info.store_logo ? '6' : '12'"
          :lg="info.store_logo ? '6' : '12'"
          :xl="info.store_logo ? '5' : '12'"
          :class="info.store_logo ? 'border-left-md-1' : ''"
          class="d-flex flex-column justify-content-center py-5 my-5"
        >
          <div :class="!info.store_logo ? 'd-flex justify-content-center' : ''">
            <div class="ml-3 border-bottom-1 border-bottom-md-0">
              <p v-if="info.location_address" class="d-flex align-items-center"><span class="size-px-23 mr-3 material-icons">room</span>{{info.location_address}}</p>
              <p v-if="info.email" class="d-flex align-items-center"><span class="size-px-23 mr-3 material-icons">email</span>{{info.email}}</p>
              <p v-if="info.contact_phone" class="d-flex align-items-center"><span class="size-px-23 mr-3 material-icons">phone</span>{{info.contact_phone}}</p>
              <p v-if="info.contact_cellphone_indicator && info.contact_cellphone" class="d-flex align-items-center">
                <span class="size-px-23 mr-3 material-icons">smartphone</span>
                +{{info.contact_cellphone_indicator}} {{info.contact_cellphone}}
              </p>
              <p v-if="info.contact_cellphone_indicator && info.contact_cellphone" class="d-flex align-items-center">
                <font-awesome-icon class="size-px-23 mr-3 " icon="fa-brands fa-whatsapp" />
                +{{info.contact_cellphone_indicator}} {{info.contact_cellphone}}
              </p>
            </div>
          </div>
        </b-col>

        <b-col v-if="info.url_location_geolocation" cols="12" md="6" lg="6" xl="5" class="order-2 order-md-1 my-5">
          <iframe
            :src="info.url_location_geolocation"
            width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </b-col>
        <b-col
          cols="12" md="6" lg="6" xl="5"
          :class="info.url_location_geolocation ? '' : 'border-left-md-1'"
          class="d-flex flex-column justify-content-center order-1 order-md-2 my-5"
        >
          <div class="text-center mb-4">
            <p class="size-px-19 font-weight-bold mb-2">{{$t('contact.text.your_question_comments')}}</p>
            <p class="size-px-16">{{$t('contact.text.enter_your_contact')}}</p>
          </div>

          <b-form>
            <b-form-input
              id="form_name"
              v-model="form.name"
              class="mb-4"
              :placeholder="$t('contact.form.name.placeholder')"
              required
              @blur="handleBlur('form.name')"
              :state="getFieldState('form.name', 'text')"
              :invalid-feedback="getFieldFeedback('form.name', 'text')"
            ></b-form-input>

            <b-form-input
              id="form_email"
              v-model="form.email"
              type="email"
              class="mb-4"
              :placeholder="$t('contact.form.email.placeholder')"
              required
              @blur="handleBlur('form.email')"
              :state="getFieldState('form.email', 'email')"
              :invalid-feedback="getFieldFeedback('form.email', 'email')"
            ></b-form-input>

            <b-form-input
              id="form_phone"
              v-model="form.phone"
              type="number"
              class="mb-4"
              :placeholder="$t('contact.form.cellphone.placeholder')"
              required
              @blur="handleBlur('form.phone')"
              :state="getFieldState('form.phone', 'cellphone')"
              :invalid-feedback="getFieldFeedback('form.phone', 'cellphone')"
            ></b-form-input>

            <b-form-textarea
              id="form_message"
              v-model="form.message"
              class="mb-4"
              :placeholder="$t('contact.form.message.placeholder')"
              rows="6"
              max-rows="10"
              @blur="handleBlur('form.message')"
              :state="getFieldState('form.message', 'required')"
              :invalid-feedback="getFieldFeedback('form.message', 'required')"
            ></b-form-textarea>

            <b-form-checkbox
              id="form_terms_conditions"
              v-model="form.accept_terms_and_conditions"
              class="text-color-grey-light size-px-13"
              name="terms_conditions"
              required
              @change="handleBlur('form.accept_terms_and_conditions')"
              :state="getFieldState('form.accept_terms_and_conditions', 'checkbox')"
              :invalid-feedback="getFieldFeedback('form.accept_terms_and_conditions', 'checkbox')"
            >
              {{$t('contact.form.accept_terms_and_conditions')}}
            </b-form-checkbox>

            <b-button @click="prepareContact" class="bg-color-dark width-pct-100 mt-3 py-4 rounded-0"><span class="size-px-22">{{$t('contact.text.send')}}</span></b-button>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import LoadingMixin from '@/plugins/Common/LoadingMixin.js'
import RecaptchaMixin from '@/plugins/Common/RecaptchaMixin.js'
import FormValidationMixin from '@/mixins/FormValidationMixin.js'

export default {
  name: 'compContact',
  data () {
    return {
      form: {
        name: null,
        email: null,
        phone: null,
        message: null,
        accept_terms_and_conditions: false
      }
    }
  },
  methods: {
    getData () {
      return this.form
    },
    reset () {
      this.form.name = null
      this.form.email = null
      this.form.phone = null
      this.form.message = null
      this.form.accept_terms_and_conditions = false
    },
    onGetRecaptchaToken (token) {
      this.form.token = token
    },
    allFieldsValid () {
      return Object.keys(this.form).every(fieldName => {
        const validationType = this.getValidationType(fieldName)
        return this.touchedFields[`form.${fieldName}`] && this.getFieldState(`form.${fieldName}`, validationType)
      })
    },
    getValidationType (fieldName) {
      if (fieldName === 'email') return 'email'
      if (fieldName === 'phone') return 'cellphone'
      if (fieldName === 'name') return 'text'
      if (fieldName === 'accept_terms_and_conditions') return 'checkbox'

      return 'required'
    },
    prepareContact () {
      if (this.allFieldsValid()) {
        if (this.statusIsLoading()) return

        this.statusLoading()

        this.getRecaptchaToken('contact')
          .then(token => { this.sendMail() })
      } else {
        this.showError(this.$t('app.text.error.filled_out_correctly'))
      }
    },

    sendMail () {
      const data = this.getData()

      // this.statusLoading()
      this.$Models.Contact.send(data)
        .then(res => {
          this.statusLoadingComplete()
          this.showSuccessMessage()

          if (res.ok) {
            this.showSuccessMessage(res.message)
          } else {
            this.showError(res.message)
          }

          if (res.ok) {
            this.reset()
          }
        })
        .catch((err) => {
          this.reset()
          this.statusLoadingFail()
          this.showError(err)
        })
    }
  },
  computed: {
    ...mapState('general', {
      info: state => state.info
    })
  },
  mixins: [LoadingMixin, RecaptchaMixin, FormValidationMixin],
  watch: {
    info (nVal, oVal) {
      this.wathInfoSiteKey(nVal, oVal)
    }
  }
}
</script>

<style lang="scss" scoped>
// @override class boostrap
  ::v-deep .form-control {
      display: block;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0rem;
      font-size: 13px;
      font-weight: 400;
      line-height: 1.5;
      color: #aaa;
      background-color: #fff;
      background-clip: padding-box;
      border: none;
      border-bottom: 1px solid #000000;
      border-radius: 0px;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      overflow-y: auto !important;
  }
  ::v-deep .form-control:focus {
      border-bottom: 2px solid black;
      color: #495057;
      background-color: #fff;
      border-color: none;
      outline: 0;
      box-shadow: none;
  }
// @override class boostrap
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
  }
  input[type=number]:focus {
  outline: none;
  }
  .line-bottom {
    position: relative;
    display: inline-block;
  }
  .line-bottom::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -5px;
    transform: translateX(-50%);
    width: 150%;
    height: 2px;
    background-color: #FFFFFF;
  }

  // Animation img logo
  .zoom-effect {
    transition: transform 0.3s ease-in-out;
  }
  .zoom-effect:hover {
    transform: scale(1.1);
  }
  // Animation
</style>
