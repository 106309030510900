<template>
  <section id="section_banner_card" class="mx-1">
    <b-container fluid>
      <b-row class="justify-content-center">
        <b-col xl="12" class="px-1 px-lg-2 position-relative">
          <span
            v-if="isDesktop"
            class="cursor-pointer position-absolute custom-position-preview material-icons-outlined"
            @click="scrollLeft"
          >
            arrow_back_ios
          </span>

          <span
            v-if="isDesktop"
            class="cursor-pointer position-absolute custom-position-next material-icons-outlined"
            @click="scrollRight"
          >
            arrow_forward_ios
          </span>

          <div class="scrollable-cards-container scrollable-cards-container-lg d-flex justify-content-star pt-1 flex-nowrap">
            <b-card
              v-for="(banner, idx) in banners" :key="idx"
              :style="{'background': banner.banner_background_color}"
              class="mb-2 rounded-lg border-0 b-card-hover size-card size-card-md"
              body-class="p-0 rounded-lg cursor-pointer"
              @click="redirectToBanner(banner)"
            >
              <b-card-img-lazy
                class="object-fit-cover h-px h-px-md"
                style="border-bottom-left-radius: 0px; border-bottom-right-radius: 0px; border-top-left-radius: 0.3rem; border-top-right-radius: 0.3rem;"
                :src="$utilities.publicPath(banner.banner_img)"
                :alt="banner.banner_name"
                top
              />

              <div class="p-3 py-lg-4">
                <b-card-title class="text-color-dark font-weight-bold mb-1 size-px-18 size-px-lg-24">
                  {{banner.banner_name}}
                </b-card-title>

                <b-card-text>
                  <b-row>
                    <b-col cols="12" class="text-break">
                      <a class="text-color-dark text-hover size-px-15 size-px-lg-16">
                        {{banner.banner_short_description}}
                      </a>
                    </b-col>
                  </b-row>
                </b-card-text>

                <a class="text-color-dark font-weight-bold d-flex align-items-center text-hover">
                  {{banner.banner_link_name}}
                </a>
              </div>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import ResizeMixin from '@/mixins/ResizeMixin.js'
import RedirectMixin from '@/mixins/RedirectMixin.js'

export default {
  name: 'CompBannerCard',
  data () {
    return {
    }
  },
  props: {
    banners: {
      type: Array,
      required: true
    }
  },
  methods: {
    redirectToBanner (banner) {
      if (banner.banner_link_source === 'INTERNAL') {
        const routeData = {}

        if (banner.banner_link_type === 'PRODUCT') {
          routeData.name = 'portal.products.detail'
          routeData.params = { productStoreSlug: banner.banner_link_related_slug }
        }
        if (banner.banner_link_type === 'CATEGORY') {
          routeData.name = 'portal.products.category'
          routeData.params = { categoryStoreSlug: banner.banner_link_related_slug }
        }
        if (banner.banner_link_type === 'BRAND') {
          routeData.name = 'portal.products.brand'
          routeData.params = { brandStoreSlug: banner.banner_link_related_slug }
        }
        if (banner.banner_link_type === 'PAGE') {
          routeData.name = 'portal.pages.detail'
          routeData.params = { pageStoreSlug: banner.banner_link_related_slug }
        }

        const url = this.$router.resolve(routeData).href
        window.location.assign(url)
      } else if (banner.banner_link_source === 'EXTERNAL') {
        window.open(banner.banner_link, '_blank')
      }
    },
    scrollLeft () {
      const container = document.querySelector('.scrollable-cards-container')
      if (container) {
        const containerWidth = container.offsetWidth
        const scrollAmount = containerWidth * 0.5
        container.scrollBy({ left: -scrollAmount, behavior: 'smooth' })
      }
    },
    scrollRight () {
      const container = document.querySelector('.scrollable-cards-container')
      if (container) {
        const containerWidth = container.offsetWidth
        const scrollAmount = containerWidth * 0.5

        container.scrollBy({ left: scrollAmount, behavior: 'smooth' })
      }
    }
  },
  mixins: [
    ResizeMixin,
    RedirectMixin
  ]
}
</script>

<style lang="scss" scoped>
  .b-card {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 20px;
  }

  b-card .text-hover:hover {
    color: black !important;
    text-decoration: underline !important;
  }
  .text-decoration-hover:hover {
    text-decoration: none !important;
  }

  .b-card-hover:hover .text-hover {
    color: black !important;
    text-decoration: underline !important;
  }

  .custom-position-preview,
  .custom-position-next {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 10px;
    border-radius: 50%;
    color:white;
  }
  .custom-position-preview {
    top: 50%;
    left: 0px;
    z-index: 60;
  }
  .custom-position-next {
    top: 50%;
    right: 0px;
    z-index: 60;
  }

  .size-card {
    width: calc(50% - 20px);
    min-width: 315px;
    margin-right: 10px;
  }

  .scrollable-cards-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .h-px {
    height: 210px;
  }
  @media (min-width: 576px) {
    .size-card-sm {
      width: calc(50% - 20px);
      min-width: 500px;
      margin-right: 20px;
    }
    .h-px-sm {
    height: 380px;
    }
  }
  @media (min-width: 768px) {
    .size-card-md {
      width: calc(50% - 20px);
      min-width: 545px;
      margin-right: 10px;
    }
    .h-px-md {
      height: 363.33px;
    }
  }
  @media (min-width: 992px) {
    .scrollable-cards-container-lg {
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
    }
  }
</style>
