export default {
  text: {
    variants: 'Variantes',
    availables: 'Disponibles',
    available: 'Disponibles',
    not_available: 'No hay Disponibles',
    add_to_cart: 'Añadir al carrito',
    already_cart: 'Ya esta en el carrito',
    category: 'CATEGORÍA',
    quantity: 'Cantidad'
  }
}
