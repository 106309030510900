<template>
  <section id="section_category" class="my-5">
    <b-container class="custom-container">
      <b-row class="mt-5 justify-content-center">
        <b-col xl="12" class="border-top-1 border-color-line pt-5" >
          <b-row>
            <b-col sm="12" md="12" lg="12" xl="3">
              <div class="py-3">
                <h1 class="size-px-24 font-weight-medium">{{$t('categories.text.categories')}}</h1>
                <p>{{$t('categories.text.buy_categories')}}</p>
              </div>
            </b-col>
            <b-col sm="12" md="12" lg="12" xl="9" class="d-flex justify-content-lg-end">
              <div class="d-flex py-3" style="overflow-x:auto;">
                <div v-for="(category, idx) in categories" :key="`category${idx}`"
                  @click="redirectCategory(category.online_store_slug)"
                  class="category-item cursor-pointer rounded mx-2 d-flex flex-column justify-content-between p-2"
                  style="min-width:110px; max-width:110px; width:100%; height:130px; box-shadow: 0px 0px 3px 0px #b5acac;"
                >
                  <TruncatedText
                    class="size-px-12 font-weight-bold text-break"
                    :max="18"
                    :title="category.name"
                    :text="category.name"
                    onlyTruncate
                  ></TruncatedText>

                  <div class="text-right">
                    <span class="material-icons-outlined size-px-50" :style="{'color': category.color}">{{ category.online_store_icon }}</span>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import RedirectMixin from '@/mixins/RedirectMixin.js'
import TruncatedText from '@/views/components/TruncatedText.vue'

export default {
  name: 'CompSectionCategories',
  components: {
    TruncatedText
  },
  computed: {
    ...mapState('general', {
      categories: state => state.filters.categories
    })
  },
  mixins: [RedirectMixin]
}
</script>

<style lang="scss" scoped>
  .custom-container {
    max-width: 1280px;
  }
  .category-item {
    transition: transform 0.3s ease;
  }
  .category-item:hover {
    transform: translateY(-10px);
  }
</style>
