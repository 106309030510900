import Vue from 'vue'
import VueI18n from 'vue-i18n'

// Localisation language list
import { es } from '@/common/config/i18n/es_CO'
import { en } from '@/common/config/i18n/en_US'

Vue.use(VueI18n)

const messages = {
  es,
  en
}

// get current selected language
const lang = 'es'

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: lang, // set locale
  messages // set locale messages
})

export default i18n
