const Mixin = {
  data () {
    return {
      status: {
        loading: false
      }
    }
  },
  methods: {
    statusIsLoading () {
      return this.status.loading === true
    },
    getMediaPath (path) {
      try {
        if (path) {
          if (path.charAt(0) === '/') {
            return `https://umedia.liztofiles.us-sjo1.upcloudobjects.com${path}`
          } else {
            return `https://umedia.liztofiles.us-sjo1.upcloudobjects.com/${path}`
          }
        }
        return null
      } catch (e) {
        return null
      }
    },
    statusLoading () {
      this.status.loading = true
      this.$store.dispatch('general/setLoading', this.status.loading)
    },
    statusLoadingComplete () {
      this.status.loading = false
      this.$store.dispatch('general/setLoading', this.status.loading)
    },
    statusLoadingFail () {
      this.status.loading = false
      this.$store.dispatch('general/setLoading', this.status.loading)
    },
    showSuccessMessage (message = 'Lizto') {
      this.$store.dispatch('general/setNotificationToast', { state: true, color: 'success', message })
    },
    showInfoMessage (message) {
      this.$store.dispatch('general/setNotificationToast', { state: true, color: 'primary', message })
    },
    showError (error) {
      let message

      if (typeof error === 'string') {
        message = error
      } else {
        try {
          message = error.response.data.message
        } catch {
          message = this.$t('app.text.error.error_ocurred')
        }
      }

      this.$store.dispatch('general/setNotificationToast', { state: true, color: 'danger', message })
    }
  }
}

export default Mixin
