export default {
  modal_whatsapp: {
    text: {
      chat_with_us: 'Chatea con nosotros',
      sales_line: 'Linea para Ventas',
      chat_with_us_sell: 'Chatea con nosotros Línea para Ventas',
      hello_click_options: '¡Hola! Haz clic en una de las opciones para chatear con uno de nuestros agentes',
      team_usually_responds: 'El equipo suele responder en unos minutos.',
      sales: 'Ventas'
    }
  },
  main_menu: {
    search: {
      label: 'Buscar',
      placeholder: 'Buscar...'
    }
  },
  secondary_menu: {
    news: 'Nuevos',
    brands: 'Marcas',
    categories: 'Categorías',
    more_categories: 'Más Categorías',
    gift_card: 'Tarjeta de regalo',
    schedule_appointment: 'Agendar cita',
    dropdown_news: {
      all_news: 'Todo lo nuevo',
      all_highlights: 'Todo lo destacado'
    }
  },
  footer: {
    contact: 'Contáctanos'
  },
  modal_cart: {
    cart_empty: {
      oh: '¡Vaya!',
      your_cart_empty: 'Tu carrito está vacío :('
    },
    cart_with_product: {
      your_cart: 'Tu carrito',
      total_cart: 'Total del carrito:',
      see_cart: 'VER CARRITO',
      pay: 'PAGAR',
      keep_buying: 'SEGUIR COMPRANDO',
      highlight_month: 'Destacados del mes',
      add_product: '¡Producto añadido al carrito!'
    }
  }
}
