<template>
  <div class="h-100">
    <b-row v-if="isDesktop" class="h-100">
      <b-col cols="2" class="px-0">
        <div v-if="mediaItems.length > 0" class="d-flex flex-column align-items-end">
          <div
            v-for="(media, idx) in mediaItems.slice(0, quantityShowProduct)"
            :key="`media_${idx}`"
            class="aspect-ratio-1-1 cursor-pointer rounded-full my-1 p-1"
            :class="{
              'border-2 border-dark': selectedImageIdx === idx,
              'hover-border-grey': selectedImageIdx !== idx
            }"
            style="width:56px; height: 56px;"
            @click="mediaSelect(idx)"
          >
            <b-img-lazy
              v-if="media.type === 'image'"
              :src="media.url"
              :alt="media.nameProduct"
              class="w-100 h-100 rounded-full"
            />
            <b-img-lazy
              v-if="media.type === 'video'"
              :src="`https://img.youtube.com/vi/${media.videoId}/0.jpg`"
              :alt="media.nameProduct"
              class="w-100 h-100 rounded-full"
            />
          </div>

          <div v-if="mediaItems.length > quantityShowProduct" class="text-center mt-2 border-top-1 border-color-white-grey">
            <a class="text-dark cursor-pointer" @click.prevent="showModalVisibleImgProducts">
              {{$t('app.text.show_all')}}
              <p>{{ mediaItems.length }}</p>
            </a>
          </div>
        </div>
        <div v-else class="d-flex flex-column align-items-end">
          <div class="d-flex aspect-ratio-1-1 rounded-full my-1 p-1 border-2 border-dark">
            <span class="material-icons size-px-40">inventory</span>
          </div>
        </div>
      </b-col>

      <b-col cols="10" class="d-flex justify-content-center align-items-center">
        <div v-if="mediaItems.length > 0" @click.prevent="showModalVisibleImgProducts" class="position-relative  cursor-pointer d-flex align-items-center w-100">
          <span v-if="selectedImageIdx > 0" @click.stop="previousImage" style="top: 50%; left: 0; transform: translateY(-50%);" class="position-absolute cursor-pointer material-icons-outlined">arrow_back_ios</span>
          <span v-if="selectedImageIdx < mediaItems.length - 1" @click.stop="nextImage" style="top: 50%; right: 0; transform: translateY(-50%);" class="position-absolute cursor-pointer material-icons-outlined">arrow_forward_ios</span>

          <b-img-lazy
            v-if="selectedMedia && selectedMedia.type === 'image'"
            class="object-fit-contain w-100"
            :src="selectedMedia.url"
            :alt="selectedMedia.nameProduct"
          />

          <div v-else-if="selectedMedia && selectedMedia.type === 'video'" class="w-100 h-100">
            <iframe
              :src="`https://www.youtube.com/embed/${selectedMedia.videoId}?controls=1&showinfo=0&rel=0&modestbranding=1`"
              class="aspect-ratio-16-9 w-100"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>

          <div v-if="product.online_store_is_new" class="new-badge size-px-10 text-light font-weight-bold" style="min-width: 43px;">
            <span>NEW</span>
          </div>

          <div v-if="product.discount_percentage != 0" class="discount-badge size-px-10 text-light font-weight-bold" style="min-width: 43px;">
            <span>{{product.discount_percentage}}%</span>
          </div>
        </div>
        <div v-else class="position-relative w-100">
          <div class="aspect-ratio-1-1 d-flex justify-content-center align-items-center w-100">
            <span class="material-icons size-px-40">inventory</span>
          </div>

          <div v-if="product.online_store_is_new" class="new-badge size-px-10 text-light font-weight-bold" style="min-width: 43px;">
            <span>NEW</span>
          </div>

          <div v-show="product.discount_percentage != 0" class="discount-badge size-px-10 text-light font-weight-bold" style="min-width: 43px;">
            <span>{{product.discount_percentage}}%</span>
          </div>
        </div>
      </b-col>
    </b-row>

    <!-- scroll mobil -->
    <div  v-if="isTablet || isMobile" class="position-relative">
      <span
        v-if="mediaItems.length > 1"
        class="cursor-pointer position-absolute custom-position-preview material-icons-outlined"
        @click="scrollLeft"
      >
        arrow_back_ios
      </span>

      <span
        v-if="mediaItems.length > 1"
        class="cursor-pointer position-absolute custom-position-next material-icons-outlined"
        @click="scrollRight"
      >
        arrow_forward_ios
      </span>

      <b-row class="scrollable-container overflow-x-auto flex-nowrap py-4" :class="mediaItems.length === 1 ? 'justify-content-center' : ''" style="overflow-x: auto;">
        <div
          v-for="(media, idx) in mediaItems"
          :key="`media_${idx}`"
          class="product-image-card mx-1 d-flex align-items-center"
          @click="selectAndShowModalMobile(idx)"
        >
          <b-img-lazy
            v-if="media.type === 'image'"
            :src="media.url"
            :alt="media.nameProduct"
            width="315"
            height="315"
          />
          <div v-else-if="media.type === 'video'" class="w-100">
            <iframe
              :src="`https://www.youtube.com/embed/${media.videoId}?controls=1&showinfo=0&rel=0&modestbranding=1`"
              width="315"
              style="aspect-ratio: 16/9;"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </b-row>
    </div>
    <!-- scroll mobil -->

    <!-- Modal -->
    <b-modal v-model="modalVisibleImgProducts" dialog-class="modal-width-custom" centered>
      <template v-slot:modal-header>
        <div class="w-100 d-flex justify-content-center position-relative">
          <h5 class="text-center w-100">{{ product.name }}</h5>
          <a class="close cursor-pointer opacity-100" aria-label="Close" @click="hideModalVisible">
            <span aria-hidden="true">&times;</span>
          </a>
        </div>
      </template>

      <div v-if="selectedMedia" class="text-center position-relative">
        <span v-if="selectedImageIdx > 0" @click="previousImage" style="top: 50%; left: 0; transform: translateY(-50%); z-index: 1000;" class="position-absolute cursor-pointer material-icons-outlined">arrow_back_ios</span>
        <span v-if="selectedImageIdx < mediaItems.length - 1" @click="nextImage" style="top: 50%; right: 0; transform: translateY(-50%); z-index: 1000;" class="position-absolute cursor-pointer material-icons-outlined">arrow_forward_ios</span>

        <b-img-lazy
          v-if="selectedMedia.type === 'image'"
          class="mb-3 aspect-ratio-16-9 object-fit-contain"
          style="width:100%;"
          :src="selectedMedia.url"
          :alt="selectedMedia.nameProduct"
        />
        <div v-else-if="selectedMedia.type === 'video'" class="w-100">
          <iframe
            :src="`https://www.youtube.com/embed/${selectedMedia.videoId}?controls=1&showinfo=0&rel=0&modestbranding=1`"
            style="aspect-ratio: 16/9;"
            class="w-100"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>

      <template v-slot:modal-footer>
        <div class="d-flex justify-content-start flex-row w-100" style="overflow-x: auto;">
          <div
            v-for="(media, idx) in mediaItems"
            :key="`media_${idx}`"
            class="aspect-ratio-1-1 cursor-pointer rounded-full my-1 mx-2"
            :class="{
              'border-2 border-dark': selectedImageIdx === idx,
              'hover-border-grey': selectedImageIdx !== idx
            }"
            @click="mediaSelect(idx)"
          >
            <b-img-lazy
              v-if="media.type === 'image'"
              :src="media.url"
              :alt="media.nameProduct"
              style="width:80px; height: 80px;"
              class="rounded-full"
            />
            <b-img-lazy
              v-else-if="media.type === 'video'"
              :src="`https://img.youtube.com/vi/${media.videoId}/0.jpg`"
              :alt="media.nameProduct"
              style="width:80px; height: 80px;"
              class="rounded-full"
            />
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ResizeMixin from '@/mixins/ResizeMixin.js'

export default {
  name: 'CompProductImages',
  data () {
    return {
      selectedImageIdx: 0,
      modalVisibleImgProducts: false
    }
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    quantityShowProduct: {
      type: Number,
      default: 8
    }
  },
  computed: {
    mediaItems () {
      const images = []

      if (this.product.photo) {
        images.push({
          type: 'image',
          url: this.$utilities.publicPath(this.product.photo),
          nameProduct: this.product.name
        })
      }

      if (this.product.product_images && this.product.product_images.length > 0) {
        this.product.product_images.forEach(image => {
          if (image.photo) {
            images.push({
              type: 'image',
              url: this.$utilities.publicPath(image.photo),
              nameProduct: this.product.name
            })
          }
        })
      }

      if (this.product.online_store_video) {
        images.push({
          type: 'video',
          videoId: this.extractVideoId(this.product.online_store_video),
          nameProduct: this.product.name
        })
      }

      return images
    },
    selectedMedia () {
      return this.mediaItems[this.selectedImageIdx] || null
    }
  },
  methods: {
    extractVideoId (url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
      const match = url.match(regExp)
      return (match && match[2].length === 11) ? match[2] : null
    },
    selectAndShowModalMobile (idx) {
      this.mediaSelect(idx)
      this.showModalVisibleImgProducts()
    },
    mediaSelect (idx) {
      this.selectedImageIdx = idx
    },
    showModalVisibleImgProducts () {
      this.modalVisibleImgProducts = true
    },
    nextImage () {
      if (this.selectedImageIdx < this.mediaItems.length - 1) {
        this.selectedImageIdx += 1
      }
    },
    previousImage () {
      if (this.selectedImageIdx > 0) {
        this.selectedImageIdx -= 1
      }
    },
    hideModalVisible () {
      this.modalVisibleImgProducts = false
    },
    scrollLeft () {
      const container = document.querySelector('.scrollable-container')
      if (container) {
        const containerWidth = container.offsetWidth
        const scrollAmount = containerWidth * 0.5
        container.scrollBy({ left: -scrollAmount, behavior: 'smooth' })
      }
    },
    scrollRight () {
      const container = document.querySelector('.scrollable-container')
      if (container) {
        const containerWidth = container.offsetWidth
        const scrollAmount = containerWidth * 0.5

        container.scrollBy({ left: scrollAmount, behavior: 'smooth' })
      }
    },
    resetSelectedImg () {
      this.selectedImageIdx = 0
    }
  },
  mixins: [ResizeMixin]
}
</script>

<style scoped>
  .hover-border-grey:hover {
      border: 2px solid #d3d3d3;
  }
  .product-image-card {
      box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 6px;
  }
  .new-badge {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000000;
    padding: 3px 10px 3px 10px;
  }
  .discount-badge {
    position: absolute;
    top: 30px;
    left: 0;
    background-color: #00d994;
    padding: 3px 10px 3px 10px;
  }
  .custom-position-preview,
  .custom-position-next {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 10px;
    border-radius: 50%;
    color:white;
  }
  .custom-position-preview {
    top: 50%;
    left: 0px;
    z-index: 60;
  }
  .custom-position-next {
    top: 50%;
    right: 0px;
    z-index: 60;
  }
  @media (min-width: 576px) {
    ::v-deep .modal-width-custom{
      max-width: 556px !important;
    }
  }
  @media (min-width: 768px) {
    ::v-deep .modal-width-custom {
      max-width: 748px !important;
    }
  }
  @media (min-width: 992px) {
    ::v-deep .modal-width-custom {
      max-width: 972px !important;
    }
  }
  @media (min-width: 1200px) {
    ::v-deep .modal-width-custom {
      max-width: 1180px !important;
    }
  }
</style>
