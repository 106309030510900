const Mixin = {
  data () {
    return {
      window: {
        width: 0,
        height: 0
      },

      DEVICE_SIZE: {
        XS: {
          MIN_VUETIFY: 0,
          MAX_VUETIFY: 600,

          MIN_BOOTSTRAP: 0,
          MAX_BOOTSTRAP: 544
        },
        SM: {
          MIN_VUETIFY: 600,
          MAX_VUETIFY: 960,

          MIN_BOOTSTRAP: 544,
          MAX_BOOTSTRAP: 767
        },
        MD: {
          MIN_VUETIFY: 960,
          MAX_VUETIFY: 1264,

          MIN_BOOTSTRAP: 768,
          MAX_BOOTSTRAP: 991
        },
        LG: {
          MIN_VUETIFY: 1264,
          MAX_VUETIFY: 1904,

          MIN_BOOTSTRAP: 992,
          MAX_BOOTSTRAP: 1199
        },
        XL: {
          MIN_VUETIFY: 1904,
          MAX_VUETIFY: null,

          MIN_BOOTSTRAP: 1200,
          MAX_BOOTSTRAP: null
        }
      }
    }
  },
  methods: {
    handleResize () {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    }
  },
  computed: {
    isMobile () {
      const device = this.deviceIs
      if (device === 'xs' || device === 'sm') { return true }
      return false
    },
    isTablet () {
      const device = this.deviceIs
      if (device === 'md') { return true }
      return false
    },
    isDesktop () {
      const device = this.deviceIs
      if (device === 'lg' || device === 'xl') { return true }
      return false
    },
    isDesktopLg () {
      const device = this.deviceIs
      if (device === 'lg') { return true }
      return false
    },
    isDesktopXl () {
      const device = this.deviceIs
      if (device === 'xl') { return true }
      return false
    },
    deviceIs () {
      const width = this.window.width

      // let minXS = this.DEVICE_SIZE.XS.MIN_VUETIFY
      // let maxXS = this.DEVICE_SIZE.XS.MAX_VUETIFY

      const minXS = this.DEVICE_SIZE.XS.MIN_BOOTSTRAP
      const maxXS = this.DEVICE_SIZE.XS.MAX_BOOTSTRAP
      if (width >= minXS && width <= maxXS) { return 'xs' }

      // let minSM = this.DEVICE_SIZE.SM.MIN_VUETIFY
      // let maxSM = this.DEVICE_SIZE.SM.MAX_VUETIFY

      const minSM = this.DEVICE_SIZE.SM.MIN_BOOTSTRAP
      const maxSM = this.DEVICE_SIZE.SM.MAX_BOOTSTRAP
      if (width >= minSM && width <= maxSM) { return 'sm' }

      // let minMD = this.DEVICE_SIZE.MD.MIN_VUETIFY
      // let maxMD = this.DEVICE_SIZE.MD.MAX_VUETIFY

      const minMD = this.DEVICE_SIZE.MD.MIN_BOOTSTRAP
      const maxMD = this.DEVICE_SIZE.MD.MAX_BOOTSTRAP
      if (width >= minMD && width <= maxMD) { return 'md' }

      // let minLG = this.DEVICE_SIZE.LG.MIN_VUETIFY
      // let maxLG = this.DEVICE_SIZE.LG.MAX_VUETIFY

      const minLG = this.DEVICE_SIZE.LG.MIN_BOOTSTRAP
      const maxLG = this.DEVICE_SIZE.LG.MAX_BOOTSTRAP
      if (width >= minLG && width <= maxLG) { return 'lg' }

      // let minXL = this.DEVICE_SIZE.XL.MIN_VUETIFY
      // // let maxXL = this.DEVICE_SIZE.XL.MAX_VUETIFY

      const minXL = this.DEVICE_SIZE.XL.MIN_BOOTSTRAP
      // let maxXL = this.DEVICE_SIZE.XL.MAX_BOOTSTRAP
      if (width >= minXL) { return 'xl' }

      return 'xl'
    },
    isXS () {
      const width = this.window.width
      const min = this.DEVICE_SIZE.XS.MIN_VUETIFY
      const max = this.DEVICE_SIZE.XS.MAX_VUETIFY

      if (width > min && width < max) { return true }
      return false
    },
    isSM () {
      const width = this.window.width
      const min = this.DEVICE_SIZE.SM.MIN_VUETIFY
      const max = this.DEVICE_SIZE.SM.MAX_VUETIFY

      if (width > min && width < max) { return true }
      return false
    },
    isMD () {
      const width = this.window.width
      const min = this.DEVICE_SIZE.MD.MIN_VUETIFY
      const max = this.DEVICE_SIZE.MD.MAX_VUETIFY

      if (width > min && width < max) { return true }
      return false
    },
    isLG () {
      const width = this.window.width
      const min = this.DEVICE_SIZE.LG.MIN_VUETIFY
      const max = this.DEVICE_SIZE.LG.MAX_VUETIFY

      if (width > min && width < max) { return true }
      return false
    },
    isXL () {
      const width = this.window.width
      const min = this.DEVICE_SIZE.XL.MIN_VUETIFY
      // let max = this.DEVICE_SIZE.XL.MAX_VUETIFY

      if (width > min) { return true }
      return false
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  }
}

export default Mixin
