<template>
  <section id="section_pages" class="my-5 mx-1" v-if="filteredPages.length > 0">
    <b-container class="custom-container">
      <b-row v-if="isDesktopXl" class="justify-content-center">
        <b-col xl="12">
          <b-row class="justify-content-center border-top-1 border-color-line pt-5 position-relative">
            <span
              v-if="!isFirstPage"
              class="cursor-pointer position-absolute custom-position-preview material-icons-outlined"
              @click="previousPage()"
            >
              arrow_back_ios
            </span>

            <span
              v-if="canNavigateNext"
              class="cursor-pointer position-absolute custom-position-next material-icons-outlined"
              @click="nextPage()"
            >
              arrow_forward_ios
            </span>

            <b-col v-for="(page, idx) in getProductsOfCurrentPage" :key="idx" lg="4" class="p-0">
              <b-card
                :style="{'background': page.page_background_color}"
                class="mb-2 rounded-lg border-0 b-card-hover mx-1 h-100"
                body-class="p-0 rounded-lg cursor-pointer"
                @click="redirectToPage(page)"
              >
                <b-card-img-lazy
                  class="object-fit-cover img-custom-size-lg w-100"
                  :src="$utilities.publicPath(page.page_content_img)"
                  :alt="page.page_name"
                  top
                />

                <b-card-body class="d-flex flex-column justify-content-between p-3 flex-grow-1">
                  <b-card-title class="text-color-dark font-weight-bold mb-1">{{page.page_name}}</b-card-title>

                  <b-card-text class="flex-grow-1">
                    <a class="text-color-dark text-hover size-px-15 d-block">
                      <TruncatedText :max="60" style="white-space: pre-wrap" :text="page.page_short_description"></TruncatedText>
                    </a>
                  </b-card-text>

                  <a class="text-color-dark font-weight-bold text-hover">{{page.page_link_name}}</a>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row v-if="isMobile || isTablet || isDesktopLg" class="justify-content-center">
        <b-col cols="12" class="px-0">
          <b-row class="justify-content-around border-top-1 border-color-line pt-5 flex-nowrap mx-1" style="overflow-x:auto;">
            <b-card
              v-for="(page, idx) in filteredPages" :key="idx"
              :style="{'background': page.page_background_color}"
              class="mb-2 rounded-lg border-0 b-card-hover mr-3 card-size card-size-lg"
              body-class="p-0 rounded-lg cursor-pointer"
              @click="redirectToPage(page)"
            >
              <b-card-img-lazy
                class="object-fit-cover img-custom-size img-custom-size-lg w-100"
                :src="$utilities.publicPath(page.page_content_img)"
                :alt="page.page_name"
                style="min-height: 210px;"
                top
              />

              <div class="p-4">
                <b-card-title class="text-color-dark font-weight-bold mb-1 size-px-18">{{page.page_name}}</b-card-title>

                <b-card-text>
                  <a class="text-color-dark text-hover size-px-14">
                    <TruncatedText :max="60" style="white-space: pre-wrap" :text="page.page_short_description"></TruncatedText>
                  </a>
                </b-card-text>

                <a class="text-color-dark font-weight-bold d-flex align-items-center text-hover">{{page.page_link_name}}</a>
              </div>
            </b-card>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import TruncatedText from '@/views/components/TruncatedText.vue'

import ResizeMixin from '@/mixins/ResizeMixin.js'
import RedirectMixin from '@/mixins/RedirectMixin.js'
import BasicPaginationMixin from '@/mixins/BasicPaginationMixin.js'

export default {
  name: 'CompPages',
  props: {
    onlyBrands: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      perPage: 3
    }
  },
  computed: {
    ...mapState('general', {
      storePages: state => state.info.store_pages
    }),
    totalPages () {
      if (this.filteredPages && this.filteredPages.length > 0) {
        return Math.ceil(this.filteredPages.length / this.perPage)
      } else {
        return 1
      }
    },
    getProductsOfCurrentPage () {
      if (this.filteredPages && this.filteredPages.length > 0) {
        const startIndex = (this.currentPage - 1) * this.perPage
        return this.filteredPages.slice(startIndex, startIndex + this.perPage)
      } else {
        return []
      }
    },
    filteredPages () {
      if (this.onlyBrands) {
        if (this.storePages && Array.isArray(this.storePages)) {
          return this.storePages.filter(page => (page.page_link_type === 'BRAND' && page.page_active))
        }
        return []
      } else {
        if (this.storePages && Array.isArray(this.storePages)) {
          return this.storePages.filter(page => (page.page_link_type !== 'BRAND' && page.page_active))
        }
        return []
      }
    }
  },
  components: {
    TruncatedText
  },
  mixins: [
    ResizeMixin,
    RedirectMixin,
    BasicPaginationMixin
  ]

}
</script>

<style lang="scss" scoped>
  .custom-container {
    max-width: 1280px;
  }
  b-card .text-hover:hover {
    color: black !important;
    text-decoration: underline !important;
  }
  .text-decoration-hover:hover {
    text-decoration: none !important;
  }
  .b-card-hover:hover .text-hover {
    color: black !important;
    text-decoration: underline !important;
  }
  .custom-position-preview,
  .custom-position-next {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 10px;
    border-radius: 50%;
    color:white;
  }
  .custom-position-preview {
    top: 50%;
    left: 0px;
    z-index: 60;
  }
  .custom-position-next {
    top: 50%;
    right: 0px;
    z-index: 60;
  }
  .card-size {
    min-width: 315px;
    max-width: 210px;
  }
  .img-custom-size {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    width: 315px;
    height: 210px;
  }
  @media (min-width: 992px) {
    .card-size-lg {
      min-width: 400px;
      max-width: 400px;
    }
    .img-custom-size-lg {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-top-left-radius: 0.3rem;
      border-top-right-radius: 0.3rem;
      width: 400px;
      height: 267px;
    }
  }
</style>
