import Product from '@/api/Models/Product/Product'
import Cart from '@/api/Models/Cart/Cart'
import GiftCard from '@/api/Models/GiftCard/GiftCard'
import Contact from '@/api/Models/Contact/Contact'

export default {
  Product,
  Cart,
  GiftCard,
  Contact
}
