import axios from 'axios'
import configService from '@/api/ORM/connection/config'
// import { getStoreSlug } from '@/api/utils' // standalone store change

const instance = axios.create({
  baseURL: configService.apiUrl,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

/*
  if (token) {
    config.headers['X-CSRF-TOKEN'] = token
  } else {
    // console.error('CSRF token not found')
  }
*/

export default instance
