<template>
  <div v-if="productToShow">
    <div
      v-if="layoutType === 'card'"
      v-bind='$attrs'
      class="text-center rounded-md h-100"
      style="box-shadow: 0px 0px 3px 0px #b5acac;"
    >
      <div class="product-container" :class="!productToShow.photo ? 'image-container': ''">
        <div v-if="productToShow.photo" @click="onClickRedirectDetailProduct(productToShow)">
          <b-img-lazy
            class="cursor-pointer aspect-ratio-1-1 w-100"
            :src="$utilities.publicPath(productToShow.photo)"
            :alt="productToShow.name"
            blank-color="grey"
            blank
          />
        </div>
        <div v-else class="w-100 d-flex justify-content-center align-items-center aspect-ratio-1-1 cursor-pointer" @click="onClickRedirectDetailProduct(productToShow)">
          <span class="material-icons">inventory</span>
        </div>

        <div v-if="showBuy(productToShow)" class="add-to-cart font-weight-bold cursor-pointer" @click="onClickBuy(productToShow)">
          <span class="size-px-12 size-px-md-12 size-px-lg-15">{{$t('productCard.text.add_to_cart')}}</span>
        </div>
        <div v-else-if="productToShow.stock && productToShow.stock.out_stock" class="add-to-cart font-weight-bold">
          <span class="size-px-12 size-px-md-12 size-px-lg-15">{{$t('productCard.text.not_available')}}</span>
        </div>
        <div v-else class="add-to-cart font-weight-bold cursor-pointer" @click="onClickSeeModalCart">
          <span class="size-px-12 size-px-md-12 size-px-lg-15">{{$t('productCard.text.in_the_cart')}}</span>
        </div>

        <div v-if="productToShow.online_store_is_new" class="new-badge size-px-10 text-light font-weight-bold" style="min-width: 43px;">
          <span>NEW</span>
        </div>
        <div v-if="productToShow.discount_percentage != 0" class="discount-badge size-px-10 text-light font-weight-bold" style="min-width: 43px;">
          <span>{{productToShow.discount_percentage}}%</span>
        </div>

        <div class="icons-top-right-eye cursor-pointer" @click="onClickSeeModalProduct(productToShow)">
          <span class="material-icons-outlined text-color-dark p-2 m-1 rounded bg-color-pink">visibility</span>
        </div>
      </div>

      <div>
        <p
          v-if="productToShow.price.special_price != 0"
          class="font-weight-bold mt-2 mb-0 size-px-18 size-px-lg-20"
        >
          {{productToShow.price.value_price | currencyFormat}}
        </p>
        <p :class="
          [{
            'font-weight-bold size-px-18 size-px-lg-20 mt-3': productToShow.price.special_price == 0,
            'text-decoration-line-through text-color-grey-light size-px-12 size-px-md-14 size-px-lg-16 mb-0': productToShow.price.special_price != 0
          }]"
          class="d-inline-block"
        >
          {{productToShow.price.retail_price | currencyFormat}}
        </p>

        <div @click="onClickRedirectDetailProduct(productToShow)" class="p-3">
          <TruncatedText
            class="size-px-12 size-px-md-14 font-weight-bold cursor-pointer text-break"
            :max="30"
            :title="productToShow.name"
            :text="productToShow.name"
            onlyTruncate
          ></TruncatedText>
        </div>
      </div>
    </div>

    <!-- Product detail -->
    <b-container v-else class="my-5 custom-container">
      <b-row class="pt-5 justify-content-center">
        <b-col cols="12" lg="6">
          <div style="height: 100%;" class="product-container" :class="!productToShow.photo ? 'image-container': ''">
            <ProductImages ref="compProductImages" :product="productToShow"> </ProductImages>
          </div>
        </b-col>

        <b-col cols="12" lg="6">
          <p v-if="brandInfoProduct" class="size-px-14 mb-0 text-break font-weight-bold cursor-pointer" @click="redirectBrand(brandInfoProduct.online_store_slug)">{{brandInfoProduct.name}}</p>

          <p class="size-px-16 size-px-md-24 size-px-lg-30 text-break font-weight-bold">{{productToShow.name}}</p>

          <p class="mb-4 size-px-22">
            <span
              v-if="productToShow.price.special_price != 0"
              class="font-weight-bold mr-3 size-px-30">
              {{productToShow.price.value_price | currencyFormat}}
            </span>
            <span :class="productToShow.price.special_price == 0 ?
              'font-weight-bold size-px-30' :
              'text-decoration-line-through text-color-grey-light size-px-20'">
              {{productToShow.price.retail_price | currencyFormat}}
            </span>
          </p>

          <TruncatedText
            style="white-space: break-spaces; margin-top:3rem; margin-bottom:3rem;"
            class="my-5 size-px-14"
            :max="300"
            :text="productToShow.online_store_description"
            :sectionTo="'about_product'"
          ></TruncatedText>

          <div v-if="product.can_variant" class="border-bottom border-color-white-grey mb-2">
            <p class="text-center font-weight-bold size-px-20">{{$t('productCard.text.variants')}}</p>
            <div>
              <p
                v-for="(variant, idx) in formattedProductVariants(product)" :key="`variant_${idx}`"
                class="variant-custom p-2 size-px-13 cursor-pointer rounded"
                :class="{
                  'variant-custom-active': variant.id === productToShow.id,
                  'bg-color-white': variant.id !== productToShow.id,
                  'd-inline-block mr-2': variant.value_name.length > 0 &&  variant.value_name.length < 18
                }"
                @click="onChangeVariantSelected(variant)"
              >
                {{variant.value_name}}
              </p>
            </div>
          </div>

          <p class="text-center my-5">
            <span v-if="productToShow.stock && !productToShow.stock.out_stock">
              <span v-if="info ? info.show_quantity_in_stock : false" class="size-px-18">
                {{productToShow.stock.quantity_available | quantityFormat}} {{$t('productCard.text.quantity_availables')}}
              </span>
              <span v-else class="size-px-18">
                {{$t('productCard.text.quantity_available')}}
              </span>
            </span>
            <span v-else class="text-center size-px-18">
              {{$t('productCard.text.not_quantity_available')}}
            </span>
          </p>

          <b-row class="justify-content-center">
            <b-col v-if="showBuy(productToShow)" cols="5" lg="auto">
              <div class="quantity d-flex justify-content-center align-items-center" style="display: inner-block;">
                <span class="decrease cursor-pointer size-px-18 d-flex align-items-center" @click="decrementDefaultQuantity">-</span>

                <input
                  readonly
                  type="number"
                  id="quantity"
                  class="text-center text border-0"
                  name="quantity"
                  :value="defaultQuantity"
                  :aria-label="$t('app.text.quantity')"
                  size="4"
                  min="1"
                  :max="productToShow.stock ? productToShow.stock.quantity_available : 999"
                  step="1"
                  placeholder=""
                  inputmode="numeric"
                  autocomplete="off"
                >

                <span class="increase cursor-pointer d-flex align-items-center" @click="incrementDefaultQuantity">+</span>
              </div>
            </b-col>

            <b-col cols="12" lg="auto" class="mt-4 mt-lg-0 text-center">
              <b-button v-if="showBuy(productToShow)" class="bg-color-red border-0 height-pct-100 rounded-full p-3 py-lg-4 py-lg-2 px-lg-5" @click="onClickBuy(productToShow)" >
                <span><font-awesome-icon class="mr-2 size-px-20" icon="fa-solid fa-bag-shopping" /> {{$t('productCard.text.add_cart')}}</span>
              </b-button>
              <b-button
                v-else-if="productToShow.stock && !productToShow.stock.out_stock"
                class="bg-color-red border-0 height-pct-100 rounded-full p-3"
                @click="onClickSeeModalCart"
              >
                <span>{{$t('productCard.text.already_cart')}}</span>
              </b-button>
            </b-col>
          </b-row>

          <div v-if="categoryInfoProduct" class="mt-3 border-top-1 border-bottom-1 border-color-white-grey py-4">
            <span class="size-px-10 font-weight-bold">{{$t('productCard.text.category')}} </span>
            <span @click="redirectCategory(categoryInfoProduct.online_store_slug)" class="size-px-10 text-color-grey-light ml-3 cursor-pointer">{{ categoryInfoProduct.name }}</span>
          </div>

          <b-row class="mt-4 d-flex justify-content-between">
            <b-col cols="5" class="d-flex align-items-center">
              <p class="size-px-14 size-px-lg-24 font-weight-bold m-0">{{$t('productCard.text.shared_product')}}</p>
            </b-col>
            <b-col cols="6" class="d-flex align-items-center px-2">
              <a target="_blank" :href="generateWhatsAppLink()" class="cursor-pointer text-decoration-none size-px-14 size-px-lg-17 font-weight-bold text-color-wp border-2 border-color-wp p-2 py-lg-2 px-lg-3 ml-2">
                <font-awesome-icon class="size-px-13 size-px-lg-19 text-color-wp mr-2" icon="fa-brands fa-whatsapp" />
                Whatsapp
              </a>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- About Product -->
      <b-row v-if="productToShow.sku || productToShow.online_store_description" class="my-4 py-3 border-y-1 border-color-white-grey" id="about_product">
        <b-col cols="12">
          <p class="size-px-25 font-weight-bold">{{$t('productCard.text.about_product')}}</p>
        </b-col>
        <b-col v-if="productToShow.sku" cols="12" class="mb-3">
          <span class="text-color-grey-light">{{$t('productCard.text.sku')}} <span class="font-weight-bold text-color-grey-dark">{{productToShow.sku}}</span></span>
        </b-col>
        <b-col cols="12" style="white-space: break-spaces;">
          <TruncatedText :max="600" style="white-space: break-spaces; margin-top:3rem; margin-bottom:3rem;" class="my-5 size-px-14 size-px-lg-18" :text="productToShow.online_store_description"></TruncatedText>
        </b-col>
      </b-row>

      <!-- About Brand Product -->
      <b-row v-if="brandInfoProduct && brandInfoProduct.online_store_description" class="justify-content-center  my-4 py-3 border-bottom-1 border-color-white-grey " id="about_product">
        <b-col cols="12">
          <p class="size-px-25 font-weight-bold">{{$t('productCard.text.about_brand')}}</p>
        </b-col>
        <b-col cols="12" style="white-space: break-spaces;">
          <TruncatedText :max="500" class="size-px-14 size-px-lg-18" :text="brandInfoProduct.online_store_description"></TruncatedText>
          <p @click="redirectBrand(brandInfoProduct.online_store_slug)" class="mt-3 size-px-14 size-px-lg-18 font-weight-bold cursor-pointer" style="text-decoration: underline;">{{$t('productCard.text.buy_more')}} <span>{{brandInfoProduct.name}}</span></p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TruncatedText from '@/views/components/TruncatedText.vue'
import ProductImages from '@/components/products/ProductImages.vue'

import LoadingMixin from '@/plugins/Common/LoadingMixin.js'
import RedirectMixin from '@/mixins/RedirectMixin.js'

export default {
  name: 'compProductCard',
  data () {
    return {
      productToShow: null,
      variantSelected: null,
      defaultQuantity: 1,

      categoryInfoProduct: null,
      brandInfoProduct: null,

      stylesNameProduct: { fontSize: '12px !important', fontWeight: 'bold !important' },
      stylesPriceProduct: { fontSize: '22px !important', fontWeight: 'bold !important' },
      stylesPriceSpecialProduct: { fontSize: '15px !important', fontWeight: 'bold !important' }
    }
  },
  props: {
    layoutType: {
      type: String,
      default: 'card'
    },
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('cart', {
      cart: state => state.cart
    }),
    ...mapState('general', {
      info: state => state.info,
      categories: state => state.filters.categories,
      brands: state => state.filters.brands

    })
  },
  methods: {
    inCart (product) {
      const object = this.cart.items.find(item => item.product.id === product.id)
      if (object) return true
      return false
    },
    showBuy (product) {
      if (product) {
        if (product.stock.out_stock) return false
        if (this.inCart(product)) return false
        return true
      }
    },
    formattedProductVariants (product) {
      const variants = [...product.variants]
      variants.sort((a, b) => a.value_name.length - b.value_name.length)
      return variants
    },
    generateWhatsAppLink () {
      let message = ''

      if (this.productToShow && this.info && this.productToShow.name && this.info.name) {
        message += `*${this.productToShow.name}-${this.info.name}*`
      }

      if (this.productToShow && this.productToShow.online_store_description) {
        const limitedDescription = this.productToShow.online_store_description.slice(0, 150)
        message += ` ${limitedDescription} `
      }

      const currentUrl = window.location.href
      if (currentUrl) {
        message += ` ${currentUrl}`
      }

      const encodedMessage = encodeURIComponent(message)

      return `https://wa.me/?text=${encodedMessage}`
    },
    findCategoryProduct () {
      this.categoryInfoProduct = null

      if (this.productToShow.category_id) {
        const category = this.categories.find(item => item.id === this.productToShow.category_id)
        if (category) {
          this.categoryInfoProduct = category
        }
      }
    },
    findBrandProduct () {
      this.brandInfoProduct = null

      if (this.productToShow.brand_id) {
        const brand = this.brands.find(item => item.id === this.productToShow.brand_id)
        if (brand) {
          this.brandInfoProduct = brand
        }
      }
    },
    calculateDiscount () {
      let discountPercentage = 0
      if (this.productToShow.online_store_special_price === 0) {
        // nothing
      } else {
        const diffPrice = this.productToShow.online_store_retail_price - this.productToShow.online_store_special_price

        discountPercentage = Math.round(diffPrice / this.productToShow.online_store_retail_price * 100)
      }

      this.productToShow.discount_percentage = discountPercentage
    },
    onChangeVariantSelected (variant) {
      if (variant.id !== this.productToShow.id) {
        this.productToShow = variant

        if (this.layoutType === 'list') {
          this.variantSelected = variant
        }

        this.findBrandProduct()
        this.calculateDiscount()
        this.$refs.compProductImages.resetSelectedImg()
      }
    },
    incrementDefaultQuantity () {
      if (!this.showBuy) { return null }

      this.defaultQuantity = parseInt(this.defaultQuantity) + 1
    },
    decrementDefaultQuantity () {
      if (!this.showBuy) { return null }

      if (this.defaultQuantity > 1) {
        this.defaultQuantity = parseInt(this.defaultQuantity) - 1
      }
    },
    onClickRedirectDetailProduct () { this.$emit('redirectDetailProduct', this.product) },
    onClickSeeModalProduct () { this.$emit('seeModalProduct', this.productToShow, this.product) },
    onClickSeeModalCart () { this.$emit('seeModalCart', false) },
    onClickBuy (product) {
      let dQuantity = null
      if (!this.inCart(product)) {
        dQuantity = parseInt(this.defaultQuantity)
        // verificar que el productos no sea un variant master
      }
      this.$emit('addToCart', product, dQuantity)
    }
  },
  watch: {
    product (nVal, oVal) {
      if (nVal) {
        if (nVal.can_variant) {
          this.productToShow = nVal.variants[0]
          this.variantSelected = nVal.variants[0]
        } else {
          this.productToShow = nVal
        }

        this.findBrandProduct()
        this.calculateDiscount()
      }
    }
  },
  created () {
    if (this.product.can_variant) {
      this.productToShow = this.product.variants[0]
      this.variantSelected = this.product.variants[0]
    } else {
      this.productToShow = this.product
      this.variantSelected = this.product
    }

    this.calculateDiscount()

    this.$nextTick(() => {
      this.findCategoryProduct()
      this.findBrandProduct()
    })
  },
  components: {
    TruncatedText,
    ProductImages
  },
  mixins: [
    LoadingMixin,
    RedirectMixin
  ]
}
</script>

<style lang="scss" scoped>
  .custom-container {
    max-width: 1280px;
  }
  .quantity {
    transition: .5s;
    padding: 4px;
    border: 1px solid #e4e6eb !important;
    align-content: center;
    float: none;
    width: 100%;
    height: 65px;
    margin-right: 10px;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  input[type=number]:focus {
    outline: none;
  }
  .decrease, .quantity, .increase {
    cursor: pointer;
    padding: 7px 4px;
    display: inline-block;
    background: 0 0;
    border: none;
    user-select: none;
  }
  .product-container {
    position: relative;
    overflow: hidden;
  }
  .product-image {
    transition: transform 0.3s;
  }
  .add-to-cart {
    bottom: 0;
    width: 100%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 12px 0;
    transform: translateY(100%);
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear, transform 0.5s;
    visibility: hidden;
  }
  .add-to-cart, .icons-top-right-heart, .icons-top-right-eye {
    position: absolute;
    display: none;
  }
  .product-container:hover .add-to-cart,
  .product-container:hover .icons-top-right-heart,
  .product-container:hover .icons-top-right-eye {
    display: block;
  }
  .product-container:hover .add-to-cart {
    animation: slideUp 0.5s forwards;
    visibility: visible;
  }
  .new-badge {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000000;
    //background-color: #ffaa65;
    padding: 3px 10px 3px 10px;
  }
  .discount-badge {
    position: absolute;
    top: 30px;
    left: 0;
    background-color: #00d994;
    padding: 3px 10px 3px 10px;
  }
  .icons-top-right-heart {
    top: 0;
    right: 0;
    padding: 5px;
  }
  .icons-top-right-eye {
    top: 0px;
    right: 0;
  }
  @keyframes slideUp {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  /* Siempre visible en dispositivos móviles */
  @media (max-width: 768px) {
    .add-to-cart, .icons-top-right-eye {
      display: block;
    }
  }
  .variant-custom {
    border: 1px solid #cdcdcd;
    background-color: #ffffff;
    // background-color: rgba(0, 0, 0, 0.08);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }
  .variant-custom-active {
    border: 1px solid #000000;
    background-color: #ffffff;
    // background-color: #f0f0f0;
    // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }
  .variant-custom:not(.variant-custom-active):hover {
    background-color: rgb(255, 255, 255) !important;
    // background-color: rgba(0, 0, 0, 0.08) !important;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-color: #000000 !important;
  }
  a.text-color-wp, a.text-color-wp:hover {
    color: #00d994;
  }
  input#quantity:focus-visible {
      outline-color: transparent;
  }
  // animation
  .product-item {
    transition: transform 0.3s ease;
  }
  .product-item:hover {
    transform: translateY(-10px);
  }
</style>
