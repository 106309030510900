<template>
  <b-form-select
    class="customer-select rounded-none border-color-grey mb-3"
    ref="select"
    :options="items"
    :label="label"
    v-model="localValue"
    v-bind='$attrs'
  ></b-form-select>
</template>

<script>
import SelectMixin from '@/plugins/Selects/SelectMixin.js'

export default {
  props: {
    itemText: {
      type: String,
      default: 'name'
    },
    itemValue: {
      type: String,
      default: 'code'
    },
    departmentCode: {
      type: String
    }
  },
  methods: {
    getTransformItems (items) {
      return items.map(item => ({
        text: item.name,
        value: item.code
      }))
    },
    fnFilter (item, queryText, itemText) {
      if (this.departamentCode) {
        return item.departament_code === this.departamentCode
      }
      return false
    },
    setItemsByDepartment (departmentCode) {
      if (departmentCode) {
        this.items = this.source.filter(object => object.departament_code === departmentCode)
          .map(object => ({
            text: object.name,
            value: object.code
          }))

        const defaultOption = {
          text: this.$t('selectMunicipality.text.selected_option'),
          value: null
        }

        this.items = [defaultOption, ...this.items]
      } else {
        this.items = [{
          text: this.$t('selectMunicipality.text.selected_option'),
          value: null
        }]
      }
    },

    loadSource () {
      this.$store.dispatch('general/loadMunicipalities', this.$route.params.portalId)
        .then(departaments => {
          this.source = departaments

          // this.setItems(this.source)
        })
        // .catch(error => {})
    },
    emitBlur () {
      this.$emit('blur')
    }
  },
  mounted () {
    this.$refs.select.$el.addEventListener('blur', this.emitBlur)
  },
  beforeDestroy () {
    this.$refs.select.$el.removeEventListener('blur', this.emitBlur)
  },
  mixins: [SelectMixin]
}
</script>

<style lang="scss" scoped>
  .customer-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    border: none;
    border-bottom: 1px solid;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .customer-select.is-invalid {
    border-color: #dc3545 !important;
  }
  .customer-select.is-valid{
    border-color: #28a745 !important;
  }
  .customer-select:focus {
      border-bottom: 2px solid black;
      color: #495057;
      background-color: #fff;
      border-color: none;
      outline: 0;
      box-shadow: none;
  }
    .customer-select.is-invalid:focus {
      border-bottom: 2px solid black;
      color: #495057;
      background-color: #fff;
      border-color: none;
      outline: 0;
      box-shadow: none;
  }
  .customer-select.is-valid:focus {
      border-bottom: 2px solid black;
      color: #495057;
      background-color: #fff;
      border-color: none;
      outline: 0;
      box-shadow: none;
  }
</style>
