<template>
  <div>
    <PageDetail/>
  </div>
</template>

<script>
import PageDetail from '@/views/components/PageDetail.vue'

export default {
  components: {
    PageDetail
  }
}
</script>
