const Mixin = {
  data () {
    return {
      shipping: {
        location_address: null,
        location_city: null,
        location_postal_code: null,
        location_departament_id: null,
        location_municipality_id: null,
        location_country_id: null
      },
      billing: {
        location_address: null,
        location_neighborhood: null,
        location_postal_code: null,
        location_departament_id: null,
        location_municipality_id: null,
        location_country_id: null
      },
      scriptEpaycoAdded: false,
      scriptMercadopagoAdded: false,
      checkout: {
        driver: null,
        signature: null,
        preference_id: null,
        init_point: null,
        sandbox_init_point: null,
        referenceCode: null,
        description: null,
        credentials: {
          merchantId: null,
          apiLogin: null,
          apiKey: null,
          accountId: null,
          notifyUrl: null,
          responseUrl: null,
          webCheckoutUrl: null
        },
        configuration: {
          CURRENCY: null,
          COUNTRY: null,
          LANGUAGE: null,
          IS_TEST: null,
          EXTERNAL: null,
          ALGORITHM_SIGNATURE: null,
          DISABLED_METHODS: null
        }
      }
    }
  },
  methods: {
    /*
    getUrlResponse () {
      const base = this.info.store_url
      const path = this.$router.resolve({ name: 'portal.index' }).href
      const url = base + path + 'online_payments/response'

      return url
    },
    */
    initPaymentCheckout () {
      if (this.checkout.driver === 'PAYU') this.payByPayu()
      if (this.checkout.driver === 'EPAYCO') this.payByEpayco()
      if (this.checkout.driver === 'MERCADOPAGO') this.payByMecadopago()
      if (this.checkout.driver === 'EXTERNAL_TRANSFER') this.payByExternalTransfer()
    },
    appendScriptEpayco () {
      if (this.scriptEpaycoAdded) { return }
      this.scriptEpaycoAdded = true
      const epaycoScript = document.createElement('script')
      epaycoScript.setAttribute('src', 'https://checkout.epayco.co/checkout.js')
      document.head.appendChild(epaycoScript)
    },
    appendScriptMercadopago () {
      if (this.scriptMercadopagoAdded) { return }
      this.scriptMercadopagoAdded = true
      const mercadopagoScript = document.createElement('script')
      mercadopagoScript.setAttribute('src', 'https://sdk.mercadopago.com/js/v2')
      document.head.appendChild(mercadopagoScript)
    },
    payByPayu () {
      document.querySelector('#formPayuBuyerFullName').value = `${this.customer.first_name} ${this.customer.last_name}`
      document.querySelector('#formPayuBuyerEmail').value = this.customer.email
      document.querySelector('#formPayuPayerDocument').value = this.customer.identification
      document.querySelector('#formPayuMobilePhone').value = this.customer.cellphone

      // document.querySelector('#formPayuTelephone').value = this.customer.telephone
      if (this.shipping.location_address) {
        document.querySelector('#formPayuShippingAddress').value = this.shipping.location_address
      }
      if (this.shipping.location_city) {
        document.querySelector('#formPayuShippingCity').value = this.shipping.location_city
      }
      document.querySelector('#formPayuShippingCountry').value = this.checkout.configuration.COUNTRY

      document.querySelector('#formPayuAmount').value = this.totals().amount
      document.querySelector('#formPayuTax').value = this.totals().tax
      document.querySelector('#formPayuTaxReturnBase').value = this.totals().taxReturnBase

      document.querySelector('#formPayuDescription').value = this.checkout.description
      document.querySelector('#formPayuReferenceCode').value = this.checkout.referenceCode

      // document.querySelector('#formPayuApiKey').value = this.checkout.credentials.apiKey
      document.querySelector('#formPayuAccountId').value = this.checkout.credentials.accountId
      document.querySelector('#formPayuMerchantId').value = this.checkout.credentials.merchantId
      document.querySelector('#formPayuConfirmationUrl').value = this.checkout.credentials.notifyUrl
      document.querySelector('#formPayuResponseUrl').value = this.checkout.credentials.responseUrl // this.getUrlResponse()

      document.querySelector('#formPayuCurrency').value = this.checkout.configuration.CURRENCY
      document.querySelector('#formPayuLng').value = this.checkout.configuration.LANGUAGE
      document.querySelector('#formPayuTest').value = this.checkout.configuration.IS_TEST
      document.querySelector('#formPayuAlgorithmSignature').value = this.checkout.configuration.ALGORITHM_SIGNATURE
      document.querySelector('#formPayuSignature').value = this.checkout.signature

      const form = document.querySelector('#formPayu')
      form.action = this.checkout.credentials.webCheckoutUrl

      this.statusLoading()

      form.submit()
    },
    payByEpayco () {
      /* eslint-disable no-undef */
      var handler = ePayco.checkout.configure({
        key: this.checkout.credentials.public_key,
        test: this.checkout.configuration.IS_TEST
      })

      var data = {
        /* Obligatorio */
        name: this.checkout.description,
        description: this.checkout.description,
        invoice: this.checkout.referenceCode,

        amount: this.totals().amount,
        tax: this.totals().tax,
        tax_base: this.totals().taxReturnBase,
        currency: this.checkout.configuration.CURRENCY,

        country: this.checkout.configuration.COUNTRY,
        lang: this.checkout.configuration.LANGUAGE,
        /* Obligatorio */

        // Onpage='false' - Standard='true'
        external: `${this.checkout.configuration.EXTERNAL}`,

        // Atributos opcionales
        // extra1: 'extra1',
        // extra2: 'extra2',
        // extra3: 'extra3',
        confirmation: this.checkout.credentials.confirmationUrl,
        response: this.checkout.credentials.responseUrl, // this.getUrlResponse()

        // Atributos cliente
        name_billing: `${this.customer.first_name} ${this.customer.last_name}`,
        email_billing: this.customer.email,
        // type_doc_billing: this.customer.document_type,
        number_doc_billing: this.customer.identification,
        mobilephone_billing: this.customer.cellphone,

        address_billing: this.shipping.location_address,
        city_billing: this.shipping.location_city,
        country_billing: this.checkout.configuration.COUNTRY,

        // Atributo deshabilitación metodo de pago
        methodsDisable: this.checkout.configuration.DISABLED_METHODS
      }

      this.statusLoading()

      handler.open(data)
    },
    payByMecadopago () {
      this.statusLoading()

      window.location.assign(this.checkout.init_point) // Descomentarear Url de produccion
      // window.location.assign(this.checkout.sandbox_init_point) // Comentarear Solo para pruebas
    },
    payByExternalTransfer () {
      window.location.assign(this.$router.resolve({ name: 'portal.orders.complete' }).href) // , query: { po: this.order.id }
    }
  },
  created () {
    this.appendScriptEpayco()
    // this.appendScriptMercadopago()
  }
}

export default Mixin
